// Font.
$fontDefault: 'Akkurat Pro', sans-serif;

// Primary colors.
$colorPrimaryClearlyWhite: #FFFFFF;
$colorPrimaryJustBlack: #2B2F38;
$colorPrimaryLightRed: #FF6685;
$colorPrimaryEnergeticRed: #D10931;
$colorPrimaryHighlightRed: #EB0A37;
$colorPrimaryBlack: #000000;

// Grey shades.
$colorGreyBarely: #F3F4F5;
$colorGreyLight: #DFE1E5;
$colorGreyMid: #BABDC2;
$colorGrey: #84878F;
$colorGreyDark: #444952;

// Semantic colors.
$colorSemanticInfoBlue: #007AFF;
$colorSemanticSuccessGreen: #18B268;
$colorSemanticWarningYellow: #FFCC00;
$colorSemanticErrorRed: #E31717;
$colorImageMapDotShadow: rgba(209, 9, 49, 0.12);
$colorSemanticHashtag: #3395FF;

// Breakpoints.
$desktopLargeBreakpoint: 1680px;
$desktopBreakpoint: 1440px;
$desktopSmallBreakpoint: 1200px;
$tabletBreakpoint: 1024px;
$tabletSmallBreakpoint: 768px;
$mobileLargeBreakpoint: 600px;
$mobileSmallBreakpoint: 480px;

// Z-Indices.
$zNavigation: 1000;
$zOverlay: 2000;
