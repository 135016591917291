.shop-detail-summary {
  width: 100%;
  padding: 0;

  &-container {
    margin-top: 0;
  }
}

app-article-detail-summary {
  width: 25%;
  padding: 10px;
}

@include tabletMax {
  app-article-detail-summary {
    width: 100%;
    padding: 0;
  }

  .shop-detail-summary {
    &-container {
      display: block;
    }
  }
}

@include tabletSmallMax {
  .error .shop-detail-summary-cart {
    top: calc(var(--vh, 1vh) * 100 - 94px);

    .text-input-error-text {
      background-color: $colorGreyBarely;
    }
  }

  app-article-detail-summary {
    width: 100%;
  }
}
