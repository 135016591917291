.scroll-shadow-left,
.scroll-shadow-right {
    position: absolute;
    top: 0;
    width: 50px;
    height: 100%;
    pointer-events: none;
}

.scroll-shadow-left {
    left: 0;
    background:
        linear-gradient(
            to right,
            rgba(46, 51, 61, 0.096) 0%,
            rgba(28, 29, 32, 0.096) 0.01%,
            rgba(255, 255, 255, 0) 100%
        );
}

.scroll-shadow-right {
    right: 0;
    background:
        linear-gradient(
            to left,
            rgba(46, 51, 61, 0.096) 0%,
            rgba(28, 29, 32, 0.096) 0.01%,
            rgba(255, 255, 255, 0) 100%
        );
}
