.timeline {
    max-width: 670px;
    width: 100%;
    display: flex;

    &-item {
        flex: 2 1 auto;
        display: flex;

        &-content-icon-line::before,
        &-content-icon-line::after,
        &-line {
            height: 2px;
            margin-top: 9px;
            background-color: $colorGreyMid;
        }

        &-line {
            flex: 1 1 auto;
        }

        &-content {
            flex: 0 0 auto;
            display: flex;
            flex-direction: column;

            &-icon-line {
                display: flex;
                align-items: flex-start;
                justify-content: center;
                width: 100%;

                &::before,
                &::after {
                    flex: 1 1 auto;
                    content: '';
                }

                &-icon-container {
                    flex: 0 0 auto;
                    width: 20px;
                    height: 20px;
                    border-radius: 50%;
                    border: 1px solid $colorGreyMid;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .icon-check {
                        color: $colorGreyMid;
                        font-size: 16px;
                    }
                }
            }

            &-text {
                @include fontBodySmallText;
                color: $colorGrey;
                padding-top: 6px;
            }
        }

        &.timeline-step-done {
            .timeline-item-content-text {
                color: $colorPrimaryEnergeticRed;
            }

            .timeline-item-content-icon-line-icon-container {
                border-color: $colorPrimaryEnergeticRed;

                .icon-check {
                    color: $colorPrimaryEnergeticRed;
                }
            }

            .timeline-item-content-icon-line::before,
            .timeline-item-content-icon-line::after,
            .timeline-item-line {
                background-color: $colorPrimaryEnergeticRed;
            }
        }

        &:first-child,
        &:last-child {
            // First and last child need less space compared to the 2 items in the middle.
            // Make those grow slower and shrink faster.
            flex: 1 2 auto;
        }

        // Hide lines left of first item.
        &:first-child {
            .timeline-item-line:first-child {
                display: none;
            }

            .timeline-item-content-icon-line::before {
                visibility: hidden;
            }
        }

        // Hide lines right of last item.
        &:last-child {
            .timeline-item-line:last-child {
                display: none;
            }

            .timeline-item-content-icon-line::after {
                visibility: hidden;
            }
        }
    }
}

@include desktopSmallMax {
    .timeline {
        margin-left: 70px;
    }
}

@include tabletSmallMax {
    .timeline {
        margin-left: 0;
        max-width: none;
        margin-bottom: 20px;
    }
}
