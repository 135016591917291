button.loading {
  pointer-events: none !important;
  opacity: 0.5;
  cursor: not-allowed;
}

.button-primary .loading-box,
.button-secondary .loading-box {
  position: relative;
  width: auto;
  height: 2px;
  overflow: hidden;
  margin-top: -2px;
  display: block;
}

.button-primary--icon .loading-box,
.button-secondary--icon .loading-box {
  width: calc(100% + 34px);
}

.loader-inverted {
  width: 100%;
  height: 100%;
  position: absolute;
  background: $colorGreyLight;
  left: 0;
  animation: load 1.5s linear infinite;
}

@keyframes load {
  0% {
    left: -100%;
    width: 25%;
  }
  50% {
    width: 50%;
  }
  100% {
    left: 100%;
    width: 25%;
  }
}
