.order-summary {
  width: 325px;
  padding: 20px;
  background-color: $colorPrimaryClearlyWhite;
  position: -webkit-sticky;
  position: sticky;
  top: 185px;

  &-title {
    @include fontBodyLargeBold;
  }

  &-items {
    display: flex;
    flex-direction: column;
    margin-top: 36px;
  }

  &-item,
  &-sum {
    display: flex;
    justify-content: space-between;
  }

  &-item {
    @include fontBodyText;
    color: $colorGreyDark;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &-sum {
    @include fontBodyLargeBold;
    margin-top: 20px;
  }

  &-privacy {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;

    a {
      text-decoration: underline;
    }

    &-checkbox {
      margin-top: -8px;
    }
  }

  &-lower {
    margin-top: 48px;
  }

  button + button {
    margin-top: 10px;
  }
}

@include tabletMax {
  .order-summary {
    &-lower {
      &--no-privacy {
        margin-top: 0;
      }
    }
  }
}

@include mobileLargeMax {
  .order-summary {
    padding: 16px;
  }
}
