.order-overview {
    background-color: $colorPrimaryClearlyWhite;

    &-title {
        @include fontH3Headline;
        text-align: left;
        padding: 20px;
    }

    &-headlines {
        display: flex;
        border-bottom: 1px solid $colorGreyLight;
        padding: 8px 20px 4px;
    }

    &-headline {
        @include fontBodySmallTextBold;
        color: $colorGrey;
        padding-right: 20px;
        width: 12%;

        &:first-child {
            width: 36%;
        }

        &:nth-child(2) {
            width: 15%;
        }

        &:nth-child(4) {
            width: 15%;
        }

        &:last-child {
            width: 10%;
            text-align: right;
            padding-right: 0;
        }
    }

    &-lower {
        display: flex;
        flex-wrap: wrap;
        padding: 20px;
        justify-content: space-between;
    }

    &-lower-comment {
        width: calc(50% + 100px);
        padding-right: 20px;
        color: $colorGrey;
        @include fontBodySmallText;
    }

    &-lower-content {
        text-align: right;
    }

    &-lower-price-title {
        @include fontBodyText;
        color: $colorGreyDark;
    }

    &-lower-price-extra {
        @include fontBodySmallText;
        color: $colorGrey;
        margin-top: 5px;
    }
}

@include tabletMax {
    .order-overview {
        &-title {
            border-bottom: 1px solid $colorGreyLight;
        }

        &-headlines {
            display: none;
        }

        &-lower {
            padding: 20px;
        }

        &-lower-comment {
            padding: 0;
            width: 100%;

            .text-area textarea {
                min-height: 96px;
            }
        }

        &-lower-price-title {
            @include fontBodyLargeBold;
        }

        &-lower-content {
            width: 100%;
            text-align: right;
            margin-right: 0;
            margin-top: 30px;
        }
    }
}

@include mobileLargeMax {
    .order-overview {
        &-title {
            @include fontH4Headline;
            padding: 16px 16px 20px;
        }

        &-lower {
            padding: 20px 16px 16px;
        }
    }
}
