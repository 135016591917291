@import "library/base/variables";
@import "library/base/fonts";
@import "library/base/icons";
@import "library/base/mixins";

.article-availability {

  &:hover {
    .article-availability-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  &-tooltip {
    visibility: hidden;
    display: block;
    font-family: 'Akkurat Pro', serif !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    width: 120px;
    background-color: $colorPrimaryJustBlack;
    color: $colorPrimaryClearlyWhite;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;

    &::after {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $colorPrimaryJustBlack transparent transparent transparent;
    }
  }
}

@include tabletMax {
  .article-availability-tooltip {
    display: none;
  }
}
