.navigation-logo-only {
    position: fixed;
    top: 0;
    left: 0;
    height: 97px;
    width: 100%;
    background-color: $colorPrimaryClearlyWhite;
    z-index: $zNavigation;

    // Border on the bottom.
    &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        pointer-events: none;
        content: '';
        background-color: $colorGreyLight;
        height: 1px;
        width: 100%;
    }

    // Spacing to push down content under navigation.
    &__spacer {
        height: 97px;
        flex: 0 0 auto;
    }

    &__logo {
        width: 100%;
        max-width: 210px;
        position: absolute;
        left: 40px;
        top: 24px;
        z-index: 1;
    }
}

@include tabletMax {
    .navigation-logo-only {
        height: 64px;

        &__spacer {
            height: 64px;
        }

        &__logo {
            top: 16px;
            left: 32px;
            max-width: 150px;
        }
    }
}

@include mobileLargeMax {
    .navigation-logo-only {
        &-logo {
            left: 16px;
        }
    }
}
