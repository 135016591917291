.thank-you {
  &-header {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 94px;
  }

  &-background {
    background-color: $colorPrimaryClearlyWhite;
    padding-top: 80px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-content {
    max-width: 670px;
    text-align: center;
    margin-left: 24px;
    margin-right: 24px;

    &-title {
      @include fontH2Headline;
      color: $colorPrimaryJustBlack;
      margin-bottom: 20px;
    }

    &-text {
      @include fontH4HeadlineRegular;
      color: $colorGreyDark;

      &-email {
        @include fontH4Headline;
      }
    }
  }

  &-button {
    margin-top: 40px;
    width: 180px;
  }

  &-animation {
    margin-top: 50px;
    margin-bottom: 23px;
    height: 154px;
    width: 880px;
    position: relative;

    &-container {
      background-image: url('/assets/images/icons/trees.svg');
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-repeat: repeat;
      background-position: 0 0;
      background-size: auto 100%;

      /* adjust s value for speed */
      animation: animatedBackground 75s linear infinite;

      &-shapes {
        width: 265px;
        position: absolute;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        top: 19px;
        left: 16%;
      }

      &-truck {
        height: 165px;
      }

      &-wind {
        opacity: 0.5;
        margin-right: 10px;
        height: 15px;
        animation: wind 1.5s 0s ease infinite;
      }
    }
  }
}

@include desktopSmallMax {
  .thank-you-header {
    .timeline {
      margin-left: 0;
    }
  }
}

@include tabletMax {
  .thank-you {
    .timeline {
      margin-bottom: 0;
    }

    &-header {
      height: 90px;
    }

    &-animation {
      width: 100%;

      &-container {
        &-shapes {
          left: 10%;
        }
      }
    }
  }
}

@include mobileLargeMax {
  .thank-you {
    &-background {
      padding-top: 48px;
    }

    &-content {
      max-width: 100%;

      &-title {
        @include fontH3Headline;
      }

      &-text {
        @include fontBodyText;

        &-email {
          @include fontBodyLargeBold;
        }
      }
    }

    &-button {
      margin-top: 28px;
    }
  }
}

@include mobileSmallMax {
  .thank-you {
    &-animation-container {
      &-shapes {
        width: 200px;
        top: 44px;
      }

      &-truck {
        height: 140px;
      }

      &-wind {
        height: 10px;
      }
    }
  }
}

@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -10000px 0;
  }
}

@keyframes wind {
  50% {
    transform: translateY(3px);
  }
}
