.pagination-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}

.pagination-description {
  display: inline-block;
}

.pagination-arrow {
  cursor: pointer;
  font-size: 24px;
  color: $colorPrimaryEnergeticRed;

  &.disabled {
    pointer-events: none;
    cursor: unset;
    visibility: hidden;
  }
}
