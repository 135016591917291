.profile-carts {
    width: 100%;
    position: relative;

    &-add-cart {
        display: block;
        width: 100%;
        padding: 24px 20px;
        background-color: $colorPrimaryClearlyWhite;
        margin-bottom: 20px;
    }
}

@include mobileLargeMax {
    .profile-carts {
        &-add-cart {
            padding: 20px 16px;
        }
    }
}
