.shop-detail-summary {
    display: block;
    width: 25%;
    padding: 10px;

    &-title {
        @include fontH4Headline;
        color: $colorPrimaryJustBlack;

        &::before {
            display: none;
            position: absolute;
            top: 18px;
            left: 12px;
            color: $colorPrimaryEnergeticRed;
            content: $icon-angle-right;
            font-family: $iconFont;
            font-size: 24px;
            pointer-events: none;
            transition: transform 0.4s ease;
        }

        &--open {
            &::before {
                transform: rotate(90deg);
            }
        }
    }

    &-container {
        margin-top: 20px;
        background-color: $colorPrimaryClearlyWhite;
        padding: 20px;
        display: block;
    }

    &-image {
        display: none;
        justify-content: center;
        align-items: center;
        height: 166px;
        border: 1px solid $colorGreyLight;
        margin-bottom: 16px;
    }

    &-headline {
        @include fontBodyLargeBold;
        color: $colorGreyDark;
    }

    &-info {
        @include fontBodyText;
        color: $colorGrey;
        margin-top: 12px;
    }

    &-button {
        margin-top: 40px;
    }

    &-cart-container {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100px;
        height: calc(100% - 40px); // 40px spacing bottom.
        width: 100%;
        pointer-events: none;
    }

    &-cart {
        position: sticky;
        top: calc(var(--vh, 1vh) * 100 - 88px); // 88px = 48px button height + 40px spacing bottom.
        right: 0;
        display: none;
        pointer-events: auto;
    }
}

@include tabletMax {
    .shop-detail-summary {
        padding: 0;
        width: 100%;
        background-color: $colorPrimaryClearlyWhite;

        &-title {
            background-color: $colorPrimaryClearlyWhite;
            padding: 18px 40px 20px;
            cursor: pointer;

            &::before {
                display: block;
                top: 16px;
            }
        }

        &-container {
            margin-top: 0;
            padding: 0 16px 12px;
            display: none;

            &--open {
                display: block;
            }
        }

        &-image {
            display: flex;
        }

        &-button {
            display: none;
        }

        &-cart {
            display: block;
        }
    }
}

@include mobileLargeMax {
    .shop-detail-summary {
        &-title {
            padding: 16px 16px 16px 40px;
        }

        &-cart-container {
            height: calc(100% - 16px); // 16px spacing bottom.
        }

        &-cart {
            top: calc(var(--vh, 1vh) * 100 - 64px); // 64px = 48px button height + 16px spacing bottom.
            right: 16px;
        }
    }
}
