@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/
   v5.0.1 | 20191019
   License: none (public domain)
*/
@import "@angular/cdk/overlay-prebuilt.css";
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, menu, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
main, menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section {
  display: block;
}

/* HTML5 hidden-attribute fix for newer browsers */
*[hidden] {
  display: none;
}

body {
  line-height: 1;
}

menu, ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* =================================================
        Fonts.
================================================= */
@font-face {
  font-family: "Akkurat Pro";
  src: url("/assets/fonts/akkurat-pro/AkkuratPro-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat Pro";
  src: url("/assets/fonts/akkurat-pro/AkkuratPro-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "iconfont-ehg";
  src: url("/assets/fonts/iconfont/fonts/iconfont-ehg.eot?hfgtrew");
  src: url("/assets/fonts/iconfont/fonts/iconfont-ehg.eot?hfgtrew#iefix") format("embedded-opentype"), url("/assets/fonts/iconfont/fonts/iconfont-ehg.ttf?hfgtrew") format("truetype"), url("/assets/fonts/iconfont/fonts/iconfont-ehg.woff?hfgtrew") format("woff"), url("/assets/fonts/iconfont/fonts/iconfont-ehg.svg?hfgtrew#iconfont-ehg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iconfont-ehg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home-icon-1:before {
  content: "\e96a";
}

.icon-zoom-out:before {
  content: "\e968";
}

.icon-zoom-in:before {
  content: "\e969";
}

.icon-share:before {
  content: "\e965";
}

.icon-thumbs-up:before {
  content: "\e966";
}

.icon-youtube:before {
  content: "\e967";
}

.icon-add-comment:before {
  content: "\e900";
}

.icon-angle-down:before {
  content: "\e901";
}

.icon-angle-left:before {
  content: "\e902";
}

.icon-angle-right:before {
  content: "\e903";
}

.icon-angle-up:before {
  content: "\e904";
}

.icon-arrow-down:before {
  content: "\e905";
}

.icon-arrow-left:before {
  content: "\e906";
}

.icon-arrow-right-up:before {
  content: "\e907";
}

.icon-arrow-right:before {
  content: "\e908";
}

.icon-arrow-up:before {
  content: "\e909";
}

.icon-auftragspositionen:before {
  content: "\e90a";
}

.icon-auslieferung:before {
  content: "\e90b";
}

.icon-blech:before {
  content: "\e90c";
  color: #babdc2;
}

.icon-book-open:before {
  content: "\e90d";
}

.icon-calendar:before {
  content: "\e90e";
}

.icon-carcoclix:before {
  content: "\e90f";
}

.icon-certificate:before {
  content: "\e910";
}

.icon-chat:before {
  content: "\e911";
}

.icon-check-rounded:before {
  content: "\e912";
}

.icon-check:before {
  content: "\e913";
}

.icon-checkbox-alt:before {
  content: "\e914";
}

.icon-checkbox:before {
  content: "\e915";
}

.icon-clock:before {
  content: "\e916";
}

.icon-close:before {
  content: "\e917";
}

.icon-compress:before {
  content: "\e918";
}

.icon-download:before {
  content: "\e919";
}

.icon-e-procurement:before {
  content: "\e91a";
}

.icon-ecommerce-dollar:before {
  content: "\e91b";
}

.icon-edit:before {
  content: "\e91c";
}

.icon-envelope:before {
  content: "\e91d";
}

.icon-events:before {
  content: "\e91e";
}

.icon-expand:before {
  content: "\e91f";
}

.icon-external-link:before {
  content: "\e920";
}

.icon-facebook:before {
  content: "\e921";
}

.icon-fahrtkostenverguetung:before {
  content: "\e922";
}

.icon-file-download:before {
  content: "\e923";
}

.icon-file-pdf:before {
  content: "\e924";
}

.icon-filter-v2:before {
  content: "\e925";
}

.icon-filter:before {
  content: "\e926";
}

.icon-flach:before {
  content: "\e927";
  color: #babdc2;
}

.icon-fliessbohren:before {
  content: "\e928";
}

.icon-font-size:before {
  content: "\e929";
}

.icon-gesundheitfoerderung:before {
  content: "\e92a";
}

.icon-gewindeschneiden:before {
  content: "\e92b";
}

.icon-graph:before {
  content: "\e92c";
}

.icon-gruendung:before {
  content: "\e92d";
}

.icon-i-form:before {
  content: "\e92e";
  color: #babdc2;
}

.icon-info:before {
  content: "\e92f";
}

.icon-instagram:before {
  content: "\e930";
}

.icon-kantine:before {
  content: "\e931";
}

.icon-lagerflaeche:before {
  content: "\e932";
}

.icon-liefertermine:before {
  content: "\e933";
}

.icon-linkedin:before {
  content: "\e934";
}

.icon-lkw:before {
  content: "\e935";
}

.icon-lock:before {
  content: "\e936";
}

.icon-login:before {
  content: "\e937";
}

.icon-logistik:before {
  content: "\e938";
}

.icon-logout:before {
  content: "\e939";
}

.icon-map-marker:before {
  content: "\e93a";
}

.icon-materialbeschaffung:before {
  content: "\e93b";
}

.icon-medal:before {
  content: "\e93c";
}

.icon-menu:before {
  content: "\e93d";
}

.icon-minus:before {
  content: "\e93e";
}

.icon-oberflaechenbehandlung:before {
  content: "\e93f";
}

.icon-pen-1:before {
  content: "\e940";
}

.icon-pen:before {
  content: "\e941";
}

.icon-person:before {
  content: "\e942";
}

.icon-play:before {
  content: "\e943";
}

.icon-plus:before {
  content: "\e944";
}

.icon-preis-zuschlaege:before {
  content: "\e945";
}

.icon-profil-rohr:before {
  content: "\e946";
  color: #babdc2;
}

.icon-quote:before {
  content: "\e947";
}

.icon-rohrlasern:before {
  content: "\e948";
}

.icon-rund-rohr:before {
  content: "\e949";
  color: #babdc2;
}

.icon-rund:before {
  content: "\e94a";
  color: #babdc2;
}

.icon-saegen:before {
  content: "\e94b";
}

.icon-search:before {
  content: "\e94c";
}

.icon-sechskant:before {
  content: "\e94d";
  color: #babdc2;
}

.icon-settings-1:before {
  content: "\e94e";
}

.icon-settings:before {
  content: "\e94f";
}

.icon-shop-cart:before {
  content: "\e950";
}

.icon-sonderform:before {
  content: "\e951";
  color: #babdc2;
}

.icon-sortiment:before {
  content: "\e952";
}

.icon-star-filled:before {
  content: "\e953";
}

.icon-star:before {
  content: "\e954";
}

.icon-support:before {
  content: "\e955";
}

.icon-t-form:before {
  content: "\e956";
  color: #babdc2;
}

.icon-telephone:before {
  content: "\e957";
}

.icon-tourenzeiten:before {
  content: "\e958";
}

.icon-trash:before {
  content: "\e959";
}

.icon-u-form:before {
  content: "\e95a";
  color: #babdc2;
}

.icon-umsatz:before {
  content: "\e95b";
}

.icon-unlock:before {
  content: "\e95c";
}

.icon-upload:before {
  content: "\e95d";
}

.icon-user:before {
  content: "\e95e";
}

.icon-vierkant:before {
  content: "\e95f";
  color: #babdc2;
}

.icon-warning:before {
  content: "\e960";
}

.icon-weiterbildung:before {
  content: "\e961";
}

.icon-winkel-profile:before {
  content: "\e962";
  color: #babdc2;
}

.icon-winkel:before {
  content: "\e963";
  color: #babdc2;
}

.icon-world:before {
  content: "\e964";
}

/* =================================================
        Base definitions.
================================================= */
html {
  box-sizing: border-box;
  font-family: "Akkurat Pro", sans-serif;
  color: #2B2F38;
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-size: 16px;
  line-height: 24px;
}

.no-overflow {
  overflow: hidden;
}

.content {
  flex: 1;
}

* {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: inherit;
}

p {
  margin: 0;
}

img,
picture {
  display: block;
  max-width: 100%;
}

a {
  text-decoration: none;
  color: inherit;
}

div,
main,
header,
footer,
section,
article {
  position: relative;
  display: block;
}

button {
  cursor: pointer;
  font-family: "Akkurat Pro", sans-serif;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
}

.radio {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
}
.radio:not(:last-child) {
  margin-bottom: 16px;
}

.radio-text {
  font-size: 16px;
  line-height: 24px;
  color: #444952;
  margin-top: 2px;
  cursor: pointer;
}

input[type=radio] {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  background-color: #FFFFFF;
  margin: 0;
  font: inherit;
  color: #D10931;
  width: 20px;
  height: 20px;
  border: 1px solid #D10931;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  -moz-appearance: none;
  -webkit-appearance: none;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
}

input[type=radio]::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 10px 10px #D10931;
}

input[type=radio]:checked::before {
  transform: scale(1);
}

.scroll-shadow-left,
.scroll-shadow-right {
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  pointer-events: none;
}

.scroll-shadow-left {
  left: 0;
  background: linear-gradient(to right, rgba(46, 51, 61, 0.096) 0%, rgba(28, 29, 32, 0.096) 0.01%, rgba(255, 255, 255, 0) 100%);
}

.scroll-shadow-right {
  right: 0;
  background: linear-gradient(to left, rgba(46, 51, 61, 0.096) 0%, rgba(28, 29, 32, 0.096) 0.01%, rgba(255, 255, 255, 0) 100%);
}

.shape-overview {
  display: flex;
  align-items: stretch;
}
@media screen and (max-width: 768px) {
  .shape-overview .shape-overview-filters {
    display: none;
  }
}

.shop-detail-overview {
  background-color: #F3F4F5;
  padding: 20px 40px 40px;
}
.shop-detail-overview-content {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

@media screen and (max-width: 1024px) {
  .shop-detail-overview {
    padding: 20px 32px 0;
  }
  .shop-detail-overview-content {
    flex-direction: column;
    padding-bottom: 128px;
    margin: 0;
  }
}
@media screen and (max-width: 600px) {
  .shop-detail-overview {
    padding: 0 16px;
  }
  .shop-detail-overview-content {
    padding-bottom: 80px;
  }
}
.material {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
@media screen and (max-width: 768px) {
  .material .material-filters {
    display: none;
  }
}

.cart-page {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
}

@media screen and (max-width: 1024px) {
  .cart-page {
    padding: 12px 32px 60px;
  }
}
@media screen and (max-width: 600px) {
  .cart-page {
    padding: 16px 16px 60px;
  }
}
.shipping-page {
  background-color: #F3F4F5;
  padding: 20px 40px 80px;
}
.shipping-page-content {
  display: flex;
  align-items: flex-start;
}
.shipping-page-content-settings {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.shipping-page-content .shipping-options {
  margin-top: 20px;
}
.shipping-page-content .order-summary {
  flex: 0 0 auto;
  margin-left: 20px;
}

@media screen and (max-width: 1440px) {
  .shipping-page .address-selection-items {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 1024px) {
  .shipping-page {
    padding: 12px 32px 60px;
  }
  .shipping-page-content {
    flex-wrap: wrap;
  }
  .shipping-page-content-settings {
    width: 100%;
    flex: 0 0 auto;
  }
  .shipping-page-content .order-summary {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
    padding: 0;
  }
  .shipping-page-content .order-summary-title, .shipping-page-content .order-summary-items, .shipping-page-content .order-summary-sum {
    display: none;
  }
  .shipping-page-content .order-summary-button {
    margin-top: 0;
  }
}
@media screen and (max-width: 600px) {
  .shipping-page {
    padding: 12px 16px 60px;
  }
}
.order-overview-page {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
}
.order-overview-page-content {
  display: flex;
  align-items: flex-start;
}
.order-overview-page-content .shipping-selection {
  margin-top: 20px;
}
.order-overview-page-content .order-summary {
  flex: 0 0 auto;
  margin-left: 20px;
}
.order-overview-page-content .address-selection {
  margin-top: 20px;
}
.order-overview-page-inner {
  flex: 1 1 auto;
}

@media screen and (max-width: 1200px) {
  .order-overview-page {
    padding: 20px 32px 60px;
  }
  .order-overview-page-content {
    flex-wrap: wrap;
  }
  .order-overview-page-inner {
    width: 100%;
    flex: 0 0 auto;
  }
  .order-overview-page .order-summary {
    margin-left: 0;
    margin-top: 20px;
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .order-overview-page {
    padding: 16px 16px 60px;
  }
}
.thank-you-page {
  height: 100%;
  padding: 0 40px 80px;
  background-color: #F3F4F5;
}

@media screen and (max-width: 1024px) {
  .thank-you-page {
    padding: 0 32px 60px;
  }
}
@media screen and (max-width: 600px) {
  .thank-you-page {
    padding: 0 16px 60px;
  }
}
.profile-user-page {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
  display: flex;
  flex-direction: row;
}
.profile-user-page-content {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .profile-user-page {
    padding: 12px 32px 60px;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .profile-user-page {
    padding: 16px 16px 60px;
  }
}
.profile-offer-overview {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
  display: flex;
  flex-direction: row;
}
.profile-offer-overview-content {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .profile-offer-overview {
    padding: 12px 32px 60px;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .profile-offer-overview {
    padding: 16px 16px 60px;
  }
}
.profile-user-management-page {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
  display: flex;
  flex-direction: row;
}
.profile-user-management-page-content {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .profile-user-management-page {
    padding: 12px 32px 60px;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .profile-user-management-page {
    padding: 16px 16px 60px;
  }
}
.profile-factory-certificates-page {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
  display: flex;
  flex-direction: row;
}
.profile-factory-certificates-page-content {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .profile-factory-certificates-page {
    padding: 12px 32px 60px;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .profile-factory-certificates-page {
    padding: 16px 16px 60px;
  }
}
.profile-carts-page {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
  display: flex;
  flex-direction: row;
}
.profile-carts-page-content {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .profile-user-page {
    padding: 12px 32px 60px;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .profile-user-page {
    padding: 16px 16px 60px;
  }
}
.profile-offer-detail-page {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
  display: flex;
  flex-direction: row;
}
.profile-offer-detail-page-content {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .profile-offer-detail-page {
    padding: 12px 32px 60px;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .profile-offer-detail-page {
    padding: 16px 16px 60px;
  }
}
.profile-cart-create-page {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
  display: flex;
  flex-direction: row;
}
.profile-cart-create-page-content {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .profile-cart-create-page {
    padding: 12px 32px 60px;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .profile-cart-create-page {
    padding: 16px 16px 60px;
  }
}
.profile-cart-edit-page {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
  display: flex;
  flex-direction: row;
}
.profile-cart-edit-page-content {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .profile-cart-edit-page {
    padding: 12px 32px 60px;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .profile-cart-edit-page {
    padding: 16px 16px 60px;
  }
}
.address-create-page {
  background-color: #F3F4F5;
  padding: 20px 40px 80px;
}

@media screen and (max-width: 1024px) {
  .address-create-page-page {
    padding: 12px 32px 60px;
  }
}
@media screen and (max-width: 600px) {
  .address-create-page {
    padding: 12px 16px 60px;
  }
}
.address-edit-page {
  background-color: #F3F4F5;
  padding: 20px 40px 80px;
}

@media screen and (max-width: 1024px) {
  .address-edit-page {
    padding: 12px 32px 60px;
  }
}
@media screen and (max-width: 600px) {
  .address-edit-page {
    padding: 12px 16px 60px;
  }
}
.profile-favorite-list-detail-page .profile-offer-overview-item {
  min-height: unset;
}

.profile-favorites-page .profile-carts-item-title-container {
  margin: 0;
}

.button-icon.remove-favorite-button {
  margin-left: 20px;
}
.button-icon.remove-favorite-button::before {
  height: 32px;
  width: 1px;
  content: "";
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #DFE1E5;
}
.button-icon.remove-favorite-button i {
  margin-left: 20px;
}

.search {
  margin: 0 auto;
  max-width: 940px;
  padding-bottom: 120px;
  padding-top: 80px;
}

.search-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 70px;
}

.search-header-title {
  color: #2B2F38;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 16px;
}

.search-header-result-title {
  color: #2B2F38;
  font-size: 20px;
  line-height: 28px;
}

.search-item {
  background-color: #FFFFFF;
  border-bottom: 1px solid #DFE1E5;
  padding: 20px;
  transition: background-color 0.4s ease;
  display: flex;
  align-items: center;
}
.search-item:first-of-type {
  margin-top: 80px;
}
.search-item:hover {
  background-color: #F3F4F5;
}
.search-item:hover .search-item-show-more {
  opacity: 1;
}

.search-item-container {
  position: relative;
  flex-grow: 1;
}
.search-item-container:after {
  color: #D10931;
  content: "\e908";
  font-family: "iconfont-ehg";
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.search-item-title {
  color: #444952;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  max-width: 540px;
}

.search-item-description {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #84878F;
  display: -webkit-box;
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
  max-width: 440px;
  overflow: hidden;
}

.search-item-link {
  color: #444952;
  direction: rtl;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  max-width: 540px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.search-item-link:before {
  border-bottom: 1px solid #444952;
  bottom: 5px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
}

.search-item-show-more {
  color: #D10931;
  font-size: 16px;
  line-height: 24px;
  opacity: 0;
  position: absolute;
  right: 48px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.4s ease;
}

.side-padding {
  padding-left: 40px;
  padding-right: 40px;
}

@media screen and (max-width: 1024px) {
  .side-padding {
    padding-left: 32px;
    padding-right: 32px;
  }
  .search-item-description {
    max-width: 300px;
  }
}
@media screen and (max-width: 800px) {
  .search-item:hover .search-item-show-more {
    display: none;
  }
  .search-item-description,
.search-item-link,
.search-item-title {
    margin-right: 40px;
  }
}
@media screen and (max-width: 600px) {
  .search {
    padding-bottom: 60px;
    padding-top: 40px;
  }
  .search-header {
    margin-bottom: 20px;
  }
  .search-header-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }
  .search-header-result-info {
    font-size: 18px;
    line-height: 24px;
  }
  .search-item {
    padding: 20px 0;
  }
  .search-item-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
  .search-item-description {
    font-size: 14px;
    line-height: 20px;
  }
  .search-item-description,
.search-item-link,
.search-item-title {
    margin-right: 0;
    max-width: calc(100% - 40px);
  }
  .search-no-results-info-title {
    font-weight: 700;
  }
  .search-no-results-info-description,
.search-no-results-info-title {
    font-size: 18px;
    line-height: 24px;
  }
  .side-padding {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.header {
  background-color: #F3F4F5;
  padding: 20px 40px;
}
.header-bottom {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
}
.header-title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  margin-right: 16px;
}
.header-favorite {
  border-left: 1px solid #BABDC2;
}
.header-filter {
  display: none;
  white-space: nowrap;
}
.header-favorite-button {
  background-color: transparent;
  color: #D10931;
  border: 0;
  padding: 0;
  margin-left: 17px;
  cursor: pointer;
}
.header-favorite-button-icon {
  font-size: 20px;
  margin-right: 7px;
  vertical-align: text-bottom;
}
.header-favorite-button-title {
  font-family: "Akkurat Pro", sans-serif;
  font-size: 16px;
  line-height: 24px;
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 16px 32px;
  }
}
@media screen and (max-width: 768px) {
  .header-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-right: 20px;
  }
  .header-bottom {
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 12px;
  }
  .header-favorite {
    border-left: 0;
  }
  .header-filter {
    display: block;
  }
  .header-favorite-button {
    width: 27px;
  }
  .header-favorite-button-icon::before {
    font-size: 20px;
    margin-right: 0;
  }
  .header-favorite-button-title {
    font-size: 0;
  }
}
@media screen and (max-width: 600px) {
  .header {
    padding: 16px 20px;
  }
}
.crumb {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-self: center;
}
@media screen and (max-width: 768px) {
  .crumb {
    display: none;
  }
}
.crumb:last-child .crumb-title {
  color: #84878F;
  pointer-events: none;
}
.crumb:last-child .crumb-split {
  display: none;
}
.crumb-title {
  font-size: 16px;
  line-height: 24px;
  color: #D10931;
  margin-right: 5px;
}
.crumb-split {
  color: #84878F;
  margin-right: 5px;
  align-self: center;
  font-size: 20px;
}
.crumb-split::before {
  height: 6px;
  width: 10px;
}

.breadcrumb {
  display: flex;
  flex-direction: row;
}
.breadcrumb-front {
  display: flex;
  flex-direction: row;
  height: 24px;
  align-self: flex-start;
  align-items: center;
}
.breadcrumb-back {
  font-size: 16px;
  line-height: 24px;
  color: #D10931;
  align-self: self-end;
}
.breadcrumb-arrow {
  margin-right: 5px;
  font-size: 20px;
  vertical-align: text-bottom;
}
.breadcrumb-arrow::before {
  width: 16px;
  height: 12px;
}
.breadcrumb-divider {
  border-right: 1px solid #BABDC2;
  margin-left: 11px;
  margin-right: 12px;
  height: 24px;
}
.breadcrumb-crumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .breadcrumb-divider {
    border-right: 0;
  }
}
.material-overview {
  background-color: #FFFFFF;
  margin-top: 40px;
  flex: 1 1 auto;
}
.material-overview-table {
  width: 100%;
}
.material-overview-table-headline {
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #DFE1E5;
  color: #84878F;
  text-align: left;
}
.material-overview-table-headline-title {
  padding-left: 20px;
  width: 40%;
}
@media screen and (max-width: 1200px) {
  .material-overview-table-headline-number {
    padding-right: 16px;
  }
  .material-overview-table-headline-shapes {
    display: none;
  }
}
.material-overview th:last-child,
.material-overview td:last-child {
  padding-right: 90px;
}

.material-list-header {
  border-bottom: 1px solid #DFE1E5;
}
.material-list-header th {
  background-color: #FFFFFF;
  padding: 24px 0 12px;
  position: sticky;
  top: 163px;
  z-index: 101;
}
.material-list-header-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #2B2F38;
  padding-left: 20px;
  text-align: start;
}
.material-list-header-shapes-icon {
  width: 40px;
  text-align: center;
}
.material-list-header-shapes-icon i {
  font-size: 20px;
  color: #BABDC2;
}
@media screen and (max-width: 1200px) {
  .material-list-header-shapes-icon {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .material-list-header th {
    top: 63px;
  }
}
.material-list-item {
  position: relative;
  border-bottom: 1px solid #DFE1E5;
}
.material-list-item > td {
  padding: 12px 0;
  vertical-align: middle;
}
.material-list-item-active {
  display: none;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  width: 4px;
  background-color: #D10931;
  z-index: 100;
}
.material-list-item-center {
  display: flex;
  align-items: center;
}
.material-list-item-title-text {
  font-size: 16px;
  line-height: 24px;
  color: #444952;
}
.material-list-item-title-arrow {
  font-size: 20px;
  color: #D10931;
  margin-bottom: 2px;
  margin-right: 14px;
  margin-left: 20px;
  transition: transform 0.4s ease;
}
.material-list-item-number {
  font-size: 16px;
  line-height: 24px;
  color: #444952;
}
@media screen and (max-width: 1200px) {
  .material-list-item-number {
    padding-right: 16px !important;
  }
}
.material-list-item-shapes-icon {
  width: 40px;
  text-align: center;
  font-size: 20px;
  margin-top: 2px;
}
.material-list-item-shapes-icon .icon-check {
  color: #D10931;
}
.material-list-item-shapes-icon .icon-minus {
  color: #BABDC2;
}
@media screen and (max-width: 1200px) {
  .material-list-item-shapes-icon {
    display: none;
  }
}
.material-list-item-subs {
  display: none;
}
.material-list-item--open .material-list-item-title-text,
.material-list-item--open .material-list-item-number {
  color: #D10931;
}
.material-list-item--open .material-list-item-title-arrow {
  transform: translateY(2px) rotate(90deg);
}
.material-list-item--open .material-list-item-active {
  display: block;
}
.material-list-item:hover {
  background-color: #F3F4F5;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.material-list-item:hover .material-list-item-title-text,
.material-list-item:hover .material-list-item-number {
  color: #D10931;
  transition: color 0.5s ease;
}

.material-list-item-sub {
  position: relative;
  border-bottom: 1px solid #DFE1E5;
  cursor: pointer;
}
.material-list-item-sub:not(.material-list-item-sub--open) {
  display: none;
}
.material-list-item-sub > td {
  padding: 12px 0;
  vertical-align: middle;
}
.material-list-item-sub > td:last-child {
  padding-right: 0;
}
.material-list-item-sub-active {
  display: none;
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  width: 4px;
  background-color: #D10931;
  z-index: 100;
}
.material-list-item-sub-center {
  display: flex;
  align-items: center;
}
.material-list-item-sub-title-text {
  font-size: 16px;
  line-height: 24px;
  color: #444952;
  margin-right: 12px;
}
.material-list-item-sub-title-icon {
  font-size: 20px;
  color: #BABDC2;
  margin-bottom: 1px;
  margin-right: 14px;
  margin-left: 54px;
}
.material-list-item-sub-number {
  font-size: 16px;
  line-height: 24px;
  color: #444952;
}
@media screen and (max-width: 1200px) {
  .material-list-item-sub-number {
    padding-right: 16px !important;
  }
}
.material-list-item-sub-show-details-content {
  display: none;
  align-items: center;
  justify-content: flex-end;
  color: #D10931;
}
.material-list-item-sub-show-details-content-text {
  font-size: 16px;
  line-height: 24px;
}
.material-list-item-sub-show-details-content-icon {
  font-size: 20px;
  margin-bottom: 1px;
  margin-right: 40px;
  margin-left: 4px;
}
.material-list-item-sub--open .material-list-item-sub-active {
  display: block;
}
.material-list-item-sub:hover {
  background-color: #F3F4F5;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.material-list-item-sub:hover .material-list-item-sub-title-icon,
.material-list-item-sub:hover .material-list-item-sub-title-text,
.material-list-item-sub:hover .material-list-item-sub-number {
  color: #D10931;
  transition: color 0.5s ease;
}
.material-list-item-sub:hover .material-list-item-sub-show-details-content {
  display: flex;
}
@media screen and (max-width: 1200px) {
  .material-list-item-sub-show-details {
    display: none;
  }
}

.checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 24px;
  margin-left: 24px;
  margin-bottom: 12px;
  /* stylelint-disable selector-max-specificity */
  /* stylelint-enable selector-max-specificity */
}
.checkbox-container {
  position: relative;
}
.checkbox input[type=checkbox] {
  display: none;
}
.checkbox input[type=checkbox] + .checkbox-title {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #444952;
  margin-left: 12px;
  transition: color 0.4s ease;
}
.checkbox input[type=checkbox] + .checkbox-title:hover {
  color: #D10931;
}
.checkbox input[type=checkbox] + .checkbox-title::after {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #FFFFFF;
  border: 1px solid #84878F;
  position: absolute;
  top: 0;
  left: calc(36px * (-1));
  display: block;
}
.checkbox input[type=checkbox] + .checkbox-title .checkbox-checkmark {
  opacity: 0;
  position: absolute;
  font-size: 24px;
  color: #FFFFFF;
  z-index: 1;
  display: block;
}
.checkbox input[type=checkbox] + .checkbox-title .checkbox-checkmark.icon-blech {
  top: -2px;
  font-size: 28px;
  left: calc(36px * (-1) - 3px);
}
.checkbox input[type=checkbox] + .checkbox-title .checkbox-checkmark.icon-check {
  top: -1px;
  font-size: 24px;
  left: calc(36px * (-1) - 1px);
}
.checkbox input[type=checkbox]:checked ~ .checkbox-arrow {
  left: 6px;
  transform: rotate(90deg);
}
.checkbox input[type=checkbox]:checked + .checkbox-title::after {
  border-color: #D10931;
  background-color: #D10931;
  display: block;
}
.checkbox input[type=checkbox]:checked + .checkbox-title .checkbox-checkmark {
  opacity: 1;
  display: block;
}
.checkbox-children .checkbox-arrow {
  display: block;
}
.checkbox-children input[type=checkbox] + .checkbox-title {
  margin-left: 32px;
}
.checkbox-children input[type=checkbox] + .checkbox-title::after {
  left: calc(56px * (-1));
}
.checkbox-children input[type=checkbox] + .checkbox-title .checkbox-checkmark.icon-blech {
  left: calc(56px * (-1) - 3px);
}
.checkbox-children input[type=checkbox] + .checkbox-title .checkbox-checkmark.icon-check {
  left: calc(56px * (-1) - 1px);
}
.checkbox-arrow {
  display: none;
  position: absolute;
  left: 8px;
  top: 0;
  line-height: 19px;
  font-size: 22px;
  transform-origin: center;
  color: #D10931;
}
.checkbox-form-icon {
  font-size: 24px;
  line-height: 1;
  margin-left: -4px;
}

.checkbox-list {
  margin-bottom: -12px;
}
.checkbox-list-title {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #84878F;
  line-height: 32px;
  margin-bottom: 9px;
}
.checkbox-list-children-container {
  display: none;
  padding-left: 34px;
}
.checkbox-list--open {
  display: block;
}

@media screen and (max-width: 768px) {
  .checkbox-list-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #2B2F38;
  }
}
.checkbox-list-title-container {
  display: flex;
  gap: 2.5rem;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.footer {
  background-color: #2B2F38;
  color: #F3F4F5;
}
.footer-max-width {
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
}
.footer-information {
  width: 30%;
  padding: 100px 40px;
}
.footer-links {
  width: 70%;
  border-left: 1px solid #444952;
}
.footer-links-content {
  display: flex;
  flex-wrap: wrap;
  padding: 100px 20px 100px 80px;
}
.footer-links-column {
  width: 33.3333%;
  padding-right: 20px;
  word-break: break-word;
}
.footer-links-column-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #84878F;
}
.footer-links-column-title::after {
  display: none;
  position: absolute;
  right: 20px;
  top: 20px;
  font-size: 20px;
  pointer-events: none;
  font-family: "iconfont-ehg";
  transition: transform 0.4s ease;
  content: "\e901";
}
.footer-links-column-title--open::after {
  transform: rotate(180deg);
}
.footer-links-column-content {
  margin-top: 20px;
  font-size: 0;
}
.footer-links-column-content a {
  display: block;
  font-size: 16px;
  line-height: 24px;
}
.footer-links-lower {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 15px 50px;
  border-top: 1px solid #444952;
}
.footer-links-lower-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.footer-links-lower-items a {
  display: block;
  margin: 10px 30px;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #84878F;
}
.footer-links-lower-socials {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
}
.footer-links-lower-socials a {
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 24px;
  text-align: center;
}
.footer-information-logo {
  width: 100%;
  max-width: 210px;
  margin-bottom: 40px;
}
.footer-information-text {
  color: #BABDC2;
  font-size: 16px;
  line-height: 24px;
}
.footer-information-link {
  margin-top: 8px;
  font-weight: 700;
  color: #BABDC2;
}
.footer-information-link span {
  margin-right: 8px;
}
.footer-information-link:first-of-type {
  margin-top: 20px;
}
.footer-information-link a {
  color: #FFFFFF;
}

@media screen and (max-width: 1024px) {
  .footer-information {
    padding: 100px 32px;
  }
  .footer-links-content {
    padding-left: 32px;
    padding-right: 12px;
  }
  .footer-links-lower {
    padding: 15px 2px;
  }
}
@media screen and (max-width: 768px) {
  .footer-max-width {
    display: block;
  }
  .footer-information {
    width: 100%;
    border-bottom: 1px solid #444952;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .footer-links-content {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .footer-links {
    width: 100%;
    border-left: none;
  }
}
@media screen and (min-width: 601px) {
  .footer-links-column-content {
    display: block !important;
  }
  .footer-links-column-content a:not(:last-child) {
    margin-bottom: 12px;
  }
}
@media screen and (max-width: 600px) {
  .footer-information {
    padding: 60px 16px;
    border-bottom: none;
  }
  .footer-links-content {
    padding: 0;
    border-top: 1px solid #444952;
  }
  .footer-links-column {
    width: 100%;
    padding: 0;
    border-bottom: 1px solid #444952;
  }
  .footer-links-column-title {
    cursor: pointer;
    padding: 20px 56px 20px 16px;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }
  .footer-links-column-title::after {
    display: block;
  }
  .footer-links-column-content {
    display: none;
    margin-top: 0;
    padding: 8px 0 20px;
  }
  .footer-links-column-content a {
    padding: 12px 16px;
    display: inline-block;
    vertical-align: top;
    width: 50%;
    color: #BABDC2;
  }
  .footer-links-lower {
    padding: 0;
    border-top: none;
  }
  .footer-links-lower-items {
    padding: 38px 0;
  }
  .footer-links-lower-items a {
    margin: 0;
    padding: 16px;
    width: 50%;
  }
  .footer-links-lower-socials {
    width: 100%;
    padding: 24px 16px;
    border-top: 1px solid #444952;
    justify-content: center;
  }
  .footer-information-logo {
    max-width: 170px;
    margin-bottom: 24px;
  }
}
.filter-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
  flex-direction: column;
  overflow: hidden;
  z-index: 2000;
}
.filter-overlay-header {
  width: 100%;
  padding: 16px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DFE1E5;
}
.filter-overlay-header-text {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}
.filter-overlay-body {
  flex: 1 1 auto;
  padding: 16px 16px 0;
  width: 100%;
  overflow: auto;
}
.filter-overlay-footer {
  padding: 16px;
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.filter-overlay-footer > .button-primary, .filter-overlay-footer > .button-secondary {
  width: calc(50% - 5px);
  flex: 0 0 auto;
}

@media screen and (max-width: 768px) {
  .filter-overlay--open {
    display: flex;
  }
}
.button-primary {
  position: relative;
  width: 100%;
  display: block;
  color: #FFFFFF;
  background-color: #D10931;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  transition: background-color 0.4s ease;
  padding: 12px 16px;
  text-align: center;
}
.button-primary:not(:disabled):hover {
  background-color: #EB0A37;
}
.button-primary:disabled {
  color: #BABDC2;
  background-color: #F3F4F5;
}
.button-primary i {
  display: block;
  position: absolute;
  top: 12px;
  font-size: 24px;
}
.button-primary span {
  display: inline-block;
  vertical-align: middle;
}
.button-primary--icon:not(.button-primary--icon-right) {
  text-align: right;
  padding-left: 50px;
}
.button-primary--icon:not(.button-primary--icon-right) i {
  left: 16px;
}
.button-primary--icon.button-primary--icon-right {
  text-align: left;
  padding-right: 50px;
}
.button-primary--icon.button-primary--icon-right i {
  right: 16px;
}

.button-secondary {
  position: relative;
  width: 100%;
  display: block;
  color: #D10931;
  background-color: #FFFFFF;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  border: 2px solid #D10931;
  transition: color 0.4s ease, border-color 0.4s ease;
  padding: 10px 14px;
  text-align: center;
}
.button-secondary:not(:disabled):hover {
  color: #EB0A37;
  border-color: #EB0A37;
}
.button-secondary:disabled {
  color: #BABDC2;
  border-color: #BABDC2;
}
.button-secondary i {
  display: block;
  position: absolute;
  top: 12px;
  font-size: 24px;
}
.button-secondary span {
  display: inline-block;
  vertical-align: middle;
}
.button-secondary--icon:not(.button-secondary--icon-right) {
  text-align: right;
  padding-left: 50px;
}
.button-secondary--icon:not(.button-secondary--icon-right) i {
  left: 16px;
}
.button-secondary--icon.button-secondary--icon-right {
  text-align: left;
  padding-right: 50px;
}
.button-secondary--icon.button-secondary--icon-right i {
  right: 16px;
}

.button-text {
  color: #D10931;
  font-size: 16px;
  line-height: 24px;
  transition: color 0.4s ease;
}
.button-text:not(:disabled):hover {
  color: #EB0A37;
}
.button-text:disabled {
  color: #BABDC2;
}
.button-text i {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  margin-right: 5px;
}
.button-text span {
  display: inline-block;
  vertical-align: middle;
}
.button-text.reset-filter-button {
  font-size: 14px;
  display: flex;
  align-items: baseline;
}
.button-text.reset-filter-button i {
  font-size: 21px;
  position: relative;
  top: 4px;
}

.button-icon {
  color: #D10931;
  font-size: 16px;
  line-height: 24px;
  transition: color 0.4s ease;
  position: relative;
}
.button-icon:disabled {
  color: #BABDC2;
  cursor: unset;
}
.button-icon:not(:disabled):hover {
  color: #EB0A37;
}
.button-icon:not(:disabled):hover .button-icon-tooltip {
  visibility: visible;
  opacity: 1;
}
.button-icon i {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
}
.button-icon-tooltip {
  visibility: hidden;
  display: block;
  font-family: "Akkurat Pro", serif !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 120px;
  background-color: #2B2F38;
  color: #FFFFFF;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.button-icon-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2B2F38 transparent transparent transparent;
}

.loading-spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: unset;
  pointer-events: none;
}
.loading-spinner:after {
  content: "";
  display: block;
  width: 17px;
  height: 17px;
  margin: 0 auto;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  border-color: #BABDC2 #BABDC2 #BABDC2 transparent;
  animation: loading-animation 1.5s linear infinite;
  cursor: unset;
  pointer-events: none;
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@media screen and (max-width: 1024px) {
  .button-icon-tooltip {
    display: none;
  }
}
.text-input-container {
  border: 1px solid #DFE1E5;
}
.text-input-label {
  font-size: 14px;
  line-height: 20px;
  color: #444952;
  margin-bottom: 4px;
}
.text-input input {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
  height: 46px;
  width: 100%;
  padding: 12px 16px;
  font-family: "Akkurat Pro", sans-serif;
  color: #2B2F38;
}
.text-input input::placeholder, .text-input input:-ms-input-placeholder, .text-input input::-ms-input-placeholder, .text-input input::-webkit-input-placeholder, .text-input input::-moz-placeholder {
  font-family: "Akkurat Pro", sans-serif;
  color: #84878F;
  font-size: 16px;
  line-height: 24px;
}
.text-input input.disabled {
  background-color: #F3F4F5;
  color: #84878F;
  pointer-events: none;
}
.text-input button {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
  display: none;
  top: 0;
  right: 0;
  position: absolute;
  color: #2B2F38;
  height: 48px;
  width: 48px;
  text-align: center;
  font-size: 24px;
}
.text-input--error .text-input-container {
  border-color: #D10931;
}
.text-input-error-text {
  font-size: 12px;
  line-height: 16px;
  padding-top: 4px;
  color: #D10931;
}
.text-input--button input {
  width: calc(100% - 48px);
  padding-right: 0;
}
.text-input--button button {
  display: block;
}

.select-box-label {
  font-size: 14px;
  line-height: 20px;
  color: #444952;
  margin-bottom: 4px;
}
.select-box-select {
  position: relative;
  display: block;
}
.select-box-select select {
  cursor: pointer;
  color: #2B2F38;
  width: 100%;
  height: 48px;
  font-family: "Akkurat Pro", sans-serif;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 48px 12px 16px;
  border: 1px solid #DFE1E5;
  -webkit-appearance: none;
}
.select-box-select::after {
  position: absolute;
  right: 0;
  top: 0;
  width: 48px;
  height: 48px;
  text-align: center;
  font-family: "iconfont-ehg";
  content: "\e901";
  color: #D10931;
  font-size: 24px;
  line-height: 48px;
  pointer-events: none;
}
.select-box-select--error {
  border: 1px solid #D10931;
}
.select-box-error-message {
  display: flex;
  flex-direction: row;
  color: #D10931;
  margin-top: 6px;
}
.select-box-error-message span {
  font-size: 12px;
  line-height: 16px;
  margin: 4px 0 0 4px;
}
.select-box-error-icon i {
  font-size: 20px;
}
.select-box-error-icon:hover .select-box-error-tooltip {
  visibility: visible;
  opacity: 1;
  display: block;
}
.select-box-error-tooltip {
  visibility: hidden;
  display: block;
  font-family: "Akkurat Pro", serif !important;
  font-weight: 400;
  font-size: 14px !important;
  line-height: 20px !important;
  width: 300px;
  background-color: #FFFFFF;
  color: #2B2F38;
  padding: 20px;
  position: absolute;
  z-index: 1;
  bottom: 150%;
  left: -70%;
  opacity: 0;
  transition: opacity 0.3s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
}
.select-box-error-tooltip a {
  color: #D10931;
  text-decoration: underline #D10931;
}
.select-box-error-tooltip::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  left: 3%;
  box-sizing: border-box;
  border: 8px solid #2B2F38;
  border-color: transparent transparent #FFFFFF #FFFFFF;
  transform-origin: 0 0;
  transform: rotate(-45deg);
  box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.16);
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 1000;
}
.navigation::after {
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
  content: "";
  background-color: #DFE1E5;
  height: 1px;
  width: 100%;
}
.navigation-spacer {
  height: 164px;
  flex: 0 0 auto;
}
.navigation-toggler {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
  display: none;
  position: absolute;
  right: 30px;
  top: 10px;
  height: 44px;
  width: 44px;
  padding: 10px;
  z-index: 1;
}
.navigation-toggler div {
  height: 2px;
  background-color: #D10931;
  width: 100%;
  transition: opacity 0.4s ease, transform 0.4s ease;
}
.navigation-toggler div:not(:last-child) {
  margin-bottom: 6px;
}
.navigation--open .navigation-toggler div {
  opacity: 0;
}
.navigation--open .navigation-toggler div:first-child {
  transform: translateY(8px) rotate(45deg);
  opacity: 1;
}
.navigation--open .navigation-toggler div:last-child {
  transform: translateY(-8px) rotate(-45deg);
  opacity: 1;
}
.navigation-top {
  padding: 24px 40px 24px 290px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.navigation-logo {
  width: 100%;
  max-width: 210px;
  position: absolute;
  left: 40px;
  top: 24px;
  z-index: 1;
}
.navigation-search {
  max-width: 420px;
  min-height: 48px;
  width: 100%;
}
.navigation-language-chooser {
  margin-left: 20px;
  min-height: 48px;
}
.navigation-language-chooser select {
  width: 92px;
}
.navigation-inner {
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navigation-items {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media screen and (max-width: 1024px) {
  .navigation-items {
    width: 100%;
  }
}
.navigation-item {
  position: relative;
  cursor: pointer;
  font-size: 18px;
  line-height: 24px;
}
.navigation-item--menu::after {
  display: block;
  position: absolute;
  top: 22px;
  right: 15px;
  color: #D10931;
  content: "\e901";
  font-family: "iconfont-ehg";
  font-size: 24px;
  pointer-events: none;
  transition: transform 0.4s ease;
}
.navigation-item-title {
  display: block;
  position: relative;
  text-align: center;
  transition: color 0.4s ease;
  padding: 22px 20px;
  min-width: 52px;
}
.navigation-item--active .navigation-item-title, .navigation-item:hover .navigation-item-title {
  color: #D10931;
}
.navigation-item-menu {
  position: absolute;
  padding: 20px;
  cursor: auto;
  top: 100%;
  left: -20px;
  background-color: #FFFFFF;
  transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.6s ease;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.16);
  display: flex;
}
.navigation-item-menu-back {
  color: #D10931;
  display: none;
  padding: 20px 32px;
  width: 100%;
  text-align: left;
  height: 64px;
}
.navigation-item-menu-back i {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
  margin-right: 5px;
}
.navigation-item-menu-back span {
  display: inline-block;
  vertical-align: middle;
  font-size: 18px;
  line-height: 24px;
}
.navigation-item-menu-back--line {
  border-bottom: 1px solid #DFE1E5;
}
.navigation-item-menu-column {
  padding: 10px;
}
.navigation-item-menu-column + .navigation-item-menu-column--without-title {
  margin-top: 26px;
}
.navigation-item-menu-link {
  position: relative;
  display: flex;
  width: 220px;
  padding: 10px 40px 10px 10px;
  font-size: 18px;
  line-height: 24px;
  transition: color 0.4s ease, background-color 0.4s ease;
}
.navigation-item-menu-link i {
  color: #BABDC2;
  font-size: 24px;
  margin-right: 5px;
  transition: color 0.4s ease;
}
.navigation-item-menu-link::after {
  content: "\e908";
  font-family: "iconfont-ehg";
  color: #D10931;
  font-size: 24px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.4s ease;
}
.navigation-item-menu-link--active {
  color: #D10931;
}
.navigation-item-menu-content {
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: repeat(5, auto);
  column-gap: 20px;
  margin-top: 0;
}
.navigation-buttons {
  display: flex;
  align-items: center;
}
.navigation-button {
  position: relative;
  padding: 20px;
  line-height: 24px;
}
.navigation-button:not(:first-child)::before {
  height: 32px;
  width: 1px;
  content: "";
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #DFE1E5;
}
.navigation-logout {
  display: none;
}

@media screen and (min-width: 1025px) {
  .navigation-content {
    display: block !important;
  }
  .navigation-item::before {
    display: block;
    position: absolute;
    bottom: 1px;
    height: 4px;
    width: 52px;
    background-color: #D10931;
    content: "";
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease;
  }
  .navigation-item--active::before {
    opacity: 1;
  }
  .navigation-item:hover::after {
    transform: rotate(180deg);
  }
  .navigation-item:first-child .navigation-item-title::after {
    height: 32px;
    width: 1px;
    content: "";
    pointer-events: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: #DFE1E5;
  }
  .navigation-item--menu .navigation-item-title {
    padding-right: 45px;
  }
  .navigation-item-menu {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
  .navigation-item-menu-title {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 700;
    color: #84878F;
    margin-bottom: 10px;
    padding: 0 10px;
  }
  .navigation-item-menu-link:hover {
    background-color: #F3F4F5;
    color: #D10931;
  }
  .navigation-item-menu-link:hover::after {
    opacity: 1;
  }
  .navigation-item-menu-link:hover i {
    color: #D10931;
  }
  .navigation .navigation-item--menu.navigation-item--active::before {
    left: calc(50% - 12px);
  }
}
@media screen and (max-width: 1024px) {
  .navigation {
    height: 64px;
  }
  .navigation-spacer {
    height: 64px;
  }
  .navigation-content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 64px 0;
    display: none;
    background-color: #FFFFFF;
  }
  .navigation-toggler {
    display: block;
    right: 22px;
  }
  .navigation-top {
    padding: 24px 32px;
  }
  .navigation-logo {
    top: 16px;
    left: 32px;
    max-width: 150px;
  }
  .navigation-search {
    max-width: none;
  }
  .navigation-search-input input {
    padding-right: 16px;
    padding-left: 48px;
  }
  .navigation-search-input i {
    color: #84878F;
  }
  .navigation-search-input button {
    right: auto;
    left: 0;
  }
  .navigation-inner {
    padding-left: 32px;
    padding-right: 0;
    overflow: auto;
    height: calc(100% - 96px);
    display: block;
  }
  .navigation-item {
    width: 100%;
    border-bottom: 1px solid #DFE1E5;
  }
  .navigation-item::after {
    transform: rotate(-90deg);
    right: 25px;
  }
  .navigation-item-title {
    min-width: 0;
    padding: 20px 60px 19px 0;
    text-align: left;
  }
  .navigation-item-menu {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    top: auto;
    width: 100%;
    overflow: auto;
    height: calc(100vh - 160px);
    background-color: #FFFFFF;
    box-shadow: none;
    transform: translateX(100%);
    z-index: 1;
    padding: 0;
    transition: transform 0.6s ease;
  }
  .navigation-item--open .navigation-item-menu {
    transform: translateX(0);
  }
  .navigation-item-menu-back {
    display: block;
  }
  .navigation-item-menu-column {
    padding: 0;
  }
  .navigation-item-menu-column + .navigation-item-menu-column--without-title {
    margin-top: 0;
  }
  .navigation-item-menu-column + .navigation-item-menu-column--without-title .navigation-item-menu-link:first-child {
    border-top: 1px solid #DFE1E5;
  }
  .navigation-item-menu-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    padding: 17px 32px;
    text-transform: none;
    color: #2B2F38;
    border-top: 1px solid #DFE1E5;
    border-bottom: 1px solid #DFE1E5;
    margin-bottom: 0;
  }
  .navigation-item-menu-link {
    width: 100%;
    padding: 20px 0 19px;
    border-bottom: 1px solid #DFE1E5;
  }
  .navigation-item-menu-link:last-child {
    padding: 20px 0;
    border-bottom: none;
  }
  .navigation-item-menu-content {
    display: block;
    padding-left: 32px;
    margin-top: 0;
  }
  .navigation-buttons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: 1px solid #DFE1E5;
    background-color: #FFFFFF;
  }
  .navigation-buttons--logged-in {
    justify-content: space-between;
  }
  .navigation-buttons--logged-in .navigation-login {
    width: unset;
  }
  .navigation-buttons--logged-in .navigation-login::after {
    display: none;
  }
  .navigation-buttons--logged-in .navigation-logout {
    display: block;
  }
  .navigation-buttons--logged-in .navigation-logout::before {
    display: none;
  }
  .navigation-login {
    width: 100%;
    padding-right: 52px;
    padding-left: 32px;
  }
}
@media screen and (max-width: 1024px) and (max-width: 600px) {
  .navigation-login {
    padding-right: 40px;
    padding-left: 16px;
  }
}
@media screen and (max-width: 1024px) {
  .navigation-logout {
    padding-right: 32px;
  }
}
@media screen and (max-width: 600px) {
  .navigation-toggler {
    right: 6px;
  }
  .navigation-top {
    padding: 16px;
  }
  .navigation-logo {
    left: 16px;
  }
  .navigation-language-chooser {
    margin-left: 16px;
  }
  .navigation-inner {
    padding-left: 16px;
    height: calc(100% - 80px);
  }
  .navigation-item::after {
    right: 15px;
  }
  .navigation-item-menu {
    height: calc(100vh - 144px);
  }
  .navigation-item-menu-back {
    padding: 20px 16px;
  }
  .navigation-item-menu-title {
    padding: 17px 16px;
  }
  .navigation-item-menu-content {
    padding-left: 16px;
  }
  .navigation-login {
    padding-right: 40px;
    padding-left: 16px;
  }
  .navigation-logout {
    padding-right: 16px;
  }
}
.navigation-cart,
.navigation-favorites {
  color: #D10931;
  font-size: 16px;
  line-height: 24px;
  transition: color 0.4s ease;
}
.navigation-cart:hover,
.navigation-favorites:hover {
  color: #EB0A37;
}
.navigation-cart i,
.navigation-favorites i {
  display: inline-block;
  vertical-align: middle;
  font-size: 24px;
}
.navigation-cart span,
.navigation-favorites span {
  display: inline-block;
  vertical-align: middle;
}
.navigation-cart-counter,
.navigation-favorites-counter {
  top: 10px;
  right: 10px;
  position: absolute;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: #2B2F38;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 22px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .navigation-cart,
.navigation-favorites {
    display: none;
  }
}
.cart-c2a {
  position: absolute;
  top: 0;
  right: 40px;
  min-height: 100px;
  height: calc(100% - 40px);
  width: 60px;
  pointer-events: none;
  z-index: 999;
}
.cart-c2a-content {
  position: sticky;
  top: calc(var(--vh, 1vh) * 100 - 100px);
  right: 0;
  display: none;
  background-color: #D10931;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  text-align: center;
  pointer-events: auto;
}
.cart-c2a-content i {
  color: #FFFFFF;
  font-size: 24px;
  line-height: 60px;
}
.cart-c2a-counter {
  top: -5px;
  right: -5px;
  position: absolute;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  background-color: #2B2F38;
  color: #FFFFFF;
  font-size: 13px;
  line-height: 26px;
  font-weight: 700;
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .cart-c2a-content {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .cart-c2a {
    height: calc(100% - 16px);
    right: 16px;
  }
  .cart-c2a-content {
    top: calc(var(--vh, 1vh) * 100 - 76px);
    right: 16px;
    bottom: 16px;
  }
}
.shape-overview-table {
  width: calc(100% - 250px);
  flex: 0 0 auto;
}
.shape-overview-table table {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  width: 100%;
  min-width: 800px;
}
.shape-overview-table table tr {
  border-bottom: 1px solid #DFE1E5;
  transition: background-color 0.3s ease;
}
.shape-overview-table table tr:hover:not(:first-child) {
  background-color: #F3F4F5;
}
.shape-overview-table table th {
  vertical-align: bottom;
  white-space: nowrap;
}
.shape-overview-table table th,
.shape-overview-table table td {
  min-height: 48px;
  padding: 12px;
  position: relative;
}
.shape-overview-table table td {
  transition: background-color 0.3s ease;
}
.shape-overview-table table td:empty::before {
  color: #BABDC2;
  content: "—";
  font-size: 12px;
}
.shape-overview-table table td a {
  position: absolute;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 12px 50px 12px 12px;
  color: #D10931;
  transition: background-color 0.3s ease, color 0.3s ease;
  height: 100%;
}
.shape-overview-table table td a::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  font-family: "iconfont-ehg";
  content: "\e908";
  color: #FFFFFF;
  font-size: 24px;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.shape-overview-table table td a:hover {
  background-color: #D10931;
  color: #FFFFFF;
}
.shape-overview-table table td a:hover::after {
  opacity: 1;
}
.shape-overview-table table td::after {
  transition: background-color 0.3s ease;
  content: "";
  height: 20000px;
  left: 0;
  position: absolute;
  top: -10000px;
  width: 100%;
  z-index: -1;
}
.shape-overview-table table td:hover {
  background-color: #DFE1E5;
}
.shape-overview-table table td:hover::after {
  background-color: #F3F4F5;
}
.shape-overview-table-shadow::after {
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 50px;
  pointer-events: none;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.15) 100%);
}
.shape-overview-table-content {
  width: 100%;
}
.shape-overview-table-content-inner {
  overflow: auto;
}
.shape-overview-table-title-extra {
  display: block;
  color: #84878F;
}
.shape-overview-table-details-hover-desktop {
  margin-left: 4px;
}

@media screen and (max-width: 1024px) {
  .shape-overview-table-shadow::after {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .shape-overview-table {
    width: 100%;
  }
  .shape-overview-table-content-inner {
    overflow: scroll;
  }
  .shape-overview-table-shadow::after {
    display: none;
  }
  .shape-overview-table-title-extra {
    display: none;
  }
  .shape-overview-table-details-hover-desktop {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .shape-overview-table-shadow::after {
    display: block;
  }
}
.shape-overview-filters {
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 250px;
  flex: 0 0 auto;
  border-right: 1px solid #DFE1E5;
}
.shape-overview-filters-filter {
  width: 100%;
  margin-top: 40px;
}
.shape-overview-filters-filter:first-child {
  margin-top: 0;
}

@media screen and (max-width: 768px) {
  .shape-overview-filters {
    width: 100%;
    border-right: none;
    padding: 0;
  }
}
.shop-detail-informations {
  display: block;
  width: 25%;
  padding: 10px;
}
.shop-detail-informations-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2B2F38;
}
.shop-detail-informations-title::before {
  display: none;
  position: absolute;
  top: 18px;
  left: 12px;
  color: #D10931;
  content: "\e903";
  font-family: "iconfont-ehg";
  font-size: 24px;
  pointer-events: none;
  transition: transform 0.4s ease;
}
.shop-detail-informations-title--open::before {
  transform: rotate(90deg);
}
.shop-detail-informations-container {
  margin-top: 20px;
  background-color: #FFFFFF;
  padding: 20px;
  display: block;
}
.shop-detail-informations-container-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 166px;
  border: 1px solid #DFE1E5;
}
.shop-detail-informations-container-information {
  margin-top: 20px;
}
.shop-detail-informations-container-information-pair {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 12px;
}
.shop-detail-informations-container-information-pair-title {
  color: #84878F;
}
.shop-detail-informations-container-information-pair-value {
  margin-left: 5px;
}
.shop-detail-informations-container-information :last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 1024px) {
  .shop-detail-informations {
    background-color: #FFFFFF;
    width: 100%;
    padding: 0;
    margin-bottom: 2px;
  }
  .shop-detail-informations-title {
    background-color: #FFFFFF;
    padding: 18px 40px 20px;
    cursor: pointer;
  }
  .shop-detail-informations-title::before {
    display: block;
    top: 16px;
  }
  .shop-detail-informations-container {
    margin-top: 0;
    padding: 0 16px 12px;
    display: none;
  }
  .shop-detail-informations-container--open {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .shop-detail-informations-title {
    padding: 16px 16px 16px 40px;
  }
}
@media screen and (max-width: 768px) {
  .shop-detail-informations {
    width: 100%;
  }
}
.shop-detail-configuration {
  display: block;
  width: 50%;
  padding: 10px;
}
.shop-detail-configuration-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2B2F38;
}
.shop-detail-configuration-title::before {
  display: none;
  position: absolute;
  top: 18px;
  left: 12px;
  color: #D10931;
  content: "\e903";
  font-family: "iconfont-ehg";
  font-size: 24px;
  pointer-events: none;
  transition: transform 0.4s ease;
}
.shop-detail-configuration-title--open::before {
  transform: rotate(90deg);
}
.shop-detail-configuration-container {
  margin-top: 20px;
  background-color: #FFFFFF;
  padding: 20px;
  display: block;
}
.shop-detail-configuration-container-step {
  padding-bottom: 28px;
}
.shop-detail-configuration-container-step:not(:last-child) {
  border-bottom: 1px solid #DFE1E5;
  margin-bottom: 20px;
}
.shop-detail-configuration-container-step-counter, .shop-detail-configuration-container-step-input {
  width: 140px;
}
.shop-detail-configuration-container-step-select {
  width: 280px;
}
.shop-detail-configuration-container-step-cut-types {
  display: none;
}
.shop-detail-configuration-container-step-cut-types--open {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.shop-detail-configuration-container-step-content:not(:first-child) {
  margin-top: 20px;
}

@media screen and (max-width: 1024px) {
  .shop-detail-configuration {
    background-color: #FFFFFF;
    width: 100%;
    padding: 0;
    margin-bottom: 2px;
  }
  .shop-detail-configuration-title {
    padding: 18px 40px 20px;
    cursor: pointer;
  }
  .shop-detail-configuration-title::before {
    display: block;
    top: 16px;
  }
  .shop-detail-configuration-container {
    margin-top: 0;
    padding: 0 16px 12px;
    display: none;
  }
  .shop-detail-configuration-container--open {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .shop-detail-configuration-title {
    padding: 16px 16px 16px 40px;
  }
}
@media screen and (max-width: 768px) {
  .shop-detail-configuration {
    width: 100%;
  }
  .shop-detail-configuration-container-step-cut-types {
    display: none;
  }
  .shop-detail-configuration-container-step-cut-types--open {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .shop-detail-configuration-container-step-counter,
.shop-detail-configuration-container-step-input {
    width: 160px;
  }
}
@media screen and (max-width: 480px) {
  .shop-detail-configuration-container-step-select {
    width: 100%;
  }
}
.configuration-step-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 28px;
}
.configuration-step-header-number {
  height: 32px;
  width: 32px;
  border: 1px solid #D10931;
  border-radius: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
}
.configuration-step-header-number span {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #D10931;
}
.configuration-step-header-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #444952;
}

.amount-counter {
  display: block;
}
.amount-counter-label {
  font-size: 14px;
  line-height: 20px;
  color: #444952;
  margin-bottom: 4px;
}
.amount-counter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  border: 1px solid #DFE1E5;
  padding: 12px;
}
.amount-counter-container-button {
  width: 24px;
  height: 24px;
}
.amount-counter-container-button i {
  font-size: 24px;
  color: #D10931;
}
.amount-counter-container-button:disabled i {
  color: #BABDC2;
}
.amount-counter-container-number {
  font-size: 16px;
  line-height: 24px;
}

.item-image-selection {
  width: calc(50% - 10px);
  margin-bottom: 20px;
}
.item-image-selection:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .item-image-selection {
    width: 100%;
  }
}
.item-image-selection-container {
  width: 100%;
  border: 1px solid #DFE1E5;
  padding: 1px;
  transition: border 0.4s ease;
}
.item-image-selection-container:not(.item-image-selection-container--selected):hover {
  border: 1px solid #D10931;
}
.item-image-selection-container--selected {
  border: 2px solid #D10931;
  padding: 0;
}
.item-image-selection-container-image {
  height: 92px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 0 0 #DFE1E5;
}
.item-image-selection-container-title {
  font-size: 14px;
  line-height: 20px;
  color: #444952;
  min-height: 36px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 7px;
  padding-right: 20px;
}
.item-image-selection-degree {
  display: none;
}
.item-image-selection-degree--selected {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
}
.item-image-selection-degree-select {
  width: calc(50% - 8px);
}

.data-sheets-list {
  margin-top: 44px;
}
.data-sheets-list-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #444952;
}

.data-sheet {
  display: flex;
  flex-direction: row;
  height: 76px;
  width: 100%;
  align-items: center;
  padding: 16px 0;
  justify-content: space-between;
}
.data-sheet-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  overflow: hidden;
}
.data-sheet-pdf {
  font-size: 40px;
  color: #D10931;
}
.data-sheet-information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  margin-left: 8px;
  margin-right: 16px;
  overflow: hidden;
}
.data-sheet-information-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  color: #444952;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-sheet-information-sub {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  line-height: 16px;
  font-weight: 700;
  color: #BABDC2;
}
.data-sheet-information-sub-info-point {
  padding: 0 5px;
}

.shop-detail-summary {
  display: block;
  width: 25%;
  padding: 10px;
}
.shop-detail-summary-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2B2F38;
}
.shop-detail-summary-title::before {
  display: none;
  position: absolute;
  top: 18px;
  left: 12px;
  color: #D10931;
  content: "\e903";
  font-family: "iconfont-ehg";
  font-size: 24px;
  pointer-events: none;
  transition: transform 0.4s ease;
}
.shop-detail-summary-title--open::before {
  transform: rotate(90deg);
}
.shop-detail-summary-container {
  margin-top: 20px;
  background-color: #FFFFFF;
  padding: 20px;
  display: block;
}
.shop-detail-summary-image {
  display: none;
  justify-content: center;
  align-items: center;
  height: 166px;
  border: 1px solid #DFE1E5;
  margin-bottom: 16px;
}
.shop-detail-summary-headline {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #444952;
}
.shop-detail-summary-info {
  font-size: 16px;
  line-height: 24px;
  color: #84878F;
  margin-top: 12px;
}
.shop-detail-summary-button {
  margin-top: 40px;
}
.shop-detail-summary-cart-container {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100px;
  height: calc(100% - 40px);
  width: 100%;
  pointer-events: none;
}
.shop-detail-summary-cart {
  position: sticky;
  top: calc(var(--vh, 1vh) * 100 - 88px);
  right: 0;
  display: none;
  pointer-events: auto;
}

@media screen and (max-width: 1024px) {
  .shop-detail-summary {
    padding: 0;
    width: 100%;
    background-color: #FFFFFF;
  }
  .shop-detail-summary-title {
    background-color: #FFFFFF;
    padding: 18px 40px 20px;
    cursor: pointer;
  }
  .shop-detail-summary-title::before {
    display: block;
    top: 16px;
  }
  .shop-detail-summary-container {
    margin-top: 0;
    padding: 0 16px 12px;
    display: none;
  }
  .shop-detail-summary-container--open {
    display: block;
  }
  .shop-detail-summary-image {
    display: flex;
  }
  .shop-detail-summary-button {
    display: none;
  }
  .shop-detail-summary-cart {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .shop-detail-summary-title {
    padding: 16px 16px 16px 40px;
  }
  .shop-detail-summary-cart-container {
    height: calc(100% - 16px);
  }
  .shop-detail-summary-cart {
    top: calc(var(--vh, 1vh) * 100 - 64px);
    right: 16px;
  }
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 2000;
  display: none;
  justify-content: center;
  padding: 40px;
}
.overlay--open {
  display: flex;
}
.overlay-inner {
  overflow: auto;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.overlay-content {
  background-color: #FFFFFF;
  padding: 20px;
  width: 100%;
  max-width: 550px;
  text-align: center;
}
.overlay-cart {
  max-width: 670px;
}

@media screen and (max-width: 600px) {
  .overlay {
    padding: 16px;
  }
  .overlay-content {
    padding: 16px;
  }
}
.material-filters {
  padding: 40px 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 0 0 auto;
  border-right: 1px solid #DFE1E5;
}
.material-filters-filter {
  margin-top: 40px;
}
.material-filters-filter:first-child {
  margin-top: 0;
}
@media screen and (max-width: 768px) {
  .material-filters {
    border-right: none;
    padding: 0;
    width: 100%;
  }
}

.fancy-input-label {
  font-size: 14px;
  line-height: 20px;
  color: #444952;
  margin-bottom: 4px;
}
.fancy-input-container {
  border: 1px solid #DFE1E5;
  display: flex;
  height: 48px;
  align-items: center;
  padding: 12px;
  flex: 1 1 auto;
  font-size: 16px;
  line-height: 24px;
}
.fancy-input-container label {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;
}
.fancy-input-container-text {
  color: #2B2F38;
  white-space: nowrap;
  flex: 0 0 auto;
  padding-right: 3px;
}
.fancy-input-container-input {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  flex: 1 1 auto;
  min-width: 0;
}
.fancy-input-container-unit {
  color: #BABDC2;
  white-space: nowrap;
  flex: 0 0 auto;
  padding-left: 3px;
}

.dimensions-filter {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.dimensions-filter-image {
  margin: 0 -8px 12px;
  align-self: center;
}
@media screen and (max-width: 768px) {
  .dimensions-filter-image {
    margin: 8px 0 20px;
  }
}
.dimensions-filter-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #84878F;
}
@media screen and (max-width: 768px) {
  .dimensions-filter-text {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #2B2F38;
  }
}
.dimensions-filter-item {
  margin-top: 12px;
}
.dimensions-filter-border {
  height: 1px;
  margin: 0 -16px 12px;
  background-color: #DFE1E5;
  display: none;
}
@media screen and (max-width: 768px) {
  .dimensions-filter-border {
    display: block;
  }
}
.dimensions-filter .reset-filter-button {
  float: right;
  margin-top: 15px;
}

.order-header {
  padding: 20px 40px;
  background-color: #F3F4F5;
}
.order-header-top, .order-header-bottom {
  display: flex;
  flex-direction: row;
}
.order-header-top {
  align-items: flex-start;
  justify-content: center;
}
.order-header-bottom {
  align-items: center;
}
.order-header-title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
}
.order-header-back-button {
  color: #D10931;
  white-space: nowrap;
  font-size: 16px;
  line-height: 24px;
  position: absolute;
  left: 0;
}
.order-header-back-button i {
  margin-right: 5px;
  font-size: 20px;
  vertical-align: text-bottom;
}
.order-header-back-button i::before {
  width: 16px;
  height: 12px;
}

@media screen and (max-width: 1024px) {
  .order-header {
    padding: 20px 32px;
  }
  .order-header-top {
    justify-content: space-between;
  }
  .order-header-back-button {
    position: unset;
    left: unset;
  }
}
@media screen and (max-width: 768px) {
  .order-header-top {
    flex-wrap: wrap;
  }
  .order-header-back-button {
    order: 1;
  }
  .order-header-bottom {
    padding-top: 12px;
  }
}
@media screen and (max-width: 600px) {
  .order-header-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
  .order-header {
    padding: 20px 16px;
  }
}
.timeline {
  max-width: 670px;
  width: 100%;
  display: flex;
}
.timeline-item {
  flex: 2 1 auto;
  display: flex;
}
.timeline-item-content-icon-line::before, .timeline-item-content-icon-line::after, .timeline-item-line {
  height: 2px;
  margin-top: 9px;
  background-color: #BABDC2;
}
.timeline-item-line {
  flex: 1 1 auto;
}
.timeline-item-content {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
}
.timeline-item-content-icon-line {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}
.timeline-item-content-icon-line::before, .timeline-item-content-icon-line::after {
  flex: 1 1 auto;
  content: "";
}
.timeline-item-content-icon-line-icon-container {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #BABDC2;
  display: flex;
  align-items: center;
  justify-content: center;
}
.timeline-item-content-icon-line-icon-container .icon-check {
  color: #BABDC2;
  font-size: 16px;
}
.timeline-item-content-text {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  padding-top: 6px;
}
.timeline-item.timeline-step-done .timeline-item-content-text {
  color: #D10931;
}
.timeline-item.timeline-step-done .timeline-item-content-icon-line-icon-container {
  border-color: #D10931;
}
.timeline-item.timeline-step-done .timeline-item-content-icon-line-icon-container .icon-check {
  color: #D10931;
}
.timeline-item.timeline-step-done .timeline-item-content-icon-line::before,
.timeline-item.timeline-step-done .timeline-item-content-icon-line::after,
.timeline-item.timeline-step-done .timeline-item-line {
  background-color: #D10931;
}
.timeline-item:first-child, .timeline-item:last-child {
  flex: 1 2 auto;
}
.timeline-item:first-child .timeline-item-line:first-child {
  display: none;
}
.timeline-item:first-child .timeline-item-content-icon-line::before {
  visibility: hidden;
}
.timeline-item:last-child .timeline-item-line:last-child {
  display: none;
}
.timeline-item:last-child .timeline-item-content-icon-line::after {
  visibility: hidden;
}

@media screen and (max-width: 1200px) {
  .timeline {
    margin-left: 70px;
  }
}
@media screen and (max-width: 768px) {
  .timeline {
    margin-left: 0;
    max-width: none;
    margin-bottom: 20px;
  }
}
.address-selection {
  background-color: #FFFFFF;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.address-selection-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 28px;
}
.address-selection-shipping-selection {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.address-selection-shipping-selection-select {
  max-width: 325px;
}
.address-selection-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  max-width: 1440px;
}
.address-selection-items.address-selection-items--mobile {
  display: none;
}
.address-selection-button-container {
  margin-top: 20px;
}

@media screen and (max-width: 1024px) {
  .address-selection-items {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .address-selection-items > * {
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 600px) {
  .address-selection {
    padding: 16px;
  }
  .address-selection-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .checkbox-list-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #2B2F38;
  }
  .address-selection-item--hide-on-mobile {
    display: none;
  }
  .address-selection-items {
    flex-direction: column;
    align-items: stretch;
  }
  .address-selection-items > * {
    width: auto;
  }
  .address-selection-items.address-selection-items--mobile {
    margin-top: 20px;
    display: flex;
  }
  .address-selection-button-container {
    margin-top: 16px;
    margin-bottom: 8px;
  }
}
.address-selection-item {
  padding: 20px;
  border: 1px solid #DFE1E5;
}
.address-selection-item-type {
  display: flex;
  justify-content: space-between;
}
.address-selection-item-type-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.address-selection-item-type .icon-pen {
  color: #D10931;
  font-size: 20px;
}
.address-selection-item-text {
  font-size: 16px;
  line-height: 24px;
  color: #444952;
  margin-top: 8px;
}

@media screen and (max-width: 600px) {
  .address-selection-item {
    padding: 16px;
  }
}
.order-summary {
  width: 325px;
  padding: 20px;
  background-color: #FFFFFF;
  position: -webkit-sticky;
  position: sticky;
  top: 185px;
}
.order-summary-title {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}
.order-summary-items {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}
.order-summary-item, .order-summary-sum {
  display: flex;
  justify-content: space-between;
}
.order-summary-item {
  font-size: 16px;
  line-height: 24px;
  color: #444952;
}
.order-summary-item:not(:last-child) {
  margin-bottom: 8px;
}
.order-summary-sum {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 20px;
}
.order-summary-privacy {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
}
.order-summary-privacy a {
  text-decoration: underline;
}
.order-summary-privacy-checkbox {
  margin-top: -8px;
}
.order-summary-lower {
  margin-top: 48px;
}
.order-summary button + button {
  margin-top: 10px;
}

@media screen and (max-width: 1024px) {
  .order-summary-lower--no-privacy {
    margin-top: 0;
  }
}
@media screen and (max-width: 600px) {
  .order-summary {
    padding: 16px;
  }
}
.shipping-options {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-bottom: -1px;
}
.shipping-options-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  padding: 20px 20px 0;
}
.shipping-options-container {
  padding: 0 20px 20px;
  border-bottom: 1px solid #DFE1E5;
}
.shipping-options-columns {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: -20px;
}
.shipping-options-column {
  flex: 1 1 auto;
  max-width: 325px;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}
.shipping-options-headline {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-top: 28px;
}
.shipping-options-comment, .shipping-options-label {
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .shipping-options-column {
    max-width: none;
  }
  .shipping-options-container {
    padding: 0 16px 16px;
  }
  .shipping-options-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    padding: 16px 16px 0;
  }
}
.cart {
  background-color: #FFFFFF;
}
.cart-headlines {
  display: flex;
  border-bottom: 1px solid #DFE1E5;
  padding: 8px 20px 4px;
}
.cart-headline {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #84878F;
  padding-right: 20px;
}
.cart-headline-product {
  width: 26.0606060606%;
  margin-left: 15.9090909091%;
}
.cart-headline-amount {
  width: 12.1212121212%;
}
.cart-headline-weight {
  width: 11.2121212121%;
}
.cart-headline-stock {
  width: 10.9090909091%;
}
.cart-headline-price {
  width: 9.0909090909%;
  text-align: right;
}
.cart-headline-summary {
  width: 9.8484848485%;
  text-align: right;
}
.cart-summary {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 60px 20px 20px;
  justify-content: space-between;
}
.cart-summary-comment {
  padding-left: 16%;
  width: calc(50% + 140px);
  padding-right: 20px;
}
.cart-summary-content {
  text-align: right;
  margin-right: -40px;
}
.cart-summary-price {
  margin-bottom: 40px;
}
.cart-summary-price-title {
  color: #444952;
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}
.cart-summary-price-extra {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-top: 5px;
}
.cart-summary-button {
  display: inline-block;
  width: auto;
  min-width: 190px;
}

@media screen and (max-width: 1200px) {
  .cart-headlines {
    display: none;
  }
  .cart-summary {
    padding: 20px;
  }
  .cart-summary-comment {
    padding: 0;
    width: 100%;
  }
  .cart-summary-comment .text-area textarea {
    min-height: 96px;
  }
  .cart-summary-content {
    width: 100%;
    text-align: right;
    margin-right: 0;
    margin-top: 20px;
  }
}
@media screen and (max-width: 600px) {
  .cart-summary {
    padding: 20px 16px 16px;
  }
  .cart-summary-button {
    width: 100%;
  }
}
.cart-selection {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.cart-selection-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-align: left;
}
.cart-selection-select {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
}
.cart-selection-select select {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
  font-size: 16px;
  line-height: 24px;
  font-family: "Akkurat Pro", sans-serif;
  padding-right: 30px;
  cursor: pointer;
}
.cart-selection-select::after {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 24px;
  content: "\e901";
  font-family: "iconfont-ehg";
  color: #2B2F38;
}

@media screen and (max-width: 1200px) {
  .cart-selection {
    border-bottom: 1px solid #DFE1E5;
  }
}
@media screen and (max-width: 600px) {
  .cart-selection {
    padding: 16px 16px 20px;
  }
  .cart-selection-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    width: 100%;
  }
  .cart-selection-select {
    margin-top: 12px;
  }
}
.cart-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  border-bottom: 1px solid #DFE1E5;
}
.cart-item-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.cart-item-image {
  width: 15.9090909091%;
  padding-right: 20px;
}
.cart-item-image img {
  width: 100%;
  padding: 20px;
  border: 1px solid #DFE1E5;
  max-width: 250px;
}
.cart-item-text {
  width: 26.0606060606%;
  padding-right: 20px;
}
.cart-item-text .cart-item-availability-icon {
  display: none;
}
.cart-item-text-title {
  color: #444952;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cart-item-text-description {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-top: 4px;
}
.cart-item-text-comment {
  font-size: 16px;
  line-height: 24px;
  color: #84878F;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cart-item-text-comment-container {
  display: flex;
  flex-direction: row;
  max-width: 256px;
  margin-top: 16px;
}
.cart-item-text-comment-edit {
  align-self: self-end;
  margin-left: 8px;
}
.cart-item-text-comment-button {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
  margin-top: 16px;
  text-align: left;
  color: #D10931;
}
.cart-item-amount {
  width: 12.1212121212%;
  padding-right: 20px;
}
.cart-item-amount .amount-counter {
  max-width: 120px;
}
.cart-item-weight {
  width: 11.2121212121%;
  padding-right: 20px;
}
.cart-item-weight-text {
  font-size: 16px;
  line-height: 24px;
  color: #2B2F38;
}
.cart-item-mobile-availability {
  display: none;
}
.cart-item-availability {
  width: 10.9090909091%;
  padding-right: 20px;
  color: #444952;
  padding-left: 28px;
  position: relative;
}
.cart-item-availability-icon {
  position: absolute;
  top: 5px;
  left: 5px;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.cart-item-availability-icon--available {
  background: #18B268;
}
.cart-item-availability-icon--unavailable {
  background: #D10931;
}
.cart-item-price {
  width: 9.0909090909%;
  padding-right: 20px;
  text-align: right;
}
.cart-item-price-extra {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-top: 4px;
}
.cart-item-sum {
  width: 9.8484848485%;
  padding-right: 20px;
  text-align: right;
}
.cart-item-icon-container {
  width: 4.8484848485%;
  height: 1px;
  position: relative;
}
.cart-item-delete {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 60px;
  height: 60px;
  text-align: center;
}
.cart-item-additions {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 15.9090909091%;
}
.cart-item-additions > * {
  margin-top: 12px;
}
.cart-item-additions > *:first-child {
  margin-top: 0;
}

@media screen and (max-width: 1200px) {
  .cart-item-image {
    display: none;
  }
  .cart-item-text {
    width: 100%;
    padding-right: 40px;
    margin-bottom: 20px;
  }
  .cart-item--transport .cart-item-text {
    width: 70%;
    margin-bottom: 0;
    padding-right: 0;
  }
  .cart-item-text .cart-item-availability-icon {
    display: inline-block;
  }
  .cart-item-text-title {
    display: inline;
    vertical-align: middle;
  }
  .cart-item-text-description {
    display: none;
  }
  .cart-item--transport .cart-item-text-description {
    display: block;
  }
  .cart-item-text-comment-container {
    margin-top: 8px;
  }
  .cart-item-text-comment-button {
    margin-top: 8px;
    display: block;
  }
  .cart-item-weight {
    display: none;
  }
  .cart-item-price {
    display: none;
  }
  .cart-item-sum {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #444952;
    width: 50%;
    text-align: right;
    padding-right: 0;
    margin-top: -20px;
  }
  .cart-item--transport .cart-item-sum {
    width: 30%;
    margin-top: initial;
  }
  .cart-item-delete {
    transform: none;
    top: 0;
    height: auto;
    width: auto;
  }
  .cart-item-amount {
    width: 50%;
    padding-right: 0;
  }
  .cart-item--transport .cart-item-amount {
    display: none;
  }
  .cart-item-mobile-availability {
    display: inline-block;
    position: absolute;
    margin-left: 8px;
  }
  .cart-item-mobile-availability .profile-offer-detail-table-item-column-in-stock {
    font-size: 0;
  }
  .cart-item-availability {
    display: none;
  }
  .cart-item-availability-icon {
    position: relative;
    top: auto;
    left: auto;
    margin-left: 5px;
    vertical-align: middle;
  }
  .cart-item-icon-container {
    width: 0;
    height: 0;
    position: static;
  }
  .cart-item-additions {
    margin-left: 0;
  }
  .cart-item-additions > *:first-child {
    margin-top: 24px;
  }
}
@media screen and (max-width: 600px) {
  .cart-item {
    padding: 20px 16px;
  }
  .cart-item-text-comment-container {
    max-width: 207px;
  }
}
.text-area textarea {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
  width: 100%;
  padding: 12px 16px;
  max-width: 100%;
  border: 1px solid #DFE1E5;
  font-family: "Akkurat Pro", sans-serif;
  color: #2B2F38;
}
.text-area textarea::placeholder, .text-area textarea:-ms-input-placeholder, .text-area textarea::-ms-input-placeholder, .text-area textarea::-webkit-input-placeholder, .text-area textarea::-moz-placeholder {
  font-family: "Akkurat Pro", sans-serif;
  color: #84878F;
  font-size: 16px;
  line-height: 24px;
}
.text-area-label {
  font-size: 14px;
  line-height: 20px;
  color: #444952;
  margin-bottom: 4px;
}

.order-overview {
  background-color: #FFFFFF;
}
.order-overview-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  text-align: left;
  padding: 20px;
}
.order-overview-headlines {
  display: flex;
  border-bottom: 1px solid #DFE1E5;
  padding: 8px 20px 4px;
}
.order-overview-headline {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  color: #84878F;
  padding-right: 20px;
  width: 12%;
}
.order-overview-headline:first-child {
  width: 36%;
}
.order-overview-headline:nth-child(2) {
  width: 15%;
}
.order-overview-headline:nth-child(4) {
  width: 15%;
}
.order-overview-headline:last-child {
  width: 10%;
  text-align: right;
  padding-right: 0;
}
.order-overview-lower {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
  justify-content: space-between;
}
.order-overview-lower-comment {
  width: calc(50% + 100px);
  padding-right: 20px;
  color: #84878F;
  font-size: 14px;
  line-height: 20px;
}
.order-overview-lower-content {
  text-align: right;
}
.order-overview-lower-price-title {
  font-size: 16px;
  line-height: 24px;
  color: #444952;
}
.order-overview-lower-price-extra {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-top: 5px;
}

@media screen and (max-width: 1024px) {
  .order-overview-title {
    border-bottom: 1px solid #DFE1E5;
  }
  .order-overview-headlines {
    display: none;
  }
  .order-overview-lower {
    padding: 20px;
  }
  .order-overview-lower-comment {
    padding: 0;
    width: 100%;
  }
  .order-overview-lower-comment .text-area textarea {
    min-height: 96px;
  }
  .order-overview-lower-price-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
  .order-overview-lower-content {
    width: 100%;
    text-align: right;
    margin-right: 0;
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .order-overview-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    padding: 16px 16px 20px;
  }
  .order-overview-lower {
    padding: 20px 16px 16px;
  }
}
.order-overview-item {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 20px;
  border-bottom: 1px solid #DFE1E5;
}
.order-overview-item-content {
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  align-items: center;
}
.order-overview-item-additions {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.order-overview-item-additions > * {
  margin-top: 12px;
}
.order-overview-item-additions > *:first-child {
  margin-top: 20px;
}
.order-overview-item-text {
  padding-right: 20px;
  width: 36%;
}
.order-overview-item-text .order-overview-item-availability-icon {
  display: none;
}
.order-overview-item-text-title {
  color: #444952;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.order-overview-item-text-description {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-top: 4px;
}
.order-overview-item-text-comment, .order-overview-item-text-comment-mobile {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: none;
  box-shadow: none;
  appearance: none;
  outline: none;
  margin-top: 16px;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
}
.order-overview-item-text-comment-mobile {
  display: none;
}
.order-overview-item-amount, .order-overview-item-weight {
  padding-right: 20px;
  width: 12%;
}
.order-overview-item-amount {
  width: 15%;
}
.order-overview-item-availability {
  padding-right: 20px;
  padding-left: 20px;
  width: 15%;
}
.order-overview-item-availability-icon {
  position: absolute;
  top: 5px;
  left: 0;
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.order-overview-item-availability-icon--available {
  background: #18B268;
}
.order-overview-item-availability-icon--unavailable {
  background: #D10931;
}
.order-overview-item-price {
  padding-right: 15px;
  width: 12%;
}
.order-overview-item-price-extra, .order-overview-item-amount-extra {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-top: 4px;
}
.order-overview-item-sum {
  padding-right: 0;
  width: 10%;
  text-align: right;
}

@media screen and (max-width: 1024px) {
  .order-overview-item-content {
    flex-wrap: wrap;
  }
  .order-overview-item-additions > *:first-child {
    margin-top: 24px;
  }
  .order-overview-item-image {
    display: none;
  }
  .order-overview-item-text {
    width: 100%;
    padding-right: 30%;
    margin-bottom: 4px;
  }
  .order-overview-item--transport .order-overview-item-text {
    width: 70%;
    margin-bottom: 0;
  }
  .order-overview-item-text .order-overview-item-availability-icon {
    display: inline-block;
  }
  .order-overview-item-text-title {
    display: inline;
    vertical-align: middle;
  }
  .order-overview-item-text-description {
    display: none;
  }
  .order-overview-item--transport .order-overview-item-text-description {
    display: block;
  }
  .order-overview-item-text-comment {
    display: none;
  }
  .order-overview-item-text-comment-mobile {
    margin-top: 16px;
    display: block;
  }
  .order-overview-item-price, .order-overview-item-weight, .order-overview-item-availability {
    display: none;
  }
  .order-overview-item-sum {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #444952;
    width: 50%;
    text-align: right;
    padding-right: 0;
    margin-top: -30px;
  }
  .order-overview-item--transport .order-overview-item-sum {
    width: 30%;
    margin-top: initial;
  }
  .order-overview-item-amount {
    width: 50%;
    padding-right: 0;
    color: #84878F;
  }
  .order-overview-item--transport .order-overview-item-amount {
    display: none;
  }
  .order-overview-item-amount-extra {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  .order-overview-item {
    padding: 20px 16px;
  }
}
.shipping-selection {
  background-color: #FFFFFF;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.shipping-selection-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  margin-bottom: 8px;
}
.shipping-selection-items {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -20px;
}
.shipping-selection-items > * {
  max-width: 325px;
  flex: 1 1 auto;
  margin-right: 20px;
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .shipping-selection {
    padding: 16px;
  }
  .shipping-selection-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .shipping-selection-items {
    margin-right: -16px;
  }
  .shipping-selection-items > * {
    max-width: none;
    margin-right: 16px;
    margin-top: 16px;
  }
}
.shipping-selection-item {
  padding: 20px;
  border: 1px solid #DFE1E5;
}
.shipping-selection-item-type {
  display: flex;
  justify-content: space-between;
}
.shipping-selection-item-type-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.shipping-selection-item-type .icon-pen {
  color: #D10931;
  font-size: 20px;
}
.shipping-selection-item-text {
  font-size: 16px;
  line-height: 24px;
  color: #444952;
  margin-top: 8px;
}

.thank-you-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 94px;
}
.thank-you-background {
  background-color: #FFFFFF;
  padding-top: 80px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.thank-you-content {
  max-width: 670px;
  text-align: center;
  margin-left: 24px;
  margin-right: 24px;
}
.thank-you-content-title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: #2B2F38;
  margin-bottom: 20px;
}
.thank-you-content-text {
  font-size: 20px;
  line-height: 28px;
  color: #444952;
}
.thank-you-content-text-email {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
}
.thank-you-button {
  margin-top: 40px;
  width: 180px;
}
.thank-you-animation {
  margin-top: 50px;
  margin-bottom: 23px;
  height: 154px;
  width: 880px;
  position: relative;
}
.thank-you-animation-container {
  background-image: url("/assets/images/icons/trees.svg");
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-repeat: repeat;
  background-position: 0 0;
  background-size: auto 100%;
  /* adjust s value for speed */
  animation: animatedBackground 75s linear infinite;
}
.thank-you-animation-container-shapes {
  width: 265px;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 19px;
  left: 16%;
}
.thank-you-animation-container-truck {
  height: 165px;
}
.thank-you-animation-container-wind {
  opacity: 0.5;
  margin-right: 10px;
  height: 15px;
  animation: wind 1.5s 0s ease infinite;
}

@media screen and (max-width: 1200px) {
  .thank-you-header .timeline {
    margin-left: 0;
  }
}
@media screen and (max-width: 1024px) {
  .thank-you .timeline {
    margin-bottom: 0;
  }
  .thank-you-header {
    height: 90px;
  }
  .thank-you-animation {
    width: 100%;
  }
  .thank-you-animation-container-shapes {
    left: 10%;
  }
}
@media screen and (max-width: 600px) {
  .thank-you-background {
    padding-top: 48px;
  }
  .thank-you-content {
    max-width: 100%;
  }
  .thank-you-content-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
  .thank-you-content-text {
    font-size: 16px;
    line-height: 24px;
  }
  .thank-you-content-text-email {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
  .thank-you-button {
    margin-top: 28px;
  }
}
@media screen and (max-width: 480px) {
  .thank-you-animation-container-shapes {
    width: 200px;
    top: 44px;
  }
  .thank-you-animation-container-truck {
    height: 140px;
  }
  .thank-you-animation-container-wind {
    height: 10px;
  }
}
@keyframes animatedBackground {
  from {
    background-position: 0 0;
  }
  to {
    background-position: -10000px 0;
  }
}
@keyframes wind {
  50% {
    transform: translateY(3px);
  }
}
.datepicker {
  margin-bottom: 18px;
}
.datepicker-label {
  font-size: 14px;
  line-height: 20px;
  color: #444952;
  margin-bottom: 4px;
}
.datepicker i {
  color: #D10931;
  display: block;
}

.overlay-add-to-cart {
  padding: 8px;
}
.overlay-add-to-cart-icon {
  margin-bottom: 40px;
}
.overlay-add-to-cart-icon img {
  margin: 0 auto;
}
@media screen and (max-width: 600px) {
  .overlay-add-to-cart-icon {
    margin-top: 24px;
  }
}
.overlay-add-to-cart-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 8px;
}
.overlay-add-to-cart-description {
  font-size: 16px;
  line-height: 24px;
  color: #84878F;
}
.overlay-add-to-cart-buttons {
  margin: 30px 0 -6px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.overlay-add-to-cart-buttons button,
.overlay-add-to-cart-buttons a {
  margin: 6px;
  width: auto;
}

@media screen and (max-width: 600px) {
  .overlay-add-to-cart {
    padding: 0;
  }
  .overlay-add-to-cart-icon {
    margin-top: 24px;
  }
}
.overlay-cart-item-comment-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.overlay-cart-item-comment-top-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2B2F38;
}
.overlay-cart-item-comment-top-icon {
  color: #D10931;
  margin-top: 3px;
}
.overlay-cart-item-comment-top-icon i {
  font-size: 24px;
}
.overlay-cart-item-comment-text-area {
  margin-top: 28px;
}
.overlay-cart-item-comment-buttons {
  margin-top: 18px;
  display: flex;
  justify-content: flex-end;
}
.overlay-cart-item-comment-buttons button,
.overlay-cart-item-comment-buttons a {
  width: 120px;
}
.overlay-cart-item-comment-buttons button:last-child,
.overlay-cart-item-comment-buttons a:last-child {
  margin-left: 12px;
}

@media screen and (max-width: 480px) {
  .overlay-cart-item-comment-buttons {
    justify-content: center;
  }
  .overlay-cart-item-comment-buttons button,
.overlay-cart-item-comment-buttons a {
    width: 148px;
  }
}
.simple-header {
  background-color: #F3F4F5;
  padding: 20px 40px;
}
.simple-header-top {
  display: flex;
  flex-direction: row;
  height: 24px;
  align-self: flex-start;
  align-items: center;
}
.simple-header-back {
  font-size: 16px;
  line-height: 24px;
  color: #D10931;
  align-self: self-end;
}
.simple-header-arrow {
  margin-right: 5px;
  font-size: 20px;
  vertical-align: text-bottom;
}
.simple-header-arrow::before {
  width: 16px;
  height: 12px;
}
.simple-header-bottom {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
}
.simple-header-title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  margin-right: 16px;
}

@media screen and (max-width: 1024px) {
  .simple-header {
    padding: 16px 32px;
  }
}
@media screen and (max-width: 768px) {
  .simple-header-title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
    margin-right: 20px;
  }
  .simple-header-bottom {
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 12px;
  }
}
@media screen and (max-width: 600px) {
  .simple-header {
    padding: 16px;
  }
}
.profile-navigation {
  max-width: 325px;
  width: 100%;
  margin-right: 20px;
}
.profile-navigation-inner {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: auto;
}
.profile-navigation-logout {
  margin-top: 1px;
  padding: 20px;
  background-color: #FFFFFF;
  color: #444952;
}
.profile-navigation-logout i {
  font-size: 24px;
  margin-top: -3px;
}
.profile-navigation-logout span {
  font-size: 18px;
  line-height: 24px;
}

@media screen and (max-width: 1024px) {
  .profile-navigation {
    max-width: unset;
    width: calc(100% + 64px);
    background-color: unset;
    margin-left: -32px;
    margin-right: 0;
    margin-bottom: 20px;
  }
  .profile-navigation-inner {
    flex-direction: row;
    overflow: scroll;
  }
  .profile-navigation-logout {
    border-bottom: 0.5px solid #BABDC2;
    padding: 20px 32px 16px;
    background-color: #F3F4F5;
    color: #84878F;
    white-space: nowrap;
  }
  .profile-navigation-logout span {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
}
@media screen and (max-width: 600px) {
  .profile-navigation {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
}
.profile-navigation-item {
  padding: 12px 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  background-color: #FFFFFF;
}
.profile-navigation-item:hover .profile-navigation-item-title {
  color: #D10931;
  transition: color 0.5s ease;
}
.profile-navigation-item-active-indicator {
  width: 4px;
  height: 24px;
  background-color: #D10931;
  border-radius: 0 4px 4px 0;
  position: absolute;
  left: 0;
  top: 12px;
}
.profile-navigation-item-title {
  font-size: 18px;
  line-height: 24px;
  color: #444952;
}
.profile-navigation-item-title--active {
  color: #D10931;
}

@media screen and (max-width: 1024px) {
  .profile-navigation-item {
    border-bottom: 0.5px solid #BABDC2;
    padding: 20px 32px 16px;
    background-color: #F3F4F5;
  }
  .profile-navigation-item-active-indicator {
    width: 100%;
    height: 3px;
    top: auto;
    bottom: -1px;
  }
  .profile-navigation-item-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
    color: #84878F;
    white-space: nowrap;
  }
  .profile-navigation-item-title--active {
    color: #D10931;
  }
}
@media screen and (max-width: 600px) {
  .profile-navigation-item {
    padding: 20px 16px 16px;
  }
}
.profile-user-customer {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px 20px 24px;
  position: relative;
}
.profile-user-customer-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #2B2F38;
  margin-bottom: 28px;
}
.profile-user-customer-content {
  display: flex;
}
.profile-user-customer-content-left, .profile-user-customer-content-right {
  max-width: 305px;
  width: 100%;
}
.profile-user-customer-content-left {
  margin-right: 20px;
}

@media screen and (max-width: 600px) {
  .profile-user-customer-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .profile-user-customer-content {
    flex-direction: column;
  }
  .profile-user-customer-content-left {
    margin-right: 0;
  }
  .profile-user-customer-content-right {
    margin-top: 20px;
  }
}
.profile-info-text {
  display: flex;
  flex-direction: column;
}
.profile-info-text:not(:first-child) {
  margin-top: 20px;
}
.profile-info-text-headline {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
}
.profile-info-text-password {
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #2B2F38;
}
.profile-info-text-value {
  font-size: 16px;
  line-height: 24px;
  color: #2B2F38;
  margin-top: 4px;
}

.profile-user-user {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px 20px 24px;
  position: relative;
  margin-bottom: 20px;
}
.profile-user-user-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #2B2F38;
  margin-bottom: 28px;
}
.profile-user-user-edit {
  position: absolute;
  top: 20px;
  right: 20px;
  display: flex;
  flex-direction: row;
  color: #D10931;
}
.profile-user-user-edit-text {
  font-size: 16px;
  line-height: 24px;
}
.profile-user-user-edit-icon {
  font-size: 24px;
  margin-left: 4px;
}
.profile-user-user-content {
  display: flex;
}
.profile-user-user-content-left, .profile-user-user-content-right {
  max-width: 305px;
  width: 100%;
}
.profile-user-user-content-left {
  margin-right: 20px;
}
.profile-user-user-content-edit {
  margin-top: 20px;
}
.profile-user-user-content-edit:first-child {
  margin-top: 0;
}
.profile-user-user-content-edit-label {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-bottom: 8px;
}
.profile-user-user-content-edit-options-label {
  color: #84878F;
}
.profile-user-user-content-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}
.profile-user-user-content-buttons button,
.profile-user-user-content-buttons a {
  width: 140px;
}
.profile-user-user-content-buttons button:last-child,
.profile-user-user-content-buttons a:last-child {
  margin-left: 12px;
}

@media screen and (max-width: 600px) {
  .profile-user-user-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .profile-user-user-edit-text {
    display: none;
  }
  .profile-user-user-content {
    flex-direction: column;
  }
  .profile-user-user-content-left, .profile-user-user-content-right {
    max-width: unset;
  }
  .profile-user-user-content-left {
    margin-right: 0;
  }
  .profile-user-user-content-right {
    margin-top: 20px;
  }
  .profile-user-user-content-buttons {
    justify-content: initial;
  }
  .profile-user-user-content-buttons button,
.profile-user-user-content-buttons a {
    width: 100%;
  }
}
.profile-user-contact {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px 20px 24px;
  position: relative;
  margin-bottom: 20px;
}
.profile-user-contact-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #2B2F38;
  margin-bottom: 28px;
}
.profile-user-contact-salutation {
  margin-bottom: 20px;
}
.profile-user-contact-content {
  display: flex;
}
.profile-user-contact-content-left, .profile-user-contact-content-right {
  max-width: 305px;
  width: 100%;
}
.profile-user-contact-content-left {
  margin-right: 20px;
}

@media screen and (max-width: 600px) {
  .profile-user-contact-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .profile-user-contact-content {
    flex-direction: column;
  }
  .profile-user-contact-content-left {
    margin-right: 0;
  }
  .profile-user-contact-content-right {
    margin-top: 20px;
  }
}
.profile-offer-overview-filter-offers {
  margin-top: 12px;
}
.profile-offer-overview-filter-search {
  background-color: #FFFFFF;
  padding: 20px;
}
.profile-offer-overview-filter-search-headline {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #2B2F38;
}
.profile-offer-overview-filter-search-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  color: #D10931;
  display: none;
}
.profile-offer-overview-filter-search--open .profile-offer-overview-filter-search-icon {
  transform: rotate(180deg);
}
.profile-offer-overview-filter-search--open .profile-offer-overview-filter-search-content {
  margin-top: 28px;
}
.profile-offer-overview-filter-search-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.profile-offer-overview-filter-search-filters-option {
  width: calc(33.3% - 10px);
  margin-bottom: 20px;
}
.profile-offer-overview-filter-search-filters-option .datepicker {
  margin-bottom: 0;
}
.profile-offer-overview-filter-search-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.profile-offer-overview-filter-search-bottom-legend {
  display: flex;
}
.profile-offer-overview-filter-search-bottom-legend-state {
  display: flex;
  position: relative;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  color: #444952;
}
.profile-offer-overview-filter-search-bottom-legend-state-icon {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}
.profile-offer-overview-filter-search-bottom-legend-state-icon--accepted {
  background-color: #18B268;
}
.profile-offer-overview-filter-search-bottom-legend-state-icon--open {
  background-color: #FFCC00;
  margin-left: 18px;
}
.profile-offer-overview-filter-search-bottom-legend-state-icon--denied {
  background-color: #E31717;
  margin-left: 18px;
}
.profile-offer-overview-filter-search-bottom-button {
  width: 140px;
}

@media screen and (max-width: 768px) {
  .profile-offer-overview-filter-search-filters-option {
    width: calc(50% - 10px);
  }
}
@media screen and (max-width: 600px) {
  .profile-offer-overview-filter-search {
    padding: 16px;
  }
  .profile-offer-overview-filter-search-headline {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .profile-offer-overview-filter-search-icon {
    display: block;
  }
  .profile-offer-overview-filter-search-content {
    display: none;
  }
  .profile-offer-overview-filter-search--open .profile-offer-overview-filter-search-content {
    display: block;
  }
  .profile-offer-overview-filter-search-filters {
    flex-direction: column;
  }
  .profile-offer-overview-filter-search-filters-option {
    width: 100%;
  }
  .profile-offer-overview-filter-search-bottom {
    flex-direction: column;
  }
  .profile-offer-overview-filter-search-bottom-legend {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 28px;
  }
  .profile-offer-overview-filter-search-bottom-legend-state-icon--open, .profile-offer-overview-filter-search-bottom-legend-state-icon--denied {
    margin-left: 0;
  }
  .profile-offer-overview-filter-search-bottom-button {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .profile-offer-overview-filter-search-bottom {
    align-items: initial;
  }
  .profile-offer-overview-filter-search-bottom-legend {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
  .profile-offer-overview-filter-search-bottom-legend-state {
    width: 50%;
  }
  .profile-offer-overview-filter-search-bottom-legend-state:nth-child(2) {
    width: calc(50% - 28px);
    margin-left: 28px;
  }
  .profile-offer-overview-filter-search-bottom-legend-state:last-child {
    margin-top: 12px;
  }
}
.profile-offer-overview-item {
  min-height: 156px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #FFFFFF;
  padding: 0 20px 20px;
  cursor: pointer;
}
.profile-offer-overview-item + .profile-offer-overview-item {
  margin-top: 12px;
}
.profile-offer-overview-item-info {
  width: calc(33.3% - 10px);
  margin-top: 20px;
}
.profile-offer-overview-item-info-title {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-bottom: 4px;
}
.profile-offer-overview-item-info-value {
  font-size: 16px;
  line-height: 24px;
  color: #2B2F38;
}
.profile-offer-overview-item-availability {
  position: absolute;
  top: 22px;
  right: 22px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.profile-offer-overview-item-availability--accepted {
  background-color: #18B268;
}
.profile-offer-overview-item-availability--open {
  background-color: #FFCC00;
}
.profile-offer-overview-item-availability--denied {
  background-color: #E31717;
}
.profile-offer-overview-item-details {
  position: absolute;
  bottom: 20px;
  right: 20px;
  opacity: 0;
}
.profile-offer-overview-item--detail {
  cursor: auto;
}
.profile-offer-overview-item:hover:not(.profile-offer-overview-item--detail) .profile-offer-overview-item-details {
  opacity: 1;
  transition: opacity 0.4s ease;
}

@media screen and (max-width: 600px) {
  .profile-offer-overview-item {
    flex-direction: column;
    justify-content: initial;
    padding: 0 16px 20px;
  }
  .profile-offer-overview-item-info {
    width: 100%;
  }
}
.profile-user-management-form {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}
.profile-user-management-form-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2B2F38;
  margin-bottom: 28px;
}
.profile-user-management-form-content {
  display: flex;
  flex-direction: column;
}
.profile-user-management-form-content-row {
  display: flex;
  margin-top: 20px;
}
.profile-user-management-form-content-field {
  max-width: 305px;
  width: 100%;
}
.profile-user-management-form-content-field-label {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-bottom: 8px;
}
.profile-user-management-form-content-field + .profile-user-management-form-content-field {
  margin-left: 20px;
}
.profile-user-management-form-content-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}
.profile-user-management-form-content-buttons button,
.profile-user-management-form-content-buttons a {
  width: 120px;
}
.profile-user-management-form-content-buttons button:last-child,
.profile-user-management-form-content-buttons a:last-child {
  margin-left: 12px;
}

@media screen and (max-width: 600px) {
  .profile-user-management-form {
    padding: 16px 16px 20px;
  }
  .profile-user-management-form-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .profile-user-management-form-content-row {
    flex-direction: column;
    margin-top: 0;
  }
  .profile-user-management-form-content-field {
    max-width: none;
    margin-top: 20px;
  }
  .profile-user-management-form-content-field + .profile-user-management-form-content-field {
    margin-left: 0;
  }
  .profile-user-management-form-content-field--empty {
    margin-top: 0;
  }
  .profile-user-management-form-content-buttons {
    justify-content: initial;
  }
  .profile-user-management-form-content-buttons button,
.profile-user-management-form-content-buttons a {
    width: 100%;
  }
}
.profile-carts {
  width: 100%;
  position: relative;
}
.profile-carts-add-cart {
  display: block;
  width: 100%;
  padding: 24px 20px;
  background-color: #FFFFFF;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .profile-carts-add-cart {
    padding: 20px 16px;
  }
}
.profile-carts-item {
  width: 100%;
  position: relative;
  padding: 20px 20px 24px;
  background-color: #FFFFFF;
  margin-bottom: 20px;
}
.profile-carts-item-title-container {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 8px;
}
.profile-carts-item-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #2B2F38;
  margin-top: 4px;
}
.profile-carts-item-icon-container {
  position: absolute;
  top: 20px;
  right: 20px;
}
.profile-carts-item-icon {
  margin-right: 12px;
}
.profile-carts-item-icon:last-child {
  margin-right: 0;
}
.profile-carts-item-info-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.profile-carts-item-info {
  width: calc(100% - 305px);
  margin-top: 20px;
}
.profile-carts-item-info:nth-child(odd) {
  max-width: 305px;
  width: 100%;
}
.profile-carts-item-info-title {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-bottom: 4px;
}
.profile-carts-item-info-value {
  font-size: 16px;
  line-height: 24px;
  color: #2B2F38;
}

@media screen and (max-width: 600px) {
  .profile-carts-item {
    padding: 18px 16px 72px;
  }
  .profile-carts-item-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .profile-carts-item-icon-container {
    top: unset;
    bottom: 20px;
    right: 20px;
  }
  .profile-carts-item-info-container {
    flex-direction: column;
    flex-wrap: initial;
  }
  .profile-carts-item-info {
    width: 100%;
  }
  .profile-carts-item-info:nth-child(odd) {
    width: 100%;
  }
}
.profile-cart-form {
  background-color: #FFFFFF;
  padding: 20px;
}
.profile-cart-form-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2B2F38;
}
.profile-cart-form-content {
  margin-top: 28px;
}
.profile-cart-form-content-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.profile-cart-form-content-top-info {
  width: calc(33.3% - 10px);
}
.profile-cart-form-content-comment {
  margin-top: 20px;
}
.profile-cart-form-content-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 16px;
}
.profile-cart-form-content-buttons a {
  width: 120px;
}
.profile-cart-form-content-buttons a:last-child {
  margin-left: 12px;
}

@media screen and (max-width: 600px) {
  .profile-cart-form {
    padding: 16px;
  }
  .profile-cart-form-title {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }
  .profile-cart-form-content-top {
    justify-content: initial;
    flex-wrap: wrap;
  }
  .profile-cart-form-content-top-info {
    width: 100%;
    margin-top: 20px;
  }
  .profile-cart-form-content-top-info:first-child {
    margin-top: 0;
  }
  .profile-cart-form-content-buttons a {
    width: calc(50% - 6px);
  }
}
.profile-user-management {
  width: 100%;
  position: relative;
}
.profile-user-management-add-user {
  display: block;
  width: 100%;
  padding: 24px 20px;
  background-color: #FFFFFF;
  margin-bottom: 12px;
}
.profile-user-management-description {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}
.profile-user-management-description-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #2B2F38;
  margin-bottom: 8px;
}
.profile-user-management-description-text {
  font-size: 16px;
  line-height: 24px;
  max-width: 765px;
  color: #84878F;
}

@media screen and (max-width: 600px) {
  .profile-user-management-add-user {
    padding: 20px 16px;
    margin-bottom: 20px;
  }
  .profile-user-management-description {
    padding: 16px;
  }
}
.profile-user-management-item {
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: #FFFFFF;
  margin-bottom: 12px;
}
.profile-user-management-item-icon-container {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}
.profile-user-management-item-icon {
  margin-right: 12px;
}
.profile-user-management-item-icon:last-child {
  margin-right: 0;
}
.profile-user-management-item-info-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.profile-user-management-item-info-left {
  max-width: 305px;
  width: 100%;
}
.profile-user-management-item-info-right {
  width: calc(100% - 305px);
}
.profile-user-management-item-info-title {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-bottom: 4px;
}
.profile-user-management-item-info-value {
  font-size: 16px;
  line-height: 24px;
  color: #2B2F38;
}
.profile-user-management-item-info-value + .profile-user-management-item-info-value {
  margin-top: 4px;
}
.profile-user-management-item-info-value + .profile-user-management-item-info-title {
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .profile-user-management-item {
    padding: 16px 16px 24px;
    margin-bottom: 20px;
  }
  .profile-user-management-item-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .profile-user-management-item-info-container {
    flex-direction: column;
    flex-wrap: initial;
  }
  .profile-user-management-item-info-left, .profile-user-management-item-info-right {
    width: 100%;
  }
  .profile-user-management-item-info-right {
    margin-top: 40px;
  }
}
.profile-offer-detail-table {
  background-color: #FFFFFF;
  flex: 1 1 auto;
  margin-top: 20px;
}
.profile-offer-detail-table-inner {
  width: 100%;
}
.profile-offer-detail-table-headline {
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #DFE1E5;
  color: #84878F;
  text-align: left;
}
.profile-offer-detail-table-headline-title {
  padding: 40px 0 4px;
}
.profile-offer-detail-table th {
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
  padding-left: 20px;
}
.profile-offer-detail-table th:last-child {
  padding-right: 20px;
  text-align: right;
}
.profile-offer-detail-table table {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .profile-offer-detail-table {
    width: calc(100% + 64px);
    margin-left: -32px;
    background-color: transparent;
    padding-top: 0;
  }
  .profile-offer-detail-table-inner {
    padding: 0 32px;
    overflow: auto;
  }
  .profile-offer-detail-table table {
    background-color: #FFFFFF;
    min-width: 900px;
  }
}
@media screen and (max-width: 600px) {
  .profile-offer-detail-table {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
  .profile-offer-detail-table th {
    padding-left: 16px;
  }
  .profile-offer-detail-table th:first-child {
    width: 204px;
  }
  .profile-offer-detail-table th:last-child {
    padding-right: 16px;
  }
  .profile-offer-detail-table-inner {
    padding: 0 16px;
  }
}
.profile-offer-detail-table-item {
  position: relative;
  border-bottom: 1px solid #DFE1E5;
}
.profile-offer-detail-table-item > td {
  padding: 12px 0 12px 20px;
  vertical-align: middle;
}
.profile-offer-detail-table-item > td:last-child {
  padding-right: 20px;
  text-align: right;
}
.profile-offer-detail-table-item-column {
  color: #444952;
}
.profile-offer-detail-table-item-column-product {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 4px;
}
.profile-offer-detail-table-item-column-legend {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
}
.profile-offer-detail-table-item-column-price {
  white-space: nowrap;
  color: #2B2F38;
}
.profile-offer-detail-table-item-column-quantity, .profile-offer-detail-table-item-column-price {
  margin-bottom: 2px;
}
.profile-offer-detail-table-item-column-in-stock {
  position: relative;
  padding-left: 22px;
}
.profile-offer-detail-table-item-column-in-stock::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.profile-offer-detail-table-item-column-in-stock--yes::after {
  background-color: #18B268;
}
.profile-offer-detail-table-item-column-in-stock--no::after {
  background-color: #E31717;
}

@media screen and (max-width: 600px) {
  .profile-offer-detail-table-item > td {
    padding: 12px 0 12px 16px;
  }
  .profile-offer-detail-table-item > td:last-child {
    padding-right: 16px;
  }
}
.profile-factory-certificates {
  width: 100%;
  position: relative;
}

.profile-factory-certificates-filter {
  background-color: #FFFFFF;
  padding: 20px;
  margin-bottom: 20px;
}
.profile-factory-certificates-filter--open .profile-factory-certificates-filter-icon {
  transform: rotate(180deg);
}
.profile-factory-certificates-filter--open .profile-factory-certificates-filter-content {
  margin-top: 28px;
}
.profile-factory-certificates-filter-content {
  margin-top: 28px;
}
.profile-factory-certificates-filter-headline {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #2B2F38;
}
.profile-factory-certificates-filter-icon {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 24px;
  color: #D10931;
  display: none;
}
.profile-factory-certificates-filter-filters {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  margin-left: -10px;
}
.profile-factory-certificates-filter-filters-option {
  width: calc(25% - 20px);
  margin: 0 10px 20px;
}
.profile-factory-certificates-filter-filters-option .datepicker {
  margin-bottom: 0;
}
.profile-factory-certificates-filter-bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.profile-factory-certificates-filter-bottom-button {
  width: 140px;
}

@media screen and (max-width: 768px) {
  .profile-factory-certificates-filter-filters-option {
    width: calc(50% - 20px);
  }
}
@media screen and (max-width: 600px) {
  .profile-factory-certificates-filter {
    padding: 16px 16px 20px;
  }
  .profile-factory-certificates-filter-headline {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .profile-factory-certificates-filter-content {
    display: none;
  }
  .profile-factory-certificates-filter--open .profile-factory-certificates-filter-content {
    display: block;
  }
  .profile-factory-certificates-filter-icon {
    display: block;
  }
  .profile-factory-certificates-filter-filters {
    width: 100%;
    margin-left: 0;
    flex-direction: column;
  }
  .profile-factory-certificates-filter-filters-option {
    width: 100%;
    margin: 0 0 20px;
  }
  .profile-factory-certificates-filter-bottom {
    flex-direction: column;
  }
  .profile-factory-certificates-filter-bottom-button {
    width: 100%;
  }
}
@media screen and (max-width: 480px) {
  .profile-factory-certificates-filter-bottom {
    align-items: initial;
  }
}
.profile-factory-certificates-list {
  background-color: #FFFFFF;
  flex: 1 1 auto;
}
.profile-factory-certificates-list-table {
  width: 100%;
}
.profile-factory-certificates-list-table-headline {
  font-size: 14px;
  line-height: 20px;
  border-bottom: 1px solid #DFE1E5;
  color: #84878F;
  text-align: left;
}
.profile-factory-certificates-list-table-headline-title {
  padding: 40px 0 4px;
}
.profile-factory-certificates-list th {
  white-space: nowrap;
  padding-right: 12px;
}
.profile-factory-certificates-list th:first-child {
  padding-left: 54px;
}
.profile-factory-certificates-list table {
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .profile-factory-certificates-list {
    width: calc(100% + 64px);
    margin-left: -32px;
    background-color: transparent;
    padding-top: 0;
  }
  .profile-factory-certificates-list-table {
    padding: 0 32px;
    overflow: auto;
  }
  .profile-factory-certificates-list table {
    background-color: #FFFFFF;
    min-width: 900px;
  }
}
@media screen and (max-width: 600px) {
  .profile-factory-certificates-list {
    width: calc(100% + 32px);
    margin-left: -16px;
  }
  .profile-factory-certificates-list-table {
    padding: 0 16px;
  }
}
.profile-factory-certificates-list-item {
  position: relative;
  border-bottom: 1px solid #DFE1E5;
}
.profile-factory-certificates-list-item > td {
  padding: 12px 12px 12px 0;
  vertical-align: middle;
}
.profile-factory-certificates-list-item-center {
  display: flex;
  align-items: center;
}
.profile-factory-certificates-list-item-title {
  color: #444952;
}
.profile-factory-certificates-list-item-title-text {
  font-size: 16px;
  line-height: 24px;
  color: #444952;
}
.profile-factory-certificates-list-item-title-arrow {
  font-size: 22px;
  color: #D10931;
  margin-bottom: 2px;
  margin-right: 14px;
  margin-left: 20px;
  transition: transform 0.4s ease;
}
.profile-factory-certificates-list-item--open .profile-factory-certificates-list-item-title-arrow {
  transform: translateY(2px) rotate(90deg);
}
.profile-factory-certificates-list-item:hover {
  background-color: #F3F4F5;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.profile-factory-certificates-list-item:hover .profile-factory-certificates-list-item-title,
.profile-factory-certificates-list-item:hover .profile-factory-certificates-list-item-title-text {
  color: #D10931;
  transition: color 0.5s ease;
}

.profile-factory-certificates-list-item-sub {
  position: relative;
  border-bottom: 1px solid #DFE1E5;
  cursor: pointer;
}
.profile-factory-certificates-list-item-sub:not(.profile-factory-certificates-list-item-sub--open) {
  display: none;
}
.profile-factory-certificates-list-item-sub > td {
  padding: 12px 0;
  vertical-align: middle;
}
.profile-factory-certificates-list-item-sub > td:first-child {
  padding-left: 54px;
}
.profile-factory-certificates-list-item-sub-center {
  display: flex;
  align-items: center;
}
.profile-factory-certificates-list-item-sub-file {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
}
.profile-factory-certificates-list-item-sub-file > span {
  position: relative;
}
.profile-factory-certificates-list-item-sub-file > span + span {
  padding-left: 10px;
  margin-left: 4px;
}
.profile-factory-certificates-list-item-sub-file > span + span::after {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-radius: 50%;
  background-color: #84878F;
}
.profile-factory-certificates-list-item-sub-title-text {
  font-size: 16px;
  line-height: 24px;
  color: #444952;
  margin-right: 12px;
}
.profile-factory-certificates-list-item-sub-title-icon {
  font-size: 20px;
  color: #D10931;
  margin-bottom: 1px;
  margin-right: 6px;
}
.profile-factory-certificates-list-item-sub-title-icon.icon-download {
  display: none;
}
.profile-factory-certificates-list-item-sub--open .profile-factory-certificates-list-item-sub-active {
  display: block;
}
.profile-factory-certificates-list-item-sub:hover {
  background-color: #F3F4F5;
  transition: background-color 0.5s ease;
  cursor: pointer;
}
.profile-factory-certificates-list-item-sub:hover .profile-factory-certificates-list-item-sub-title-icon,
.profile-factory-certificates-list-item-sub:hover .profile-factory-certificates-list-item-sub-title-text,
.profile-factory-certificates-list-item-sub:hover .profile-factory-certificates-list-item-sub-file {
  color: #D10931;
  transition: color 0.5s ease;
}
.profile-factory-certificates-list-item-sub:hover .profile-factory-certificates-list-item-sub-file span::after {
  background-color: #D10931;
  transition: background-color 0.5s ease;
}

@media screen and (max-width: 1024px) {
  .profile-factory-certificates-list-item-sub-title-icon.icon-download {
    display: block;
  }
}
.address-form {
  background-color: #FFFFFF;
  padding: 20px;
}
.address-form-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #2B2F38;
}
.address-form-input {
  max-width: 305px;
  width: 100%;
  margin-top: 20px;
}
.address-form-input:first-child {
  margin-top: 28px;
}
.address-form-input-small {
  max-width: 190px;
}
.address-form-input-big {
  max-width: 420px;
}
.address-form-group {
  display: flex;
  flex-direction: row;
}
.address-form-group .address-form-input {
  margin-left: 20px;
}
.address-form-group .address-form-input:first-child {
  margin-left: 0;
  margin-top: 20px;
}
.address-form-content-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}
.address-form-content-buttons a {
  width: 140px;
}
.address-form-content-buttons a:last-child {
  margin-left: 12px;
}

@media screen and (max-width: 600px) {
  .address-form {
    padding: 16px;
  }
  .address-form-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .address-form-input {
    max-width: 100%;
  }
  .address-form-input-small, .address-form-input-big {
    max-width: 100%;
  }
  .address-form-group {
    flex-direction: column;
  }
  .address-form-group .address-form-input {
    margin-left: 0;
  }
  .address-form-content-buttons a {
    width: calc(50% - 6px);
  }
}
.navigation-logo-only {
  position: fixed;
  top: 0;
  left: 0;
  height: 97px;
  width: 100%;
  background-color: #FFFFFF;
  z-index: 1000;
}
.navigation-logo-only::after {
  position: absolute;
  left: 0;
  bottom: 0;
  pointer-events: none;
  content: "";
  background-color: #DFE1E5;
  height: 1px;
  width: 100%;
}
.navigation-logo-only__spacer {
  height: 97px;
  flex: 0 0 auto;
}
.navigation-logo-only__logo {
  width: 100%;
  max-width: 210px;
  position: absolute;
  left: 40px;
  top: 24px;
  z-index: 1;
}

@media screen and (max-width: 1024px) {
  .navigation-logo-only {
    height: 64px;
  }
  .navigation-logo-only__spacer {
    height: 64px;
  }
  .navigation-logo-only__logo {
    top: 16px;
    left: 32px;
    max-width: 150px;
  }
}
@media screen and (max-width: 600px) {
  .navigation-logo-only-logo {
    left: 16px;
  }
}
.order-overview-item-addition {
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border: 1px solid #DFE1E5;
}
.order-overview-item-addition-text {
  padding-right: 20px;
  width: calc(78% + 12px);
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
}
.order-overview-item-addition-text-title {
  color: #444952;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.order-overview-item-addition-text-description {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
}
.order-overview-item-addition-price {
  padding-right: 15px;
  width: calc(12% - 12px);
}
.order-overview-item-addition-price-extra {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-top: 4px;
}
.order-overview-item-addition-sum {
  padding-right: 0;
  width: 10%;
  text-align: right;
}

@media screen and (max-width: 1024px) {
  .order-overview-item-addition {
    padding: 16px;
  }
  .order-overview-item-addition-text {
    width: 100%;
    flex: 1 1 auto;
    padding-right: 8px;
  }
  .order-overview-item-addition-price {
    display: none;
  }
  .order-overview-item-addition-sum {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #444952;
    padding-right: 0;
    flex: 0 0 auto;
    white-space: nowrap;
    width: auto;
  }
}
.cart-item-addition {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-block: 12px;
  border: 1px solid #DFE1E5;
}
.cart-item-addition-text {
  width: 71.7117117117%;
  padding-right: 20px;
  padding-left: 20px;
}
.cart-item-addition-text-title {
  color: #444952;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
}
.cart-item-addition-text-description {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-top: 4px;
}
.cart-item-addition-addition-amount {
  display: none;
}
.cart-item-addition-price {
  width: 10.8108108108%;
  padding-right: 20px;
  text-align: right;
}
.cart-item-addition-price-extra {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-top: 4px;
}
.cart-item-addition-sum {
  width: 11.7117117117%;
  padding-right: 20px;
  text-align: right;
}
.cart-item-addition-icon-spacer {
  width: 5.7657657658%;
  height: 1px;
}

@media screen and (max-width: 1200px) {
  .cart-item-addition {
    padding: 20px;
  }
  .cart-item-addition-text {
    width: 100%;
    padding-right: 40px;
    margin-bottom: 20px;
    padding-left: 0;
  }
  .cart-item--addition .cart-item-addition-text {
    width: 70%;
    margin-bottom: 0;
    padding-right: 0;
  }
  .cart-item-addition-text-title {
    display: inline;
    vertical-align: middle;
  }
  .cart-item--addition .cart-item-addition-text-description {
    display: block;
  }
  .cart-item-addition-price {
    display: none;
  }
  .cart-item-addition-sum {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: #444952;
    width: 50%;
    text-align: right;
    padding-right: 0;
  }
  .cart-item--transport .cart-item-addition-sum {
    width: 30%;
    margin-top: initial;
  }
  .cart-item--addition .cart-item-addition-sum {
    width: 50%;
    margin-top: 12px;
  }
  .cart-item-addition-amount {
    width: 50%;
    padding-right: 0;
  }
  .cart-item--transport .cart-item-addition-amount, .cart-item--addition .cart-item-addition-amount {
    display: none;
  }
  .cart-item-addition-addition-amount {
    display: block;
    width: 50%;
    padding-right: 0;
    font-size: 14px;
    line-height: 20px;
    color: #84878F;
  }
  .cart-item-addition-icon-spacer {
    display: none;
  }
}
.skeleton-row {
  border-top: 1px solid #DFE1E5;
}
.skeleton-row.skeleton-header-row {
  border-top: none;
}
.skeleton-row:not(.product-skeleton) .skeleton-item:last-child {
  padding-right: 85px;
}

.skeleton-item {
  padding: 13px 20px;
}
@media screen and (max-width: 1200px) {
  .skeleton-item {
    width: 100%;
  }
}
.skeleton-item.circle {
  padding: 13px 15px;
  width: 40px;
}
@media screen and (max-width: 1200px) {
  .skeleton-item.circle {
    display: none;
  }
}
.skeleton-item.skeleton-header {
  height: 69px;
  vertical-align: bottom;
}

@media screen and (max-width: 1200px) {
  .skeleton-spare-item {
    display: none;
  }
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  gap: 10px;
}

.pagination-description {
  display: inline-block;
}

.pagination-arrow {
  cursor: pointer;
  font-size: 24px;
  color: #D10931;
}
.pagination-arrow.disabled {
  pointer-events: none;
  cursor: unset;
  visibility: hidden;
}

.table-scroll-container {
  position: sticky;
  top: 50%;
  right: 0;
  z-index: 1000;
  text-align: right;
}

.shape-overview-table table th.js-sticky-td,
.shape-overview-table table td.js-sticky-td {
  background-color: #FFFFFF;
  z-index: 100;
  position: relative;
  left: 0 !important;
}

.shape-overview-table table th.js-sticky-td.highlighted,
.shape-overview-table table td.js-sticky-td.highlighted,
.shape-overview-table table tbody tr:hover:first-child,
tr:hover td.js-sticky-td,
.highlighted {
  background-color: #F3F4F5;
}

.shape-overview-table table td.highlighted:hover {
  background-color: #DFE1E5;
}

.shape-overview-table table tr:hover,
tbody .shape-overview-table table tr:hover {
  background-color: unset;
}

.shape-overview-table table td::after {
  display: none;
}

.shape-overview-table table th {
  transition: background-color 0.3s ease;
}

.scroll-button {
  font-size: 26px;
  background-color: #D10931;
  color: #FFFFFF;
  width: 40px;
  height: 80px;
  font-weight: 600;
  position: absolute;
  display: inline-block;
}
.scroll-button.js-table-scroll--right {
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  right: 0;
}
.scroll-button.js-table-scroll--right i {
  left: 4px;
}
.scroll-button.js-table-scroll--left {
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  left: 0;
}
.scroll-button.js-table-scroll--left i {
  right: 4px;
}
.scroll-button i {
  pointer-events: none;
  position: relative;
}

.sticky-scroll-shadow {
  right: -50px;
  background: linear-gradient(to right, rgba(46, 51, 61, 0.096) 0%, rgba(28, 29, 32, 0.096) 0.01%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;
}
.sticky-scroll-shadow.sticky-scroll-shadow--show {
  opacity: 1;
}
@media screen and (max-width: 600px) {
  .sticky-scroll-shadow.sticky-scroll-shadow--show {
    opacity: 0;
  }
}

button.loading {
  pointer-events: none !important;
  opacity: 0.5;
  cursor: not-allowed;
}

.button-primary .loading-box,
.button-secondary .loading-box {
  position: relative;
  width: auto;
  height: 2px;
  overflow: hidden;
  margin-top: -2px;
  display: block;
}

.button-primary--icon .loading-box,
.button-secondary--icon .loading-box {
  width: calc(100% + 34px);
}

.loader-inverted {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #DFE1E5;
  left: 0;
  animation: load 1.5s linear infinite;
}

@keyframes load {
  0% {
    left: -100%;
    width: 25%;
  }
  50% {
    width: 50%;
  }
  100% {
    left: 100%;
    width: 25%;
  }
}
html, * {
  scroll-behavior: smooth;
  font-family: "Akkurat Pro", sans-serif !important;
}

main {
  min-height: 540px;
}

.hidden {
  visibility: hidden;
}

.mat-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;
}
.mat-checkbox-inner-container .mat-checkbox-frame {
  border-width: 1px !important;
}

.button-primary:disabled, .button-secondary:disabled {
  cursor: not-allowed;
  color: #DFE1E5;
  background-color: #F3F4F5;
}
.button-primary:disabled:hover, .button-secondary:disabled:hover {
  background-color: #F3F4F5;
}

.mat-expansion-panel {
  display: inline !important;
}

.cdk-global-overlay-wrapper > .cdk-overlay-pane {
  padding: 0 !important;
}

mat-datepicker-input {
  height: 24px;
}

.display-flex {
  display: flex;
}

.display-none {
  display: none;
}

.app-loading {
  opacity: 0.5;
  pointer-events: none;
}

.icon-percent:before {
  content: "\e96b";
}

.profile-login-page {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
  display: flex;
  flex-direction: row;
}
.profile-login-page-content {
  width: 100%;
}

.registration-card-container {
  margin-top: 1.5rem;
  background-color: #F3F4F5;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 50%;
}
.registration-card-container a {
  color: #D10931;
}
.registration-card-container a:hover {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .profile-login-page {
    padding: 12px 32px 60px;
    flex-direction: column;
  }
  .registration-card-container {
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .profile-login-page {
    padding: 16px 16px 60px;
  }
}
.order-summary-privacy-text.privacy-error {
  color: #D10931;
}

.registration-page {
  padding: 20px 40px 80px;
  background-color: #F3F4F5;
  display: flex;
  flex-direction: row;
}
.registration-page-content {
  width: 100%;
}

.registration-form span.strong {
  font-weight: bold;
}

@media screen and (max-width: 1024px) {
  .registration-page {
    padding: 12px 32px 60px;
    flex-direction: column;
  }
}
@media screen and (max-width: 600px) {
  .registration-page {
    padding: 16px 16px 60px;
  }
}
.overlay {
  display: flex;
}

.overlay-content .mat-dialog-container {
  padding: 0;
  box-shadow: none;
  background: none;
  color: inherit;
  border-radius: 0;
}

.cdk-global-overlay-wrapper {
  z-index: 3000;
}

app-collapsable .mat-expansion-panel-header {
  padding: 0;
}
app-collapsable .mat-expansion-panel-body {
  padding: 0;
  box-sizing: border-box;
}

.navigation-item-title {
  display: block;
}

.navigation-item-menu-link {
  cursor: pointer;
}

.navigation-shop-link .navigation-item-title {
  padding-right: 20px;
}
.navigation-shop-link.navigation-item--menu::after {
  display: none;
}

@media screen and (min-width: 1025px) {
  .cms-navigation-item:hover .navigation-item-menu,
.navigation-item-hover .navigation-item-menu {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
  .navigation .navigation-item--menu.navigation-item--active::before {
    left: 50%;
  }
}
@media screen and (max-width: 1280px) {
  .navigation-button {
    padding: 20px 10px;
  }
  .navigation-cart-counter, .navigation-favorites-counter {
    right: 4px;
    height: 18px;
    width: 18px;
    font-size: 12px;
    line-height: 21px;
  }
}
@media screen and (max-width: 1200px) {
  .navigation-item-title {
    padding: 22px 10px;
    font-size: 16px;
  }
  .navigation-item--menu::after {
    right: 8px;
  }
  .navigation-item--menu:nth-child(1)::after {
    right: 10px;
  }
  .navigation-item--menu .navigation-item-title {
    padding-right: 32px;
  }
  .navigation-item:nth-child(1) .navigation-item-title {
    padding-right: 15px;
  }
  .navigation-item:nth-child(2) .navigation-item-title {
    padding-left: 15px;
  }
  .navigation .navigation-item--menu.navigation-item--active::before {
    left: calc(50% - 3px);
  }
}
.navigation-sales-rep {
  position: absolute;
  right: 590px;
  z-index: 1;
}
.navigation-sales-rep span {
  margin-right: 20px;
}

.navigation-logo {
  z-index: 3;
}
.navigation-logo img {
  z-index: 4;
  position: absolute;
}
.navigation-logo::before {
  content: "";
  position: absolute;
  width: 264px;
  height: 80px;
  left: -48px;
  top: -24px;
  background-color: #FFFFFF;
  z-index: 3;
}

@media screen and (max-width: 1024px) {
  .navigation-sales-rep {
    display: none;
  }
  .navigation-logo::before {
    width: 210px;
    height: 72px;
  }
}
.navigation-item--highlight .navigation-item-title {
  align-items: center;
  color: #d10931;
  display: flex;
  font-size: 18px;
  line-height: 18px;
}

@media screen and (min-width: 1025px) {
  .navigation-item--highlight-first .navigation-item-title:after {
    background-color: #dfe1e5;
    content: "";
    height: 32px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }
}
.profile-navigation-logout .profile-navigation-logout {
  padding: 0;
  border-bottom: 0 none;
}

.profile-login-login {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px 20px 24px;
  position: relative;
  margin-bottom: 20px;
}
.profile-login-login-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #2B2F38;
  margin-bottom: 28px;
}
.profile-login-login-content {
  display: flex;
}
.profile-login-login-content-left, .profile-login-login-content-right {
  max-width: 305px;
  width: 100%;
}
.profile-login-login-content-left {
  margin-right: 20px;
}
.profile-login-login-content-edit {
  margin-top: 20px;
}
.profile-login-login-content-edit:first-child {
  margin-top: 0;
}
.profile-login-login-content-edit-label {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-bottom: 8px;
}
.profile-login-login-content-edit-options-label {
  color: #84878F;
}
.profile-login-login-content-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}
.profile-login-login-content-buttons app-button {
  width: 140px;
}
.profile-login-login-content-buttons app-button:last-child {
  margin-left: 12px;
}

@media screen and (max-width: 600px) {
  .profile-login-login-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .profile-login-login-content {
    flex-direction: column;
  }
  .profile-login-login-content-left, .profile-login-login-content-right {
    max-width: unset;
  }
  .profile-login-login-content-left {
    margin-right: 0;
  }
  .profile-login-login-content-right {
    margin-top: 20px;
  }
  .profile-login-login-content-buttons {
    justify-content: initial;
  }
  .profile-login-login-content-buttons app-button {
    width: 100%;
  }
}
.shop-detail-informations-container-information-pair {
  flex-wrap: wrap;
  gap: 5px;
}

.shop-detail-informations-container-information-pair-value {
  margin-left: 0;
  word-break: break-word;
}

.shop-detail-informations {
  width: 100%;
  padding: 0;
}
.shop-detail-informations-container {
  margin-top: 0;
}
.shop-detail-informations-container-image {
  height: auto;
}
.shop-detail-informations-container-image img {
  height: 166px;
}

app-article-detail-information {
  width: 25%;
  padding: 10px;
}

@media screen and (max-width: 1024px) {
  app-article-detail-information {
    width: 100%;
    padding: 0;
  }
  .shop-detail-informations-container {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  app-article-detail-information {
    width: 100%;
  }
}
.shop-detail-configuration {
  width: 100%;
  padding: 0;
}
.shop-detail-configuration-container {
  margin-top: 0;
}

app-article-detail-configuration {
  width: 50%;
  padding: 10px;
}

@media screen and (max-width: 1024px) {
  app-article-detail-configuration {
    width: 100%;
    padding: 0;
  }
  .shop-detail-configuration-container {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  app-article-detail-configuration {
    width: 100%;
  }
}
.shop-detail-summary {
  width: 100%;
  padding: 0;
}
.shop-detail-summary-container {
  margin-top: 0;
}

app-article-detail-summary {
  width: 25%;
  padding: 10px;
}

@media screen and (max-width: 1024px) {
  app-article-detail-summary {
    width: 100%;
    padding: 0;
  }
  .shop-detail-summary-container {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .error .shop-detail-summary-cart {
    top: calc(var(--vh, 1vh) * 100 - 94px);
  }
  .error .shop-detail-summary-cart .text-input-error-text {
    background-color: #F3F4F5;
  }
  app-article-detail-summary {
    width: 100%;
  }
}
.cart-c2a {
  z-index: 300;
}
.cart-c2a .navigation-cart-counter {
  top: -5px;
  right: -5px;
  position: absolute;
}
.cart-c2a.disabled {
  display: none;
}

.sales-rep-c2a {
  width: 300px;
  right: unset;
  left: 16px;
}

.sales-rep-c2a-content {
  padding-top: 6px;
  border-radius: 0;
  width: 300px;
  color: #FFFFFF;
}

@media screen and (max-width: 1024px) {
  .cart-c2a.favorites-c2a {
    height: calc(100% - 116px);
  }
  .cart-c2a.favorites-c2a .cart-c2a-content {
    top: calc(var(--vh, 1vh) * 100 - 176px);
  }
}
@media screen and (max-width: 600px) {
  .cart-c2a.favorites-c2a {
    height: calc(100% - 86px);
  }
  .cart-c2a.favorites-c2a .cart-c2a-content {
    top: calc(var(--vh, 1vh) * 100 - 146px);
  }
}
@media screen and (max-width: 480px) {
  .sales-rep-c2a {
    width: 270px !important;
  }
  .sales-rep-c2a-content {
    width: 270px !important;
  }
}
.profile-cart-form-content-buttons app-button {
  width: 120px;
  display: block;
}
.profile-cart-form-content-buttons app-button a:last-child {
  width: 100%;
  margin: 0;
}
.profile-cart-form-content-buttons app-button:last-child {
  margin-left: 12px;
}

@media screen and (max-width: 600px) {
  .profile-cart-form-buttons app-button {
    width: calc(50% - 6px);
  }
}
.address-form-input-max {
  max-width: 630px;
}

.text-input-error-text {
  font-size: 12px;
  line-height: 16px;
  padding-top: 4px;
  color: #D10931;
}

.profile-offer-overview-filter-search-bottom-legend-state {
  cursor: default;
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-h1,
.mat-headline,
.mat-typography .mat-h1,
.mat-typography .mat-headline,
.mat-typography h1 {
  font: 400 24px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h2,
.mat-title,
.mat-typography .mat-h2,
.mat-typography .mat-title,
.mat-typography h2 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h3,
.mat-subheading-2,
.mat-typography .mat-h3,
.mat-typography .mat-subheading-2,
.mat-typography h3 {
  font: 400 16px / 28px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h4,
.mat-subheading-1,
.mat-typography .mat-h4,
.mat-typography .mat-subheading-1,
.mat-typography h4 {
  font: 400 15px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}

.mat-h5,
.mat-typography .mat-h5,
.mat-typography h5 {
  font: 400 calc(14px * 0.83) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-h6,
.mat-typography .mat-h6,
.mat-typography h6 {
  font: 400 calc(14px * 0.67) / 20px Roboto, "Helvetica Neue", sans-serif;
  margin: 0 0 12px;
}

.mat-body-strong,
.mat-body-2,
.mat-typography .mat-body-strong,
.mat-typography .mat-body-2 {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-body,
.mat-body-1,
.mat-typography .mat-body,
.mat-typography .mat-body-1,
.mat-typography {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}
.mat-body p,
.mat-body-1 p,
.mat-typography .mat-body p,
.mat-typography .mat-body-1 p,
.mat-typography p {
  margin: 0 0 12px;
}

.mat-small,
.mat-caption,
.mat-typography .mat-small,
.mat-typography .mat-caption {
  font: 400 12px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-display-4,
.mat-typography .mat-display-4 {
  font: 300 112px / 112px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.05em;
  margin: 0 0 56px;
}

.mat-display-3,
.mat-typography .mat-display-3 {
  font: 400 56px / 56px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.02em;
  margin: 0 0 64px;
}

.mat-display-2,
.mat-typography .mat-display-2 {
  font: 400 45px / 48px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: -0.005em;
  margin: 0 0 64px;
}

.mat-display-1,
.mat-typography .mat-display-1 {
  font: 400 34px / 40px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0 0 64px;
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.001px);
  width: 133.3333333333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00101px);
  width: 133.3333433333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00102px);
  width: 133.3333533333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28122em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28121em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.2812em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59375em) scale(0.75);
  width: 133.3333333333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59374em) scale(0.75);
  width: 133.3333433333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-option:hover:not(.mat-option-disabled), .mat-option:focus:not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-selected:not(.mat-option-multiple):not(.mat-option-disabled) {
  background: rgba(0, 0, 0, 0.04);
}
.mat-option.mat-active {
  background: rgba(0, 0, 0, 0.04);
  color: rgba(0, 0, 0, 0.87);
}
.mat-option.mat-option-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-accent .mat-option.mat-selected:not(.mat-option-disabled) {
  color: yellow;
}

.mat-warn .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #f44336;
}

.mat-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
}

.mat-optgroup-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-optgroup-disabled .mat-optgroup-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-optgroup-label {
  font: 500 14px / 24px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-pseudo-checkbox {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked,
.mat-pseudo-checkbox-indeterminate,
.mat-accent .mat-pseudo-checkbox-checked,
.mat-accent .mat-pseudo-checkbox-indeterminate {
  background: yellow;
}

.mat-warn .mat-pseudo-checkbox-checked,
.mat-warn .mat-pseudo-checkbox-indeterminate {
  background: #f44336;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-autocomplete-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-autocomplete-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: white;
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover):not(.mat-option-disabled) {
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge {
  position: relative;
}

.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

.mat-badge-content {
  color: white;
  background: #f44336;
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-accent .mat-badge-content {
  background: yellow;
  color: rgba(0, 0, 0, 0.87);
}

.mat-badge-warn .mat-badge-content {
  color: white;
  background: #f44336;
}

.mat-badge-disabled .mat-badge-content {
  background: #b9b9b9;
  color: rgba(0, 0, 0, 0.38);
}

.mat-badge-content {
  font-weight: 600;
  font-size: 12px;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-badge-small .mat-badge-content {
  font-size: 9px;
}

.mat-badge-large .mat-badge-content {
  font-size: 24px;
}

.mat-bottom-sheet-container {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-bottom-sheet-container {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-button, .mat-icon-button, .mat-stroked-button {
  color: inherit;
  background: transparent;
}
.mat-button.mat-primary, .mat-icon-button.mat-primary, .mat-stroked-button.mat-primary {
  color: #f44336;
}
.mat-button.mat-accent, .mat-icon-button.mat-accent, .mat-stroked-button.mat-accent {
  color: yellow;
}
.mat-button.mat-warn, .mat-icon-button.mat-warn, .mat-stroked-button.mat-warn {
  color: #f44336;
}
.mat-button.mat-primary.mat-button-disabled, .mat-button.mat-accent.mat-button-disabled, .mat-button.mat-warn.mat-button-disabled, .mat-button.mat-button-disabled.mat-button-disabled, .mat-icon-button.mat-primary.mat-button-disabled, .mat-icon-button.mat-accent.mat-button-disabled, .mat-icon-button.mat-warn.mat-button-disabled, .mat-icon-button.mat-button-disabled.mat-button-disabled, .mat-stroked-button.mat-primary.mat-button-disabled, .mat-stroked-button.mat-accent.mat-button-disabled, .mat-stroked-button.mat-warn.mat-button-disabled, .mat-stroked-button.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-button.mat-primary .mat-button-focus-overlay, .mat-icon-button.mat-primary .mat-button-focus-overlay, .mat-stroked-button.mat-primary .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-accent .mat-button-focus-overlay, .mat-icon-button.mat-accent .mat-button-focus-overlay, .mat-stroked-button.mat-accent .mat-button-focus-overlay {
  background-color: yellow;
}
.mat-button.mat-warn .mat-button-focus-overlay, .mat-icon-button.mat-warn .mat-button-focus-overlay, .mat-stroked-button.mat-warn .mat-button-focus-overlay {
  background-color: #f44336;
}
.mat-button.mat-button-disabled .mat-button-focus-overlay, .mat-icon-button.mat-button-disabled .mat-button-focus-overlay, .mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  background-color: transparent;
}
.mat-button .mat-ripple-element, .mat-icon-button .mat-ripple-element, .mat-stroked-button .mat-ripple-element {
  opacity: 0.1;
  background-color: currentColor;
}

.mat-button-focus-overlay {
  background: black;
}

.mat-stroked-button:not(.mat-button-disabled) {
  border-color: rgba(0, 0, 0, 0.12);
}

.mat-flat-button, .mat-raised-button, .mat-fab, .mat-mini-fab {
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  color: white;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  color: rgba(0, 0, 0, 0.87);
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  color: white;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.mat-flat-button.mat-primary, .mat-raised-button.mat-primary, .mat-fab.mat-primary, .mat-mini-fab.mat-primary {
  background-color: #f44336;
}
.mat-flat-button.mat-accent, .mat-raised-button.mat-accent, .mat-fab.mat-accent, .mat-mini-fab.mat-accent {
  background-color: yellow;
}
.mat-flat-button.mat-warn, .mat-raised-button.mat-warn, .mat-fab.mat-warn, .mat-mini-fab.mat-warn {
  background-color: #f44336;
}
.mat-flat-button.mat-primary.mat-button-disabled, .mat-flat-button.mat-accent.mat-button-disabled, .mat-flat-button.mat-warn.mat-button-disabled, .mat-flat-button.mat-button-disabled.mat-button-disabled, .mat-raised-button.mat-primary.mat-button-disabled, .mat-raised-button.mat-accent.mat-button-disabled, .mat-raised-button.mat-warn.mat-button-disabled, .mat-raised-button.mat-button-disabled.mat-button-disabled, .mat-fab.mat-primary.mat-button-disabled, .mat-fab.mat-accent.mat-button-disabled, .mat-fab.mat-warn.mat-button-disabled, .mat-fab.mat-button-disabled.mat-button-disabled, .mat-mini-fab.mat-primary.mat-button-disabled, .mat-mini-fab.mat-accent.mat-button-disabled, .mat-mini-fab.mat-warn.mat-button-disabled, .mat-mini-fab.mat-button-disabled.mat-button-disabled {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-flat-button.mat-primary .mat-ripple-element, .mat-raised-button.mat-primary .mat-ripple-element, .mat-fab.mat-primary .mat-ripple-element, .mat-mini-fab.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-flat-button.mat-accent .mat-ripple-element, .mat-raised-button.mat-accent .mat-ripple-element, .mat-fab.mat-accent .mat-ripple-element, .mat-mini-fab.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}
.mat-flat-button.mat-warn .mat-ripple-element, .mat-raised-button.mat-warn .mat-ripple-element, .mat-fab.mat-warn .mat-ripple-element, .mat-mini-fab.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}

.mat-stroked-button:not([class*=mat-elevation-z]), .mat-flat-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.mat-raised-button.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-fab:not([class*=mat-elevation-z]), .mat-mini-fab:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.mat-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]), .mat-mini-fab:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.mat-fab.mat-button-disabled:not([class*=mat-elevation-z]), .mat-mini-fab.mat-button-disabled:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button,
.mat-flat-button, .mat-fab, .mat-mini-fab {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-button-toggle-standalone:not([class*=mat-elevation-z]),
.mat-button-toggle-group:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard:not([class*=mat-elevation-z]),
.mat-button-toggle-group-appearance-standard:not([class*=mat-elevation-z]) {
  box-shadow: none;
}

.mat-button-toggle {
  color: rgba(0, 0, 0, 0.38);
}
.mat-button-toggle .mat-button-toggle-focus-overlay {
  background-color: rgba(0, 0, 0, 0.12);
}

.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
  background: white;
}
.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: black;
}

.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: solid 1px #e0e0e0;
}

[dir=rtl] .mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: solid 1px #e0e0e0;
}

.mat-button-toggle-group-appearance-standard.mat-button-toggle-vertical .mat-button-toggle + .mat-button-toggle {
  border-left: none;
  border-right: none;
  border-top: solid 1px #e0e0e0;
}

.mat-button-toggle-checked {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.54);
}
.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: rgba(0, 0, 0, 0.87);
}

.mat-button-toggle-disabled {
  color: rgba(0, 0, 0, 0.26);
  background-color: #eeeeee;
}
.mat-button-toggle-disabled.mat-button-toggle-appearance-standard {
  background: white;
}
.mat-button-toggle-disabled.mat-button-toggle-checked {
  background-color: #bdbdbd;
}

.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border: solid 1px #e0e0e0;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 48px;
}

.mat-button-toggle {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.mat-card.mat-card-flat:not([class*=mat-elevation-z]) {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-card-subtitle {
  color: rgba(0, 0, 0, 0.54);
}

.mat-card {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-card-title {
  font-size: 24px;
  font-weight: 500;
}

.mat-card-header .mat-card-title {
  font-size: 20px;
}

.mat-card-subtitle,
.mat-card-content {
  font-size: 14px;
}

.mat-checkbox-frame {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-checkbox-checkmark {
  fill: #fafafa;
}

.mat-checkbox-checkmark-path {
  stroke: #fafafa !important;
}

.mat-checkbox-mixedmark {
  background-color: #fafafa;
}

.mat-checkbox-indeterminate.mat-primary .mat-checkbox-background, .mat-checkbox-checked.mat-primary .mat-checkbox-background {
  background-color: #f44336;
}
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: yellow;
}
.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background, .mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #f44336;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background, .mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: #b0b0b0;
}
.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-frame {
  border-color: #b0b0b0;
}
.mat-checkbox-disabled .mat-checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.mat-checkbox .mat-ripple-element {
  background-color: black;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element {
  background: #f44336;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element {
  background: yellow;
}
.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #f44336;
}

.mat-checkbox {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-checkbox-layout .mat-checkbox-label {
  line-height: 24px;
}

.mat-chip.mat-standard-chip {
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled):active {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.mat-chip.mat-standard-chip:not(.mat-chip-disabled) .mat-chip-remove:hover {
  opacity: 0.54;
}
.mat-chip.mat-standard-chip.mat-chip-disabled {
  opacity: 0.4;
}
.mat-chip.mat-standard-chip::after {
  background: black;
}

.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-primary .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn {
  background-color: #f44336;
  color: white;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-chip-remove {
  color: white;
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-warn .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent {
  background-color: yellow;
  color: rgba(0, 0, 0, 0.87);
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-chip-remove {
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.4;
}
.mat-chip.mat-standard-chip.mat-chip-selected.mat-accent .mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

.mat-chip {
  font-size: 14px;
  font-weight: 500;
}
.mat-chip .mat-chip-trailing-icon.mat-icon,
.mat-chip .mat-chip-remove.mat-icon {
  font-size: 18px;
}

.mat-table {
  background: white;
}

.mat-table thead, .mat-table tbody, .mat-table tfoot,
mat-header-row, mat-row, mat-footer-row,
[mat-header-row], [mat-row], [mat-footer-row],
.mat-table-sticky {
  background: inherit;
}

mat-row, mat-header-row, mat-footer-row,
th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}

.mat-header-cell {
  color: rgba(0, 0, 0, 0.54);
}

.mat-cell, .mat-footer-cell {
  color: rgba(0, 0, 0, 0.87);
}

.mat-table {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-header-cell {
  font-size: 12px;
  font-weight: 500;
}

.mat-cell, .mat-footer-cell {
  font-size: 14px;
}

.mat-calendar-arrow {
  fill: rgba(0, 0, 0, 0.54);
}

.mat-datepicker-toggle,
.mat-datepicker-content .mat-calendar-next-button,
.mat-datepicker-content .mat-calendar-previous-button {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-table-header-divider::after {
  background: rgba(0, 0, 0, 0.12);
}

.mat-calendar-table-header,
.mat-calendar-body-label {
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.87);
  border-color: transparent;
}

.mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  color: rgba(0, 0, 0, 0.38);
}

.mat-form-field-disabled .mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-in-preview {
  color: rgba(0, 0, 0, 0.24);
}

.mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-calendar-body-disabled > .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  border-color: rgba(0, 0, 0, 0.18);
}

.mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}

.mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}

.mat-calendar-body-comparison-bridge-start::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-comparison-bridge-end::before,
[dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}

.mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}

.mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}

.mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}

.mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}

.mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}

.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}

@media (hover: hover) {
  .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}
.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range::before {
  background: rgba(255, 255, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(255, 255, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-accent [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(255, 255, 0, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-accent .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-accent .mat-calendar-body-selected {
  background-color: yellow;
  color: rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(255, 255, 0, 0.4);
}
.mat-datepicker-content.mat-accent .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.87);
}
.mat-datepicker-content.mat-accent .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-accent .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(255, 255, 0, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-accent .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(255, 255, 0, 0.3);
  }
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range::before {
  background: rgba(244, 67, 54, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range::before {
  background: rgba(249, 171, 0, 0.2);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-start::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-end::before {
  background: linear-gradient(to right, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-bridge-end::before,
.mat-datepicker-content.mat-warn [dir=rtl] .mat-calendar-body-comparison-bridge-start::before {
  background: linear-gradient(to left, rgba(244, 67, 54, 0.2) 50%, rgba(249, 171, 0, 0.2) 50%);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-in-range > .mat-calendar-body-comparison-identical,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range.mat-calendar-body-in-range::after {
  background: #a8dab5;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-comparison-identical.mat-calendar-body-selected,
.mat-datepicker-content.mat-warn .mat-calendar-body-in-comparison-range > .mat-calendar-body-selected {
  background: #46a35e;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-selected {
  background-color: #f44336;
  color: white;
}
.mat-datepicker-content.mat-warn .mat-calendar-body-disabled > .mat-calendar-body-selected {
  background-color: rgba(244, 67, 54, 0.4);
}
.mat-datepicker-content.mat-warn .mat-calendar-body-today.mat-calendar-body-selected {
  box-shadow: inset 0 0 0 1px white;
}
.mat-datepicker-content.mat-warn .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.mat-datepicker-content.mat-warn .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgba(244, 67, 54, 0.3);
}
@media (hover: hover) {
  .mat-datepicker-content.mat-warn .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: rgba(244, 67, 54, 0.3);
  }
}

.mat-datepicker-content-touch {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-datepicker-toggle-active {
  color: #f44336;
}
.mat-datepicker-toggle-active.mat-accent {
  color: yellow;
}
.mat-datepicker-toggle-active.mat-warn {
  color: #f44336;
}

.mat-date-range-input-inner[disabled] {
  color: rgba(0, 0, 0, 0.38);
}

.mat-calendar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-calendar-body {
  font-size: 13px;
}

.mat-calendar-body-label,
.mat-calendar-period-button {
  font-size: 14px;
  font-weight: 500;
}

.mat-calendar-table-header th {
  font-size: 11px;
  font-weight: 400;
}

.mat-dialog-container {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  background: white;
  color: rgba(0, 0, 0, 0.87);
}

.mat-dialog-title {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-divider-vertical {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel {
  background: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-action-row {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-expansion-panel .mat-expansion-panel-header.cdk-keyboard-focused:not([aria-disabled=true]), .mat-expansion-panel .mat-expansion-panel-header.cdk-program-focused:not([aria-disabled=true]), .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:hover:not([aria-disabled=true]) {
  background: rgba(0, 0, 0, 0.04);
}

@media (hover: none) {
  .mat-expansion-panel:not(.mat-expanded):not([aria-disabled=true]) .mat-expansion-panel-header:hover {
    background: white;
  }
}
.mat-expansion-panel-header-title {
  color: rgba(0, 0, 0, 0.87);
}

.mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-expansion-panel-header[aria-disabled=true] {
  color: rgba(0, 0, 0, 0.26);
}
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-title,
.mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  color: inherit;
}

.mat-expansion-panel-header {
  height: 48px;
}
.mat-expansion-panel-header.mat-expanded {
  height: 64px;
}

.mat-expansion-panel-header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 400;
}

.mat-expansion-panel-content {
  font: 400 14px / 20px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-label {
  color: rgba(0, 0, 0, 0.6);
}

.mat-hint {
  color: rgba(0, 0, 0, 0.6);
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-accent {
  color: yellow;
}
.mat-form-field.mat-focused .mat-form-field-label.mat-warn {
  color: #f44336;
}

.mat-focused .mat-form-field-required-marker {
  color: yellow;
}

.mat-form-field-ripple {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #f44336;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-accent {
  background-color: yellow;
}
.mat-form-field.mat-focused .mat-form-field-ripple.mat-warn {
  background-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid) .mat-form-field-infix::after {
  color: #f44336;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-accent .mat-form-field-infix::after {
  color: yellow;
}
.mat-form-field-type-mat-native-select.mat-focused:not(.mat-form-field-invalid).mat-warn .mat-form-field-infix::after {
  color: #f44336;
}

.mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-label.mat-accent,
.mat-form-field.mat-form-field-invalid .mat-form-field-label .mat-form-field-required-marker {
  color: #f44336;
}
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple,
.mat-form-field.mat-form-field-invalid .mat-form-field-ripple.mat-accent {
  background-color: #f44336;
}

.mat-error {
  color: #f44336;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-hint {
  color: rgba(0, 0, 0, 0.54);
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-standard .mat-form-field-underline {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
  background-size: 4px 100%;
  background-repeat: repeat-x;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background-color: rgba(0, 0, 0, 0.02);
}
.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: rgba(0, 0, 0, 0.42);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-underline::before {
  background-color: transparent;
}

.mat-form-field-appearance-outline .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.12);
}
.mat-form-field-appearance-outline .mat-form-field-outline-thick {
  color: rgba(0, 0, 0, 0.87);
}
.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-focused.mat-accent .mat-form-field-outline-thick {
  color: yellow;
}
.mat-form-field-appearance-outline.mat-focused.mat-warn .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: #f44336;
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-outline {
  color: rgba(0, 0, 0, 0.06);
}

.mat-form-field {
  font-size: inherit;
  font-weight: 400;
  line-height: 1.125;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
}

.mat-form-field-wrapper {
  padding-bottom: 1.34375em;
}

.mat-form-field-prefix .mat-icon,
.mat-form-field-suffix .mat-icon {
  font-size: 150%;
  line-height: 1.125;
}
.mat-form-field-prefix .mat-icon-button,
.mat-form-field-suffix .mat-icon-button {
  height: 1.5em;
  width: 1.5em;
}
.mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field-suffix .mat-icon-button .mat-icon {
  height: 1.125em;
  line-height: 1.125;
}

.mat-form-field-infix {
  padding: 0.5em 0;
  border-top: 0.84375em solid transparent;
}

.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.34372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-label-wrapper {
  top: -0.84375em;
  padding-top: 0.84375em;
}

.mat-form-field-label {
  top: 1.34375em;
}

.mat-form-field-underline {
  bottom: 1.34375em;
}

.mat-form-field-subscript-wrapper {
  font-size: 75%;
  margin-top: 0.6666666667em;
  top: calc(100% - 1.7916666667em);
}

.mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.4375em 0;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00106px);
  width: 133.3333933333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00107px);
  width: 133.3334033333%;
}
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.28125em) scale(0.75) perspective(100px) translateZ(0.00108px);
  width: 133.3334133333%;
}
.mat-form-field-appearance-legacy .mat-form-field-label {
  top: 1.28125em;
}
.mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 1.25em;
}
.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.5416666667em;
  top: calc(100% - 1.6666666667em);
}

@media print {
  .mat-form-field-appearance-legacy.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28116em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-form-field-autofill-control:-webkit-autofill + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28115em) scale(0.75);
  }
  .mat-form-field-appearance-legacy.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-1.28114em) scale(0.75);
  }
}
.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0.25em 0 0.75em 0;
}
.mat-form-field-appearance-fill .mat-form-field-label {
  top: 1.09375em;
  margin-top: -0.5em;
}
.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-0.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 1em 0 1em 0;
}
.mat-form-field-appearance-outline .mat-form-field-label {
  top: 1.84375em;
  margin-top: -0.25em;
}
.mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59373em) scale(0.75);
  width: 133.3333533333%;
}
.mat-form-field-appearance-outline.mat-form-field-can-float .mat-input-server[label]:not(:label-shown) + .mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-1.59372em) scale(0.75);
  width: 133.3333633333%;
}

.mat-grid-tile-header,
.mat-grid-tile-footer {
  font-size: 14px;
}
.mat-grid-tile-header .mat-line,
.mat-grid-tile-footer .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-grid-tile-header .mat-line:nth-child(n+2),
.mat-grid-tile-footer .mat-line:nth-child(n+2) {
  font-size: 12px;
}

.mat-icon.mat-primary {
  color: #f44336;
}
.mat-icon.mat-accent {
  color: yellow;
}
.mat-icon.mat-warn {
  color: #f44336;
}

.mat-form-field-type-mat-native-select .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}

.mat-input-element:disabled,
.mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-input-element {
  caret-color: #f44336;
}
.mat-input-element::placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-moz-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}
.mat-input-element:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-form-field.mat-accent .mat-input-element {
  caret-color: yellow;
}

.mat-form-field.mat-warn .mat-input-element,
.mat-form-field-invalid .mat-input-element {
  caret-color: #f44336;
}

.mat-form-field-type-mat-native-select.mat-form-field-invalid .mat-form-field-infix::after {
  color: #f44336;
}

input.mat-input-element {
  margin-top: -0.0625em;
}

.mat-list-base .mat-list-item {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-list-option {
  color: rgba(0, 0, 0, 0.87);
}
.mat-list-base .mat-subheader {
  color: rgba(0, 0, 0, 0.54);
}
.mat-list-base .mat-list-item-disabled {
  background-color: #eeeeee;
  color: rgba(0, 0, 0, 0.38);
}

.mat-list-option:hover, .mat-list-option:focus,
.mat-nav-list .mat-list-item:hover,
.mat-nav-list .mat-list-item:focus,
.mat-action-list .mat-list-item:hover,
.mat-action-list .mat-list-item:focus {
  background: rgba(0, 0, 0, 0.04);
}

.mat-list-single-selected-option, .mat-list-single-selected-option:hover, .mat-list-single-selected-option:focus {
  background: rgba(0, 0, 0, 0.12);
}

.mat-list-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-option {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-list-base .mat-list-item {
  font-size: 16px;
}
.mat-list-base .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-list-option {
  font-size: 16px;
}
.mat-list-base .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 14px;
}
.mat-list-base .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-list-base[dense] .mat-list-item {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-item .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-item .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option {
  font-size: 12px;
}
.mat-list-base[dense] .mat-list-option .mat-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  box-sizing: border-box;
}
.mat-list-base[dense] .mat-list-option .mat-line:nth-child(n+2) {
  font-size: 12px;
}
.mat-list-base[dense] .mat-subheader {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-menu-panel {
  background: white;
}
.mat-menu-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-menu-item {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
}
.mat-menu-item[disabled],
.mat-menu-item[disabled] .mat-menu-submenu-icon,
.mat-menu-item[disabled] .mat-icon-no-color {
  color: rgba(0, 0, 0, 0.38);
}

.mat-menu-item .mat-icon-no-color,
.mat-menu-submenu-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: rgba(0, 0, 0, 0.04);
}

.mat-menu-item {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.mat-paginator {
  background: white;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: rgba(0, 0, 0, 0.54);
}

.mat-paginator-decrement,
.mat-paginator-increment {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
  border-right: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-paginator-first,
.mat-paginator-last {
  border-top: 2px solid rgba(0, 0, 0, 0.54);
}

.mat-icon-button[disabled] .mat-paginator-decrement,
.mat-icon-button[disabled] .mat-paginator-increment,
.mat-icon-button[disabled] .mat-paginator-first,
.mat-icon-button[disabled] .mat-paginator-last {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-paginator-container {
  min-height: 56px;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
}

.mat-progress-bar-background {
  fill: #f9ccc9;
}

.mat-progress-bar-buffer {
  background-color: #f9ccc9;
}

.mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-bar.mat-accent .mat-progress-bar-background {
  fill: #fbfbbc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-buffer {
  background-color: #fbfbbc;
}
.mat-progress-bar.mat-accent .mat-progress-bar-fill::after {
  background-color: yellow;
}

.mat-progress-bar.mat-warn .mat-progress-bar-background {
  fill: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-buffer {
  background-color: #f9ccc9;
}
.mat-progress-bar.mat-warn .mat-progress-bar-fill::after {
  background-color: #f44336;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: #f44336;
}
.mat-progress-spinner.mat-accent circle, .mat-spinner.mat-accent circle {
  stroke: yellow;
}
.mat-progress-spinner.mat-warn circle, .mat-spinner.mat-warn circle {
  stroke: #f44336;
}

.mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.54);
}

.mat-radio-button.mat-primary.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-primary .mat-radio-inner-circle,
.mat-radio-button.mat-primary .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-primary.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-primary:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: yellow;
}
.mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: yellow;
}
.mat-radio-button.mat-warn.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f44336;
}
.mat-radio-button.mat-warn .mat-radio-inner-circle,
.mat-radio-button.mat-warn .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-warn.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-warn:active .mat-radio-persistent-ripple {
  background-color: #f44336;
}
.mat-radio-button.mat-radio-disabled.mat-radio-checked .mat-radio-outer-circle,
.mat-radio-button.mat-radio-disabled .mat-radio-outer-circle {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-ripple .mat-ripple-element,
.mat-radio-button.mat-radio-disabled .mat-radio-inner-circle {
  background-color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button.mat-radio-disabled .mat-radio-label-content {
  color: rgba(0, 0, 0, 0.38);
}
.mat-radio-button .mat-ripple-element {
  background-color: black;
}

.mat-radio-button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-value {
  color: rgba(0, 0, 0, 0.87);
}

.mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

.mat-select-panel {
  background: white;
}
.mat-select-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.12);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #f44336;
}
.mat-form-field.mat-focused.mat-accent .mat-select-arrow {
  color: yellow;
}
.mat-form-field.mat-focused.mat-warn .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-invalid .mat-select-arrow {
  color: #f44336;
}
.mat-form-field .mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.38);
}

.mat-select {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-select-trigger {
  height: 1.125em;
}

.mat-drawer-container {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-drawer {
  background-color: white;
  color: rgba(0, 0, 0, 0.87);
}
.mat-drawer.mat-drawer-push {
  background-color: white;
}
.mat-drawer:not(.mat-drawer-side) {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-drawer-side {
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}
.mat-drawer-side.mat-drawer-end {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}

[dir=rtl] .mat-drawer-side {
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
}
[dir=rtl] .mat-drawer-side.mat-drawer-end {
  border-left: none;
  border-right: solid 1px rgba(0, 0, 0, 0.12);
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: yellow;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 255, 0, 0.54);
}
.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: yellow;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-primary.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-thumb {
  background-color: #f44336;
}
.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(244, 67, 54, 0.54);
}
.mat-slide-toggle.mat-warn.mat-checked .mat-ripple-element {
  background-color: #f44336;
}
.mat-slide-toggle:not(.mat-checked) .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle-thumb {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}

.mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slide-toggle-content {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-primary .mat-slider-track-fill,
.mat-slider.mat-primary .mat-slider-thumb,
.mat-slider.mat-primary .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-primary .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-primary .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}
.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label {
  background-color: yellow;
}
.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-accent .mat-slider-focus-ring {
  background-color: rgba(255, 255, 0, 0.2);
}
.mat-slider.mat-warn .mat-slider-track-fill,
.mat-slider.mat-warn .mat-slider-thumb,
.mat-slider.mat-warn .mat-slider-thumb-label {
  background-color: #f44336;
}
.mat-slider.mat-warn .mat-slider-thumb-label-text {
  color: white;
}
.mat-slider.mat-warn .mat-slider-focus-ring {
  background-color: rgba(244, 67, 54, 0.2);
}

.mat-slider:hover .mat-slider-track-background,
.mat-slider.cdk-focused .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.38);
}

.mat-slider.mat-slider-disabled .mat-slider-track-background,
.mat-slider.mat-slider-disabled .mat-slider-track-fill,
.mat-slider.mat-slider-disabled .mat-slider-thumb {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-disabled:hover .mat-slider-track-background {
  background-color: rgba(0, 0, 0, 0.26);
}

.mat-slider.mat-slider-min-value .mat-slider-focus-ring {
  background-color: rgba(0, 0, 0, 0.12);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb,
.mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing.cdk-focused .mat-slider-thumb-label {
  background-color: rgba(0, 0, 0, 0.26);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
  background-color: transparent;
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.38);
}
.mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing):hover.mat-slider-disabled .mat-slider-thumb, .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing).cdk-focused.mat-slider-disabled .mat-slider-thumb {
  border-color: rgba(0, 0, 0, 0.26);
}

.mat-slider-has-ticks .mat-slider-wrapper::after {
  border-color: rgba(0, 0, 0, 0.7);
}

.mat-slider-horizontal .mat-slider-ticks {
  background-image: repeating-linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
  background-image: -moz-repeating-linear-gradient(0.0001deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-vertical .mat-slider-ticks {
  background-image: repeating-linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7) 2px, transparent 0, transparent);
}

.mat-slider-thumb-label-text {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 12px;
  font-weight: 500;
}

.mat-step-header.cdk-keyboard-focused, .mat-step-header.cdk-program-focused, .mat-step-header:hover:not([aria-disabled]), .mat-step-header:hover[aria-disabled=false] {
  background-color: rgba(0, 0, 0, 0.04);
}
.mat-step-header:hover[aria-disabled=true] {
  cursor: default;
}
@media (hover: none) {
  .mat-step-header:hover {
    background: none;
  }
}
.mat-step-header .mat-step-label,
.mat-step-header .mat-step-optional {
  color: rgba(0, 0, 0, 0.54);
}
.mat-step-header .mat-step-icon {
  background-color: rgba(0, 0, 0, 0.54);
  color: white;
}
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header.mat-accent .mat-step-icon {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-accent .mat-step-icon-selected,
.mat-step-header.mat-accent .mat-step-icon-state-done,
.mat-step-header.mat-accent .mat-step-icon-state-edit {
  background-color: yellow;
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header.mat-warn .mat-step-icon {
  color: white;
}
.mat-step-header.mat-warn .mat-step-icon-selected,
.mat-step-header.mat-warn .mat-step-icon-state-done,
.mat-step-header.mat-warn .mat-step-icon-state-edit {
  background-color: #f44336;
  color: white;
}
.mat-step-header .mat-step-icon-state-error {
  background-color: transparent;
  color: #f44336;
}
.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba(0, 0, 0, 0.87);
}
.mat-step-header .mat-step-label.mat-step-label-error {
  color: #f44336;
}

.mat-stepper-horizontal, .mat-stepper-vertical {
  background-color: white;
}

.mat-stepper-vertical-line::before {
  border-left-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header::before,
.mat-horizontal-stepper-header::after,
.mat-stepper-horizontal-line {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.mat-horizontal-stepper-header {
  height: 72px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header,
.mat-vertical-stepper-header {
  padding: 24px 24px;
}

.mat-stepper-vertical-line::before {
  top: -16px;
  bottom: -16px;
}

.mat-stepper-label-position-bottom .mat-horizontal-stepper-header::after, .mat-stepper-label-position-bottom .mat-horizontal-stepper-header::before {
  top: 36px;
}

.mat-stepper-label-position-bottom .mat-stepper-horizontal-line {
  top: 36px;
}

.mat-stepper-vertical, .mat-stepper-horizontal {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-step-label {
  font-size: 14px;
  font-weight: 400;
}

.mat-step-sub-label-error {
  font-weight: normal;
}

.mat-step-label-error {
  font-size: 14px;
}

.mat-step-label-selected {
  font-size: 14px;
  font-weight: 500;
}

.mat-sort-header-arrow {
  color: #757575;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.mat-tab-group-inverted-header .mat-tab-nav-bar,
.mat-tab-group-inverted-header .mat-tab-header {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: none;
}

.mat-tab-label, .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-label.mat-tab-disabled, .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.38);
}

.mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.87);
}

.mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: rgba(0, 0, 0, 0.38);
}

.mat-tab-group[class*=mat-background-] > .mat-tab-header,
.mat-tab-nav-bar[class*=mat-background-] {
  border-bottom: none;
  border-top: none;
}

.mat-tab-group.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-primary.mat-background-primary > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 255, 141, 0.3);
}
.mat-tab-group.mat-accent .mat-ink-bar, .mat-tab-nav-bar.mat-accent .mat-ink-bar {
  background-color: yellow;
}
.mat-tab-group.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-accent.mat-background-accent > .mat-tab-link-container .mat-ink-bar {
  background-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-warn .mat-ink-bar, .mat-tab-nav-bar.mat-warn .mat-ink-bar {
  background-color: #f44336;
}
.mat-tab-group.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-group.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-header .mat-ink-bar, .mat-tab-nav-bar.mat-warn.mat-background-warn > .mat-tab-link-container .mat-ink-bar {
  background-color: white;
}
.mat-tab-group.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-primary .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-primary .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-primary > .mat-tab-header, .mat-tab-group.mat-background-primary > .mat-tab-link-container, .mat-tab-group.mat-background-primary > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-primary > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-primary > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}
.mat-tab-group.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-accent .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-accent .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 255, 141, 0.3);
}
.mat-tab-group.mat-background-accent > .mat-tab-header, .mat-tab-group.mat-background-accent > .mat-tab-link-container, .mat-tab-group.mat-background-accent > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination {
  background-color: yellow;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link {
  color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(0, 0, 0, 0.4);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-focus-indicator::before {
  border-color: rgba(0, 0, 0, 0.87);
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: black;
  opacity: 0.4;
}
.mat-tab-group.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-accent > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-accent > .mat-tab-header-pagination .mat-ripple-element {
  background-color: black;
  opacity: 0.12;
}
.mat-tab-group.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-group.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-group.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-keyboard-focused:not(.mat-tab-disabled), .mat-tab-nav-bar.mat-background-warn .mat-tab-label.cdk-program-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-keyboard-focused:not(.mat-tab-disabled),
.mat-tab-nav-bar.mat-background-warn .mat-tab-link.cdk-program-focused:not(.mat-tab-disabled) {
  background-color: rgba(255, 205, 210, 0.3);
}
.mat-tab-group.mat-background-warn > .mat-tab-header, .mat-tab-group.mat-background-warn > .mat-tab-link-container, .mat-tab-group.mat-background-warn > .mat-tab-header-pagination, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination {
  background-color: #f44336;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link {
  color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-label.mat-tab-disabled, .mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-tab-link.mat-tab-disabled {
  color: rgba(255, 255, 255, 0.4);
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-focus-indicator::before, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-focus-indicator::before,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-focus-indicator::before {
  border-color: white;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
  border-color: white;
  opacity: 0.4;
}
.mat-tab-group.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-group.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element, .mat-tab-nav-bar.mat-background-warn > .mat-tab-header .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-link-container .mat-ripple-element,
.mat-tab-nav-bar.mat-background-warn > .mat-tab-header-pagination .mat-ripple-element {
  background-color: white;
  opacity: 0.12;
}

.mat-tab-group {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tab-label, .mat-tab-link {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.mat-toolbar {
  background: whitesmoke;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-primary {
  background: #f44336;
  color: white;
}
.mat-toolbar.mat-accent {
  background: yellow;
  color: rgba(0, 0, 0, 0.87);
}
.mat-toolbar.mat-warn {
  background: #f44336;
  color: white;
}
.mat-toolbar .mat-form-field-underline,
.mat-toolbar .mat-form-field-ripple,
.mat-toolbar .mat-focused .mat-form-field-ripple {
  background-color: currentColor;
}
.mat-toolbar .mat-form-field-label,
.mat-toolbar .mat-focused .mat-form-field-label,
.mat-toolbar .mat-select-value,
.mat-toolbar .mat-select-arrow,
.mat-toolbar .mat-form-field.mat-focused .mat-select-arrow {
  color: inherit;
}
.mat-toolbar .mat-input-element {
  caret-color: currentColor;
}

.mat-toolbar-multiple-rows {
  min-height: 64px;
}

.mat-toolbar-row, .mat-toolbar-single-row {
  height: 64px;
}

@media (max-width: 599px) {
  .mat-toolbar-multiple-rows {
    min-height: 56px;
  }
  .mat-toolbar-row, .mat-toolbar-single-row {
    height: 56px;
  }
}
.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
  font: 500 20px / 32px Roboto, "Helvetica Neue", sans-serif;
  letter-spacing: normal;
  margin: 0;
}

.mat-tooltip {
  background: rgba(97, 97, 97, 0.9);
}

.mat-tooltip {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 10px;
  padding-top: 6px;
  padding-bottom: 6px;
}

.mat-tooltip-handset {
  font-size: 14px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.mat-tree {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-tree-node,
.mat-nested-tree-node {
  font-weight: 400;
  font-size: 14px;
}

.mat-snack-bar-container {
  color: rgba(255, 255, 255, 0.7);
  background: #323232;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-simple-snackbar-action {
  color: yellow;
}

.mat-simple-snackbar {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}

.mat-simple-snackbar-action {
  line-height: 1;
  font-family: inherit;
  font-size: inherit;
  font-weight: 500;
}

.item-image-selection-container-image {
  height: 310px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 1px 0 0 #DFE1E5;
}

.fancy-input .fancy-input-container-unit {
  cursor: default;
}
.fancy-input--error .fancy-input-container {
  border-color: #D10931 !important;
}
.fancy-input--disabled .fancy-input-label, .fancy-input--disabled .fancy-input-container-unit {
  color: #DFE1E5 !important;
}
.fancy-input--disabled .fancy-input-container {
  background-color: #F3F4F5 !important;
  color: #DFE1E5 !important;
}

.overlay-cart-item-comment-top-icon {
  cursor: pointer;
}

.button-icon:not(:disabled):hover .button-icon-tooltip-wide {
  width: 300px;
  left: -77px;
}

.navigation-cart-disabled {
  color: #BABDC2;
  cursor: not-allowed;
}
.navigation-cart-disabled:hover {
  color: #BABDC2;
}

.material-filters {
  gap: 3rem;
}

.filter-container {
  width: 100%;
}

.text-area--error textarea {
  border-color: #D10931;
}

.mat-tab-label-container {
  border: 1px solid #DFE1E5;
}

.mat-tab-header {
  border: none;
}

.mat-tab-label {
  width: 50%;
  font-size: 18px;
}
.mat-tab-label.mat-tab-label-active {
  color: #D10931;
}

.navigation-search {
  transition: max-width 0.4s ease;
  background-color: #FFFFFF;
  z-index: 4;
}
.navigation-search.focus {
  max-width: 100%;
}
.navigation-search.focus .navigation-search-clear {
  display: block !important;
}
.navigation-search.focus .navigation-search-submit {
  left: 0;
  right: auto;
}
.navigation-search.focus input {
  padding-left: 50px;
}
.navigation-search .navigation-search-clear {
  color: #D10931 !important;
  display: none !important;
}
.navigation-search .navigation-search-clear-mobile {
  display: none;
}

.live-search {
  background-color: #FFFFFF;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 128px);
  height: calc(100svh - 128px);
  left: 0;
  max-height: 0;
  opacity: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 20px;
  transition: max-height 0.4s ease, opacity 0.2s ease;
  z-index: 1000;
}

.open .live-search {
  max-height: 820px;
  opacity: 1;
  padding: 20px;
}

.live-search-mobile, .live-search-mobile-divider {
  display: none;
}

.live-search-all-link-mobile {
  color: #D10931;
  display: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;
}
.live-search-all-link-mobile i {
  font-size: 24px;
  margin-left: 8px;
}

.live-search-result-info {
  color: #84878F;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid #DFE1E5;
}
.live-search-result-info strong {
  color: #444952;
}

.live-search-header {
  align-items: center;
  border-bottom: 1px solid #DFE1E5;
  color: #84878F;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: space-between;
  line-height: 20px;
  padding: 36px 0 8px;
}

.live-search-header-right {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.live-search-header-right-link {
  color: #D10931;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 40px;
}
.live-search-header-right-link i {
  font-size: 24px;
  margin-left: 8px;
}

.live-search-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DFE1E5;
  padding: 20px 0;
}

.live-search-item-image {
  border: 1px solid #DFE1E5;
  margin-right: 20px;
}

.live-search-item-container {
  flex-grow: 1;
  position: relative;
}
.live-search-item-container:after {
  color: #D10931;
  content: "\e908";
  font-family: "iconfont-ehg";
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.live-search-item-result-info {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
}

.live-search-item-title {
  color: #444952;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  max-width: 60%;
}

.live-search-item-description {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #84878F;
  display: -webkit-box;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  max-width: 70%;
  overflow: hidden;
}
.live-search-item-description.live-search-item-breadcrumb {
  color: #84878F;
  margin-bottom: 2px;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
}
.live-search-item-description .live-search-item-devider {
  margin: 0 6px;
}

.live-search-item-link {
  color: #444952;
  direction: rtl;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  max-width: 60%;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.live-search-item-link:before {
  border-bottom: 1px solid #444952;
  bottom: 5px;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
}

.search-no-results {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 272px;
  margin: auto;
  max-width: 555px;
  width: 100%;
}

.mat-tab-body-content .search-no-results {
  margin: 50px auto;
}

.search .live-search-item {
  transition: all 0.4s ease;
  padding: 20px;
}
.search .live-search-item:hover {
  background-color: #F3F4F5;
}
.search .live-search-item:hover .search-item-show-more {
  opacity: 1;
}

.search-no-results-avatar {
  background-color: #F3F4F5;
  border-radius: 100%;
  height: 140px;
  margin-bottom: 40px;
  position: relative;
  width: 140px;
}
.search-no-results-avatar i {
  color: #BABDC2;
  font-size: 88px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.search-no-results-info {
  text-align: center;
}

.search-no-results-info-title {
  font-weight: 700;
}

.search-no-results-info-description, .search-no-results-info-title {
  color: #84878F;
  font-size: 20px;
  line-height: 28px;
}

@media screen and (max-width: 1024px) {
  .open .live-search {
    max-height: max-content;
    display: flex;
    opacity: 1;
  }
  .live-search {
    box-shadow: none;
    display: none;
    opacity: 0;
    padding: 24px 32px;
    transition: opacity 0.4s ease;
    z-index: 10;
    width: 100vw;
    left: -32px;
    top: 0;
  }
  .live-search-result-info {
    display: none;
  }
  .live-search-mobile-divider {
    border-bottom: 1px solid #DFE1E5;
    display: block;
    margin-top: -20px;
  }
  .live-search-header {
    padding: 24px 0 8px;
  }
  .live-search-header-right-link {
    display: none;
  }
  .live-search-item {
    display: flex;
  }
  .live-search-item-container {
    width: 100%;
  }
  .live-search-item-description {
    -webkit-line-clamp: 3;
  }
  .live-search-item-description, .live-search-item-link, .live-search-item-title {
    max-width: 80%;
  }
  .live-search-all-link-mobile {
    display: flex;
  }
  .navigation-search.focus .navigation-search-clear {
    display: none !important;
  }
  .navigation-search.focus .navigation-search-clear-mobile {
    align-items: center;
    color: #D10931;
    display: flex;
    height: 48px;
    justify-content: flex-end;
    position: absolute;
    right: -110px;
    top: 50%;
    transform: translateY(-50%);
    width: 80px;
    z-index: 10;
    font-size: 16px;
  }
  .navigation-language-chooser.focus {
    opacity: 0;
  }
}
@media screen and (max-width: 600px) {
  .mat-tab-label-container {
    border: none;
    border-bottom: 1px solid #DFE1E5;
  }
  .mat-tab-body-content {
    padding: 0 16px;
  }
  .live-search {
    left: -16px;
    padding: 24px 0;
  }
}
.item-comment-container {
  margin-top: 1rem;
  background-color: #f3f4f5;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 40.9090909091%;
}
.item-comment-container .title {
  display: flex;
  gap: 0.5rem;
}
.item-comment-container .title p {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
}
.item-comment-container .title .icon-add-comment {
  font-size: 18px;
  color: #D10931;
  text-align: center;
}
.item-comment-container .call-to-action {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #D10931;
  cursor: pointer;
  text-align: start;
}

@media screen and (max-width: 1200px) {
  .item-comment-container {
    width: 100%;
  }
}
.checkbox.configuration-checkbox input[type=checkbox] {
  display: inline-block;
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.checkbox.configuration-checkbox input[type=checkbox]:disabled + .checkbox-title {
  cursor: default;
  color: #BABDC2;
}
.checkbox.configuration-checkbox input[type=checkbox]:disabled + .checkbox-title::after {
  background-color: #BABDC2;
  border-color: #BABDC2;
}
.checkbox.configuration-checkbox input[type=checkbox]:focus ~ .checkbox-title::after {
  outline: Highlight solid 2px;
}
.checkbox.configuration-checkbox input[type=checkbox] + .checkbox-title {
  font-weight: unset;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .checkbox.configuration-checkbox input[type=checkbox]:focus ~ .checkbox-title::after {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}
.registration {
  width: 100%;
  position: relative;
}
.registration-add-user {
  display: block;
  width: 100%;
  padding: 24px 20px;
  background-color: #FFFFFF;
  margin-bottom: 12px;
}
.registration-description {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}
.registration-description-title {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  color: #2B2F38;
  margin-bottom: 8px;
}
.registration-description-text {
  font-size: 16px;
  line-height: 24px;
  max-width: 765px;
  color: #84878F;
}

@media screen and (max-width: 600px) {
  .registration-add-user {
    padding: 20px 16px;
    margin-bottom: 20px;
  }
  .registration-description {
    padding: 16px;
  }
}
.registration-form {
  width: 100%;
  background-color: #FFFFFF;
  padding: 20px;
  position: relative;
  margin-bottom: 20px;
}
.registration-form-title {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #2B2F38;
  margin-bottom: 28px;
}
.registration-form-content {
  display: flex;
  flex-direction: column;
}
.registration-form-content-row {
  display: flex;
  margin-top: 20px;
}
.registration-form-content-field {
  max-width: 305px;
  width: 100%;
}
.registration-form-content-field-label {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-bottom: 8px;
}
.registration-form-content-field + .registration-form-content-field {
  margin-left: 20px;
}
.registration-form-content-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
}
.registration-form-content-buttons button,
.registration-form-content-buttons a {
  width: 120px;
}
.registration-form-content-buttons button:last-child,
.registration-form-content-buttons a:last-child {
  margin-left: 12px;
}
.registration-form-checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 20px;
}
.registration-form-checkbox span.checkbox-error {
  color: #E31717;
}
.registration-form-checkbox a {
  text-decoration: underline;
}
.registration-form-checkbox .checkbox {
  margin-top: -12px;
}
.registration-form-error {
  color: #E31717;
}

@media screen and (max-width: 600px) {
  .registration-form {
    padding: 16px 16px 20px;
  }
  .registration-form-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .registration-form-content-row {
    flex-direction: column;
    margin-top: 0;
  }
  .registration-form-content-field {
    max-width: none;
    margin-top: 20px;
  }
  .registration-form-content-field + .registration-form-content-field {
    margin-left: 0;
  }
  .registration-form-content-field--empty {
    margin-top: 0;
  }
  .registration-form-content-buttons {
    justify-content: initial;
  }
  .registration-form-content-buttons button,
.registration-form-content-buttons a {
    width: 100%;
  }
}
.registration-item {
  width: 100%;
  position: relative;
  padding: 20px;
  background-color: #FFFFFF;
  margin-bottom: 12px;
}
.registration-item-icon-container {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
}
.registration-item-icon {
  margin-right: 12px;
}
.registration-item-icon:last-child {
  margin-right: 0;
}
.registration-item-info-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.registration-item-info-left {
  max-width: 305px;
  width: 100%;
}
.registration-item-info-right {
  width: calc(100% - 305px);
}
.registration-item-info-title {
  font-size: 14px;
  line-height: 20px;
  color: #84878F;
  margin-bottom: 4px;
}
.registration-item-info-value {
  font-size: 16px;
  line-height: 24px;
  color: #2B2F38;
}
.registration-item-info-value + .registration-item-info-value {
  margin-top: 4px;
}
.registration-item-info-value + .registration-item-info-title {
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .registration-item {
    padding: 16px 16px 24px;
    margin-bottom: 20px;
  }
  .registration-item-title {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
  }
  .registration-item-info-container {
    flex-direction: column;
    flex-wrap: initial;
  }
  .registration-item-info-left, .registration-item-info-right {
    width: 100%;
  }
  .registration-item-info-right {
    margin-top: 40px;
  }
}
.shape-overview-table-content-inner {
  width: 100%;
}

.shape-overview-table table th {
  white-space: unset;
}

@media screen and (max-width: 768px) {
  .shape-overview .filter-overlay .shape-overview-filters {
    display: flex;
  }
  .shape-overview-table table {
    min-width: 500px;
  }
  .shape-overview-table-content-inner {
    overflow: auto;
  }
}
.filter-overlay-footer > * {
  width: calc(50% - 5px);
  flex: 0 0 auto;
}

.header-title-small {
  font-size: 18px;
  line-height: 24px;
}

@media screen and (max-width: 768px) {
  .article-page .header-bottom {
    flex-direction: column;
  }
  .article-page .header-favorite-button {
    width: 100%;
    margin-left: 0;
    margin-top: 10px;
  }
  .article-page .header-favorite-button-title {
    font-size: inherit;
  }
}
.material-list-product-link:hover i.icon-check {
  color: #2B2F38;
}

.profile-offer-overview-item .text-disabled {
  color: #BABDC2;
}

/* =================================================
        Fonts.
================================================= */
@font-face {
  font-family: "Akkurat Pro";
  src: url("/assets/fonts/akkurat-pro/AkkuratPro-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Akkurat Pro";
  src: url("/assets/fonts/akkurat-pro/AkkuratPro-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "iconfont-ehg";
  src: url("/assets/fonts/iconfont/fonts/iconfont-ehg.eot?hfgtrew");
  src: url("/assets/fonts/iconfont/fonts/iconfont-ehg.eot?hfgtrew#iefix") format("embedded-opentype"), url("/assets/fonts/iconfont/fonts/iconfont-ehg.ttf?hfgtrew") format("truetype"), url("/assets/fonts/iconfont/fonts/iconfont-ehg.woff?hfgtrew") format("woff"), url("/assets/fonts/iconfont/fonts/iconfont-ehg.svg?hfgtrew#iconfont-ehg") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon-], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "iconfont-ehg" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home-icon-1:before {
  content: "\e96a";
}

.icon-zoom-out:before {
  content: "\e968";
}

.icon-zoom-in:before {
  content: "\e969";
}

.icon-share:before {
  content: "\e965";
}

.icon-thumbs-up:before {
  content: "\e966";
}

.icon-youtube:before {
  content: "\e967";
}

.icon-add-comment:before {
  content: "\e900";
}

.icon-angle-down:before {
  content: "\e901";
}

.icon-angle-left:before {
  content: "\e902";
}

.icon-angle-right:before {
  content: "\e903";
}

.icon-angle-up:before {
  content: "\e904";
}

.icon-arrow-down:before {
  content: "\e905";
}

.icon-arrow-left:before {
  content: "\e906";
}

.icon-arrow-right-up:before {
  content: "\e907";
}

.icon-arrow-right:before {
  content: "\e908";
}

.icon-arrow-up:before {
  content: "\e909";
}

.icon-auftragspositionen:before {
  content: "\e90a";
}

.icon-auslieferung:before {
  content: "\e90b";
}

.icon-blech:before {
  content: "\e90c";
  color: #babdc2;
}

.icon-book-open:before {
  content: "\e90d";
}

.icon-calendar:before {
  content: "\e90e";
}

.icon-carcoclix:before {
  content: "\e90f";
}

.icon-certificate:before {
  content: "\e910";
}

.icon-chat:before {
  content: "\e911";
}

.icon-check-rounded:before {
  content: "\e912";
}

.icon-check:before {
  content: "\e913";
}

.icon-checkbox-alt:before {
  content: "\e914";
}

.icon-checkbox:before {
  content: "\e915";
}

.icon-clock:before {
  content: "\e916";
}

.icon-close:before {
  content: "\e917";
}

.icon-compress:before {
  content: "\e918";
}

.icon-download:before {
  content: "\e919";
}

.icon-e-procurement:before {
  content: "\e91a";
}

.icon-ecommerce-dollar:before {
  content: "\e91b";
}

.icon-edit:before {
  content: "\e91c";
}

.icon-envelope:before {
  content: "\e91d";
}

.icon-events:before {
  content: "\e91e";
}

.icon-expand:before {
  content: "\e91f";
}

.icon-external-link:before {
  content: "\e920";
}

.icon-facebook:before {
  content: "\e921";
}

.icon-fahrtkostenverguetung:before {
  content: "\e922";
}

.icon-file-download:before {
  content: "\e923";
}

.icon-file-pdf:before {
  content: "\e924";
}

.icon-filter-v2:before {
  content: "\e925";
}

.icon-filter:before {
  content: "\e926";
}

.icon-flach:before {
  content: "\e927";
  color: #babdc2;
}

.icon-fliessbohren:before {
  content: "\e928";
}

.icon-font-size:before {
  content: "\e929";
}

.icon-gesundheitfoerderung:before {
  content: "\e92a";
}

.icon-gewindeschneiden:before {
  content: "\e92b";
}

.icon-graph:before {
  content: "\e92c";
}

.icon-gruendung:before {
  content: "\e92d";
}

.icon-i-form:before {
  content: "\e92e";
  color: #babdc2;
}

.icon-info:before {
  content: "\e92f";
}

.icon-instagram:before {
  content: "\e930";
}

.icon-kantine:before {
  content: "\e931";
}

.icon-lagerflaeche:before {
  content: "\e932";
}

.icon-liefertermine:before {
  content: "\e933";
}

.icon-linkedin:before {
  content: "\e934";
}

.icon-lkw:before {
  content: "\e935";
}

.icon-lock:before {
  content: "\e936";
}

.icon-login:before {
  content: "\e937";
}

.icon-logistik:before {
  content: "\e938";
}

.icon-logout:before {
  content: "\e939";
}

.icon-map-marker:before {
  content: "\e93a";
}

.icon-materialbeschaffung:before {
  content: "\e93b";
}

.icon-medal:before {
  content: "\e93c";
}

.icon-menu:before {
  content: "\e93d";
}

.icon-minus:before {
  content: "\e93e";
}

.icon-oberflaechenbehandlung:before {
  content: "\e93f";
}

.icon-pen-1:before {
  content: "\e940";
}

.icon-pen:before {
  content: "\e941";
}

.icon-person:before {
  content: "\e942";
}

.icon-play:before {
  content: "\e943";
}

.icon-plus:before {
  content: "\e944";
}

.icon-preis-zuschlaege:before {
  content: "\e945";
}

.icon-profil-rohr:before {
  content: "\e946";
  color: #babdc2;
}

.icon-quote:before {
  content: "\e947";
}

.icon-rohrlasern:before {
  content: "\e948";
}

.icon-rund-rohr:before {
  content: "\e949";
  color: #babdc2;
}

.icon-rund:before {
  content: "\e94a";
  color: #babdc2;
}

.icon-saegen:before {
  content: "\e94b";
}

.icon-search:before {
  content: "\e94c";
}

.icon-sechskant:before {
  content: "\e94d";
  color: #babdc2;
}

.icon-settings-1:before {
  content: "\e94e";
}

.icon-settings:before {
  content: "\e94f";
}

.icon-shop-cart:before {
  content: "\e950";
}

.icon-sonderform:before {
  content: "\e951";
  color: #babdc2;
}

.icon-sortiment:before {
  content: "\e952";
}

.icon-star-filled:before {
  content: "\e953";
}

.icon-star:before {
  content: "\e954";
}

.icon-support:before {
  content: "\e955";
}

.icon-t-form:before {
  content: "\e956";
  color: #babdc2;
}

.icon-telephone:before {
  content: "\e957";
}

.icon-tourenzeiten:before {
  content: "\e958";
}

.icon-trash:before {
  content: "\e959";
}

.icon-u-form:before {
  content: "\e95a";
  color: #babdc2;
}

.icon-umsatz:before {
  content: "\e95b";
}

.icon-unlock:before {
  content: "\e95c";
}

.icon-upload:before {
  content: "\e95d";
}

.icon-user:before {
  content: "\e95e";
}

.icon-vierkant:before {
  content: "\e95f";
  color: #babdc2;
}

.icon-warning:before {
  content: "\e960";
}

.icon-weiterbildung:before {
  content: "\e961";
}

.icon-winkel-profile:before {
  content: "\e962";
  color: #babdc2;
}

.icon-winkel:before {
  content: "\e963";
  color: #babdc2;
}

.icon-world:before {
  content: "\e964";
}

.article-availability:hover .article-availability-tooltip {
  visibility: visible;
  opacity: 1;
}
.article-availability-tooltip {
  visibility: hidden;
  display: block;
  font-family: "Akkurat Pro", serif !important;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  width: 120px;
  background-color: #2B2F38;
  color: #FFFFFF;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}
.article-availability-tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #2B2F38 transparent transparent transparent;
}

@media screen and (max-width: 1024px) {
  .article-availability-tooltip {
    display: none;
  }
}
.profile-offer-detail-table-item-column-in-stock--no::after {
  background-color: #ffa86a;
}

.select-box-select select.disabled {
  background-color: #F3F4F5;
  color: #84878F;
  pointer-events: none;
}

.profile-user-management-form-content-field input[type=checkbox] {
  accent-color: #D10931;
}

.profile-user-management-item-reset-password-container {
  position: absolute;
  top: 111px;
  right: 20px;
  z-index: 1;
}

@media screen and (max-width: 600px) {
  .profile-user-management-item-reset-password-container {
    top: 256px;
  }
}