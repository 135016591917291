.simple-header {
    background-color: $colorGreyBarely;
    padding: 20px 40px;

    &-top {
        display: flex;
        flex-direction: row;
        height: 24px;
        align-self: flex-start;
        align-items: center;
    }

    &-back {
        @include fontBodyText;
        color: $colorPrimaryEnergeticRed;
        align-self: self-end;
    }

    &-arrow {
        margin-right: 5px;
        font-size: 20px;
        vertical-align: text-bottom;

        &::before {
            width: 16px;
            height: 12px;
        }
    }

    &-bottom {
        display: flex;
        flex-direction: row;
        padding-top: 20px;
        align-items: center;
    }

    &-title {
        @include fontH2Headline;
        margin-right: 16px;
    }
}

@include tabletMax {
    .simple-header {
        padding: 16px 32px;
    }
}

@include tabletSmallMax {
    .simple-header {
        &-title {
            @include fontH3Headline;
            margin-right: 20px;
        }

        &-bottom {
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 12px;
        }
    }
}

@include mobileLargeMax {
    .simple-header {
        padding: 16px;
    }
}
