.crumb {
    display: flex;
    flex-direction: row;
    white-space: nowrap;
    align-self: center;

    @include tabletSmallMax {
        display: none;
    }

    &:last-child {
        .crumb-title {
            color: $colorGrey;
            pointer-events: none;
        }

        .crumb-split {
            display: none;
        }
    }

    &-title {
        @include fontBodyText;
        color: $colorPrimaryEnergeticRed;
        margin-right: 5px;
    }

    &-split {
        color: $colorGrey;
        margin-right: 5px;
        align-self: center;
        font-size: 20px;

        &::before {
            height: 6px;
            width: 10px;
        }
    }
}
