.navigation-item-title {
  display: block;
}

.navigation-item-menu-link {
  cursor: pointer;
}

// remove if shop menu has sub menu
.navigation-shop-link {
  .navigation-item-title {
    padding-right: 20px;
  }

  &.navigation-item--menu::after {
    display: none;
  }
}

@media screen and (min-width: $tabletBreakpoint + 1px) {
  .cms-navigation-item:hover .navigation-item-menu,
  .navigation-item-hover .navigation-item-menu {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  // remove if shop menu has sub menu
  .navigation .navigation-item--menu.navigation-item--active::before {
    left: calc(50%);
  }
}

@media screen and (max-width: 1280px) {
  .navigation-button {
    padding: 20px 10px;
  }

  .navigation-cart-counter, .navigation-favorites-counter {
    right: 4px;
    height: 18px;
    width: 18px;
    font-size: 12px;
    line-height: 21px;
  }
}

@include desktopSmallMax {
  .navigation-item-title {
    padding: 22px 10px;
    font-size: 16px;
  }

  .navigation-item--menu {
    &::after {
      right: 8px;
    }

    &:nth-child(1)::after {
      right: 10px;
    }

    .navigation-item-title {
      padding-right: 32px;
    }
  }

  // change if shop menu has sub menu
  .navigation-item:nth-child(1) .navigation-item-title {
    padding-right: 15px;
  }

  .navigation-item:nth-child(2) .navigation-item-title {
    padding-left: 15px;
  }

  // remove if shop menu has sub menu
  .navigation .navigation-item--menu.navigation-item--active::before {
    left: calc(50% - 3px);
  }
}

.navigation-sales-rep {
  position: absolute;
  right: 590px;
  z-index: 1;

  span {
    margin-right: 20px;
  }
}

.navigation{

  &-logo {
    z-index: 3;

    img {
      z-index: 4;
      position: absolute;
    }

    &::before {
      content: '';
      position:absolute;
      width: 264px;
      height: 80px;
      left: -48px;
      top: -24px;
      background-color: $colorPrimaryClearlyWhite;
      z-index: 3;
    }
  }
}

@include tabletMax {
  .navigation-sales-rep {
    display: none;
  }

  .navigation-logo::before {
    width: 210px;
    height: 72px;
  }
}

.navigation-item--highlight .navigation-item-title {
  align-items: center;
  color: #d10931;
  display: flex;
  font-size: 18px;
  line-height: 18px;
}

@include tabletMin {
  .navigation-item--highlight-first .navigation-item-title:after {
    background-color: #dfe1e5;
    content: "";
    height: 32px;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
  }
}
