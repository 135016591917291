/* =================================================
        Fonts.
================================================= */

@font-face {
    font-family: 'Akkurat Pro';
    src: url('/assets/fonts/akkurat-pro/AkkuratPro-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Akkurat Pro';
    src: url('/assets/fonts/akkurat-pro/AkkuratPro-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@mixin fontTabText {
    font-size: 10px;
    line-height: 16px;
}

@mixin fontTabTextBold {
    font-size: 10px;
    line-height: 16px;
    font-weight: 700;
}

@mixin fontOverline {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

@mixin fontCaptionRegular {
    font-size: 12px;
    line-height: 16px;
}

@mixin fontCaptionBold {
    font-size: 12px;
    line-height: 16px;
    font-weight: 700;
}

@mixin fontBodySmallText {
    font-size: 14px;
    line-height: 20px;
}

@mixin fontBodySmallTextBold {
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
}

@mixin fontBodyText {
    font-size: 16px;
    line-height: 24px;
}

@mixin fontBodyBold {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}

@mixin fontBodyLargeText {
    font-size: 18px;
    line-height: 24px;
}

@mixin fontBodyLargeBold {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
}

@mixin fontH4HeadlineRegular {
    font-size: 20px;
    line-height: 28px;
}

@mixin fontH4Headline {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
}

@mixin fontH3Headline {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
}

@mixin fontH2Headline {
    font-size: 32px;
    line-height: 40px;
    font-weight: 700;
}

@mixin fontH1Headline {
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
}

@mixin fontDisplayFont {
    font-size: 48px;
    line-height: 56px;
    font-weight: 700;
}
