.text-area {
    textarea {
        @include clearStyle;
        width: 100%;
        padding: 12px 16px;
        max-width: 100%;
        border: 1px solid $colorGreyLight;
        font-family: $fontDefault;
        color: $colorPrimaryJustBlack;

        &::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder,
        &::-webkit-input-placeholder,
        &::-moz-placeholder {
            font-family: $fontDefault;
            color: $colorGrey;
            @include fontBodyText;
        }
    }

    &-label {
        @include fontBodySmallText;
        color: $colorGreyDark;
        margin-bottom: 4px;
    }
}
