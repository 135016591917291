.breadcrumb {
    display: flex;
    flex-direction: row;

    &-front {
        display: flex;
        flex-direction: row;
        height: 24px;
        align-self: flex-start;
        align-items: center;
    }

    &-back {
        @include fontBodyText;
        color: $colorPrimaryEnergeticRed;
        align-self: self-end;
    }

    &-arrow {
        margin-right: 5px;
        font-size: 20px;
        vertical-align: text-bottom;

        &::before {
            width: 16px;
            height: 12px;
        }
    }

    &-divider {
        border-right: 1px solid $colorGreyMid;
        margin-left: 11px;
        margin-right: 12px;
        height: 24px;
    }

    &-crumbs {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

@include tabletSmallMax {
    .breadcrumb-divider {
        border-right: 0;
    }
}
