.registration-item {
    width: 100%;
    position: relative;
    padding: 20px;
    background-color: $colorPrimaryClearlyWhite;
    margin-bottom: 12px;

    &-icon-container {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 1;
    }

    &-icon {
        margin-right: 12px;

        &:last-child {
            margin-right: 0;
        }
    }

    &-info-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &-info-left {
        max-width: 305px;
        width: 100%;
    }

    &-info-right {
        width: calc(100% - 305px);
    }

    &-info-title {
        @include fontBodySmallText;
        color: $colorGrey;
        margin-bottom: 4px;
    }

    &-info-value {
        @include fontBodyText;
        color: $colorPrimaryJustBlack;

        & + & {
            margin-top: 4px;
        }
    }

    &-info-value + &-info-title {
        margin-top: 20px;
    }
}

@include mobileLargeMax {
    .registration-item {
        padding: 16px 16px 24px;
        margin-bottom: 20px;

        &-title {
            @include fontH4Headline;
        }

        &-info-container {
            flex-direction: column;
            flex-wrap: initial;
        }

        &-info-left,
        &-info-right {
            width: 100%;
        }

        &-info-right {
            margin-top: 40px;
        }
    }
}
