.mat-tab-label-container {
  border: 1px solid $colorGreyLight;
}

.mat-tab-header {
  border: none;
}

.mat-tab-label {
  width: 50%;
  font-size: 18px;

  &.mat-tab-label-active {
    color: $colorPrimaryEnergeticRed;
  }
}

.navigation-search {
  transition: max-width .4s ease;
  background-color: $colorPrimaryClearlyWhite;
  z-index: 4;

  &.focus {
    max-width: 100%;

    .navigation-search-clear {
      display: block !important;
    }

    .navigation-search-submit {
      left: 0;
      right: auto;
    }

    input {
      padding-left: 50px;
    }
  }

  .navigation-search-clear {
    color: $colorPrimaryEnergeticRed !important;
    display: none !important;
  }

  .navigation-search-clear-mobile {
    display: none;
  }
}

.live-search {
  background-color: $colorPrimaryClearlyWhite;
  box-shadow: 0 2px 16px rgba(0, 0, 0, .16);
  display: flex;
  flex-direction: column;
  height: calc(100vh - 128px); // fallback for older browsers
  height: calc(100svh - 128px);
  left: 0;
  max-height: 0;
  opacity: 0;
  overflow: auto;
  position: absolute;
  right: 0;
  top: 20px;
  transition: max-height .4s ease, opacity .2s ease;
  z-index: 1000
}

.open .live-search {
  max-height: 820px;
  opacity: 1;
  padding: 20px;
}

.live-search-mobile, .live-search-mobile-divider {
  display: none
}

.live-search-all-link-mobile {
  color: $colorPrimaryEnergeticRed;
  display: none;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: 16px;

  i {
    font-size: 24px;
    margin-left: 8px
  }
}

.live-search-result-info {
  color: $colorGrey;
  font-size: 20px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 20px;
  padding-bottom: 5px;
  border-bottom: 1px solid $colorGreyLight;

  strong {
    color: $colorGreyDark;
  }
}

.live-search-header {
  align-items: center;
  border-bottom: 1px solid $colorGreyLight;
  color: $colorGrey;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  justify-content: space-between;
  line-height: 20px;
  padding: 36px 0 8px
}

.live-search-header-right {
  align-items: center;
  display: flex;
  flex-direction: row
}

.live-search-header-right-link {
  color: $colorPrimaryEnergeticRed;
  display: flex;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-left: 40px;

  i {
    font-size: 24px;
    margin-left: 8px
  }
}

.live-search-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $colorGreyLight;
  padding: 20px 0
}

.live-search-item-image {
  border: 1px solid $colorGreyLight;
  margin-right: 20px;
}

.live-search-item-container {
  flex-grow: 1;
  position: relative;

  &:after {
    color: $colorPrimaryEnergeticRed;
    content: "\e908";
    font-family: $iconFont;
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%)
  }
}

.live-search-item-result-info {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px
}

.live-search-item-title {
  color: $colorGreyDark;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  max-width: 60%
}

.live-search-item-description {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: $colorGrey;
  display: -webkit-box;
  font-size: 14px;
  line-height: 20px;
  margin-top: 8px;
  max-width: 70%;
  overflow: hidden;

  &.live-search-item-breadcrumb {
    color: $colorGrey;
    margin-bottom: 2px;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 4px;
  }

  .live-search-item-devider {
    margin: 0 6px;
  }
}

.live-search-item-link {
  color: $colorGreyDark;
  direction: rtl;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  max-width: 60%;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:before {
    border-bottom: 1px solid $colorGreyDark;
    bottom: 5px;
    content: "";
    left: 0;
    position: absolute;
    right: 0
  }
}

.search-no-results {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 272px;
  margin: auto;
  max-width: 555px;
  width: 100%
}

.mat-tab-body-content .search-no-results {
  margin: 50px auto;
}

.search .live-search-item {
  transition: all .4s ease;
  padding: 20px;

  &:hover {
    background-color: $colorGreyBarely;

    .search-item-show-more {
      opacity: 1;
    }
  }
}

.search-no-results-avatar {
  background-color: $colorGreyBarely;
  border-radius: 100%;
  height: 140px;
  margin-bottom: 40px;
  position: relative;
  width: 140px;

  i {
    color: $colorGreyMid;
    font-size: 88px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%)
  }
}

.search-no-results-info {
  text-align: center
}

.search-no-results-info-title {
  font-weight: 700
}

.search-no-results-info-description, .search-no-results-info-title {
  color: $colorGrey;
  font-size: 20px;
  line-height: 28px
}

@media screen and (max-width: $tabletBreakpoint) {
  .open .live-search {
    max-height: max-content;
    display: flex;
    opacity: 1
  }

  .live-search {
    box-shadow: none;
    display: none;
    opacity: 0;
    padding: 24px 32px;
    transition: opacity .4s ease;
    z-index: 10;
    width: 100vw;
    left: -32px;
    top: 0;
  }

  .live-search-result-info {
    display: none;
  }

  .live-search-mobile-divider {
    border-bottom: 1px solid $colorGreyLight;
    display: block;
    margin-top: -20px
  }

  .live-search-header {
    padding: 24px 0 8px
  }

  .live-search-header-right-link {
    display: none
  }

  .live-search-item {
    display: flex
  }

  .live-search-item-container {
    width: 100%
  }

  .live-search-item-description {
    -webkit-line-clamp: 3
  }

  .live-search-item-description, .live-search-item-link, .live-search-item-title {
    max-width: 80%
  }

  .live-search-all-link-mobile {
    display: flex
  }

  .navigation-search.focus {
    .navigation-search-clear {
      display: none !important;
    }

    .navigation-search-clear-mobile {
      align-items: center;
      color: $colorPrimaryEnergeticRed;
      display: flex;
      height: 48px;
      justify-content: flex-end;
      position: absolute;
      right: -110px;
      top: 50%;
      transform: translateY(-50%);
      width: 80px;
      z-index: 10;
      font-size: 16px;
    }
  }

  .navigation-language-chooser.focus {
    opacity: 0;
  }
}

@media screen and (max-width: $mobileLargeBreakpoint) {
  .mat-tab-label-container {
    border: none;
    border-bottom: 1px solid $colorGreyLight;
  }

  .mat-tab-body-content {
    padding: 0 16px;
  }

  .live-search {
    left: -16px;
    padding: 24px 0;
  }
}
