.checkbox-list {
  // Remove bottom margin from last checkbox.
  margin-bottom: -12px;

  &-title {
    @include fontBodySmallTextBold;
    color: $colorGrey;
    line-height: 32px;
    margin-bottom: 9px;
  }

  &-children-container {
    display: none;
    padding-left: 34px;
  }

  &--open {
    display: block;
  }
}

@include tabletSmallMax {
  .checkbox-list-title {
    @include fontBodyLargeBold;
    color: $colorPrimaryJustBlack;
  }
}

.checkbox-list-title-container {
  display: flex;
  gap: 2.5rem;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
