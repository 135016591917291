html, * {
  scroll-behavior: smooth;
  font-family: $fontDefault !important;
}

main {
  min-height: 540px;
}

.hidden {
  visibility: hidden;
}

.mat-checkbox-inner-container {
  height: 20px !important;
  width: 20px !important;

  .mat-checkbox-frame {
    border-width: 1px !important;
  }
}

.button-primary, .button-secondary {
  &:disabled {
    cursor: not-allowed;
    color: $colorGreyLight;
    background-color: $colorGreyBarely;
    &:hover {
      background-color: $colorGreyBarely;
    }
  }
}

.mat-expansion-panel {
  display: inline !important;
}

.cdk-global-overlay-wrapper > .cdk-overlay-pane {
  padding: 0 !important;
}

mat-datepicker-input {
  height: 24px;
}

.display-flex {
  display: flex;
}
.display-none {
  display: none;
}

.app-loading {
  opacity: 0.5;
  pointer-events: none;
}
