.shop-detail-configuration {
  width: 100%;
  padding: 0;

  &-container {
    margin-top: 0;
  }
}

app-article-detail-configuration {
  width: 50%;
  padding: 10px;
}

@include tabletMax {
  app-article-detail-configuration {
    width: 100%;
    padding: 0;
  }

  .shop-detail-configuration {
    &-container {
      display: block;
    }
  }
}

@include tabletSmallMax {
  app-article-detail-configuration {
    width: 100%;
  }
}
