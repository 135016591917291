.item-image-selection {
  &-container {
    &-image {
      height: 310px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 0 1px 0 0 $colorGreyLight;
    }
  }
}
