.profile-carts-item {
    width: 100%;
    position: relative;
    padding: 20px 20px 24px;
    background-color: $colorPrimaryClearlyWhite;
    margin-bottom: 20px;

    &-title-container {
        display: inline-block;
        cursor: pointer;
        margin-bottom: 8px;
    }

    &-title {
        @include fontH3Headline;
        color: $colorPrimaryJustBlack;
        margin-top: 4px;
    }

    &-icon-container {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    &-icon {
        margin-right: 12px;

        &:last-child {
            margin-right: 0;
        }
    }

    &-info-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    &-info {
        width: calc(100% - 305px);
        margin-top: 20px;

        &:nth-child(odd) {
            max-width: 305px;
            width: 100%;
        }

        &-title {
            @include fontBodySmallText;
            color: $colorGrey;
            margin-bottom: 4px;
        }

        &-value {
            @include fontBodyText;
            color: $colorPrimaryJustBlack;
        }
    }
}

@include mobileLargeMax {
    .profile-carts-item {
        padding: 18px 16px 72px;

        &-title {
            @include fontH4Headline;
        }

        &-icon-container {
            top: unset;
            bottom: 20px;
            right: 20px;
        }

        &-info-container {
            flex-direction: column;
            flex-wrap: initial;
        }

        &-info {
            width: 100%;

            &:nth-child(odd) {
                width: 100%;
            }
        }
    }
}
