.item-comment-container {
  margin-top: 1rem;
  background-color: #f3f4f5;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: calc(100% * ((520 + 20) / 1320));

  .title {
    display: flex;
    gap: 0.5rem;

    p {
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
    }

    .icon-add-comment {
      font-size: 18px;
      color: #D10931;
      text-align: center;
    }
  }

  .call-to-action {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #D10931;
    cursor: pointer;
    text-align: start;
  }
}

@include desktopSmallMax {
  .item-comment-container {
    width: 100%;
  }
}
