.search {
  margin: 0 auto;
  max-width: 940px;
  padding-bottom: 120px;
  padding-top: 80px
}

.search-header {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 70px;
}

.search-header-title {
  color: $colorPrimaryJustBlack;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  margin-bottom: 16px
}

.search-header-result-title {
  color: $colorPrimaryJustBlack;
  font-size: 20px;
  line-height: 28px
}

.search-item {
  background-color: $colorPrimaryClearlyWhite;
  border-bottom: 1px solid $colorGreyLight;
  padding: 20px;
  transition: background-color .4s ease;
  display: flex;
  align-items: center;

  &:first-of-type {
    margin-top: 80px
  }

  &:hover {
    background-color: $colorGreyBarely;

    .search-item-show-more {
      opacity: 1
    }
  }
}

.search-item-container {
  position: relative;
  flex-grow: 1;

  &:after {
    color: $colorPrimaryEnergeticRed;
    content: "\e908";
    font-family: $iconFont;
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%)
  }
}

.search-item-title {
  color: $colorGreyDark;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  max-width: 540px
}

.search-item-description {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: $colorGrey;
  display: -webkit-box;
  font-size: 14px;
  line-height: 20px;
  margin-top: 4px;
  max-width: 440px;
  overflow: hidden
}

.search-item-link {
  color: $colorGreyDark;
  direction: rtl;
  display: inline-block;
  font-size: 16px;
  line-height: 24px;
  margin-top: 12px;
  max-width: 540px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:before {
    border-bottom: 1px solid $colorGreyDark;
    bottom: 5px;
    content: "";
    left: 0;
    position: absolute;
    right: 0
  }
}

.search-item-show-more {
  color: $colorPrimaryEnergeticRed;
  font-size: 16px;
  line-height: 24px;
  opacity: 0;
  position: absolute;
  right: 48px;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity .4s ease
}

.side-padding {
  padding-left: 40px;
  padding-right: 40px
}

@include tabletMax {
  .side-padding {
    padding-left:32px;
    padding-right: 32px
  }

  .search-item-description {
    max-width: 300px;
  }
}

@media screen and (max-width: 800px) {
  .search-item:hover .search-item-show-more {
    display: none;
  }

  .search-item-description,
  .search-item-link,
  .search-item-title {
    margin-right: 40px;
  }
}

@include mobileLargeMax {
  .search {
    padding-bottom: 60px;
    padding-top: 40px;
  }

  .search-header {
    margin-bottom: 20px;
  }

  .search-header-title {
    font-size: 32px;
    font-weight: 700;
    line-height: 40px;
  }

  .search-header-result-info {
    font-size: 18px;
    line-height: 24px;
  }

  .search-item {
    padding: 20px 0;
  }

  .search-item-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }

  .search-item-description {
    font-size: 14px;
    line-height: 20px;
  }

  .search-item-description,
  .search-item-link,
  .search-item-title {
    margin-right: 0;
    max-width: calc(100% - 40px);
  }

  .search-no-results-info-title {
    font-weight: 700;
  }

  .search-no-results-info-description,
  .search-no-results-info-title {
    font-size: 18px;
    line-height: 24px;
  }

  .side-padding {
    padding-left:16px;
    padding-right: 16px
  }
}
