.shipping-page {
    background-color: $colorGreyBarely;
    padding: 20px 40px 80px;

    &-content {
        display: flex;
        align-items: flex-start;

        &-settings {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }

        .shipping-options {
            margin-top: 20px;
        }

        .order-summary {
            flex: 0 0 auto;
            margin-left: 20px;
        }
    }
}

@include desktopMax {
    .shipping-page .address-selection-items {
        grid-template-columns: repeat(2, 1fr);
    }
}

@include tabletMax {
    .shipping-page {
        padding: 12px 32px 60px;
    }

    .shipping-page-content {
        flex-wrap: wrap;

        &-settings {
            width: 100%;
            flex: 0 0 auto;
        }

        .order-summary {
            margin-left: 0;
            margin-top: 20px;
            width: 100%;
            padding: 0;

            &-title,
            &-items,
            &-sum {
                display: none;
            }

            &-button {
                margin-top: 0;
            }
        }
    }
}

@include mobileLargeMax {
    .shipping-page {
        padding: 12px 16px 60px;
    }
}
