.thank-you-page {
    height: 100%;
    padding: 0 40px 80px;
    background-color: $colorGreyBarely;
}

@include tabletMax {
    .thank-you-page {
        padding: 0 32px 60px;
    }
}

@include mobileLargeMax {
    .thank-you-page {
        padding: 0 16px 60px;
    }
}
