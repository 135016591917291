.material-overview {
  background-color: $colorPrimaryClearlyWhite;
  margin-top: 40px;
  flex: 1 1 auto;

  &-table {
    width: 100%;
  }

  &-table-headline {
    @include fontBodySmallText;
    border-bottom: 1px solid $colorGreyLight;
    color: $colorGrey;
    text-align: left;

    &-title {
      padding-left: 20px;
      width: 40%;
    }

    @include desktopSmallMax {
      &-number {
        padding-right: 16px;
      }

      &-shapes {
        display: none;
      }
    }
  }

  th:last-child,
  td:last-child {
    padding-right: 90px;
  }
}
