.shop-detail-overview {
    background-color: $colorGreyBarely;
    padding: 20px 40px 40px;

    &-content {
        display: flex;
        flex-wrap: wrap;
        margin: -10px; // Compensate for padding of information, configuration and summary columns.
    }
}

@include tabletMax {
    .shop-detail-overview {
        padding: 20px 32px 0;

        &-content {
            flex-direction: column;
            padding-bottom: 128px; // 128px = 48px cart button height + 40px spacing top + 40px spacing bottom.
            margin: 0;
        }
    }
}

@include mobileLargeMax {
    .shop-detail-overview {
        padding: 0 16px;

        &-content {
            padding-bottom: 80px; // 80px = 48px cart button height + 16px spacing top + 16px spacing bottom.
        }
    }
}
