.profile-factory-certificates-list-item-sub {
    position: relative;
    border-bottom: 1px solid $colorGreyLight;
    cursor: pointer;

    &:not(.profile-factory-certificates-list-item-sub--open) {
        display: none;
    }

    > td {
        padding: 12px 0;
        vertical-align: middle;

        &:first-child {
            padding-left: 54px;
        }
    }

    &-center {
        display: flex;
        align-items: center;
    }

    &-file {
        @include fontBodySmallText;
        color: $colorGrey;

        > span {
            position: relative;
        }

        > span + span {
            padding-left: 10px;
            margin-left: 4px;

            // Display dot.
            &::after {
                content: '';
                position: absolute;
                width: 2px;
                height: 2px;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                border-radius: 50%;
                background-color: $colorGrey;
            }
        }
    }

    &-title {
        &-text {
            @include fontBodyText;
            color: $colorGreyDark;
            margin-right: 12px;
        }

        &-icon {
            font-size: 20px;
            color: $colorPrimaryEnergeticRed;
            margin-bottom: 1px;
            margin-right: 6px;

            &.icon-download {
                display: none;
            }
        }
    }

    &--open {
        .profile-factory-certificates-list-item-sub-active {
            display: block;
        }
    }

    &:hover {
        background-color: $colorGreyBarely;
        transition: background-color 0.5s ease;
        cursor: pointer;

        .profile-factory-certificates-list-item-sub-title-icon,
        .profile-factory-certificates-list-item-sub-title-text,
        .profile-factory-certificates-list-item-sub-file {
            color: $colorPrimaryEnergeticRed;
            transition: color 0.5s ease;
        }

        .profile-factory-certificates-list-item-sub-file span::after {
            background-color: $colorPrimaryEnergeticRed;
            transition: background-color 0.5s ease;
        }
    }
}

@include tabletMax {
    .profile-factory-certificates-list-item-sub-title-icon.icon-download {
        display: block;
    }
}
