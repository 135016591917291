.data-sheet {
    display: flex;
    flex-direction: row;
    height: 76px;
    width: 100%;
    align-items: center;
    padding: 16px 0;
    justify-content: space-between;

    &-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        overflow: hidden;
    }

    &-pdf {
        font-size: 40px;
        color: $colorPrimaryEnergeticRed;
    }

    &-information {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        margin-left: 8px;
        margin-right: 16px;
        overflow: hidden;

        &-title {
            @include fontBodyBold;
            color: $colorGreyDark;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &-sub {
            display: flex;
            flex-direction: row;
            @include fontCaptionBold;
            color: $colorGreyMid;

            &-info-point {
                padding: 0 5px;
            }
        }
    }
}
