.shop-detail-configuration {
    display: block;
    width: 50%;
    padding: 10px;

    &-title {
        @include fontH4Headline;
        color: $colorPrimaryJustBlack;

        &::before {
            display: none;
            position: absolute;
            top: 18px;
            left: 12px;
            color: $colorPrimaryEnergeticRed;
            content: $icon-angle-right;
            font-family: $iconFont;
            font-size: 24px;
            pointer-events: none;
            transition: transform 0.4s ease;
        }

        &--open {
            &::before {
                transform: rotate(90deg);
            }
        }
    }

    &-container {
        margin-top: 20px;
        background-color: $colorPrimaryClearlyWhite;
        padding: 20px;
        display: block;
    }

    &-container-step {
        padding-bottom: 28px;

        &:not(:last-child) {
            border-bottom: 1px solid $colorGreyLight;
            margin-bottom: 20px;
        }

        &-counter,
        &-input {
            width: 140px;
        }

        &-select {
            width: 280px;
        }

        &-cut-types {
            display: none;

            &--open {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
    }

    &-container-step-content:not(:first-child) {
        margin-top: 20px;
    }
}

@include tabletMax {
    .shop-detail-configuration {
        background-color: $colorPrimaryClearlyWhite;
        width: 100%;
        padding: 0;
        margin-bottom: 2px;

        &-title {
            padding: 18px 40px 20px;
            cursor: pointer;

            &::before {
                display: block;
                top: 16px;
            }
        }

        &-container {
            margin-top: 0;
            padding: 0 16px 12px;
            display: none;

            &--open {
                display: block;
            }
        }
    }
}

@include mobileLargeMax {
    .shop-detail-configuration-title {
        padding: 16px 16px 16px 40px;
    }
}

@include tabletSmallMax {
    .shop-detail-configuration {
        width: 100%;

        &-container-step-cut-types {
            display: none;

            &--open {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
            }
        }
    }

    .shop-detail-configuration-container-step-counter,
    .shop-detail-configuration-container-step-input {
        width: 160px;
    }
}

@include mobileSmallMax {
    .shop-detail-configuration-container-step-select {
        width: 100%;
    }
}
