.fancy-input {
    &-label {
        @include fontBodySmallText;
        color: $colorGreyDark;
        margin-bottom: 4px;
    }

    &-container {
        border: 1px solid $colorGreyLight;
        display: flex;
        height: 48px;
        align-items: center;
        padding: 12px;
        flex: 1 1 auto;
        @include fontBodyText;

        label {
            display: flex;
            align-items: center;
            flex: 1 1 auto;
            min-width: 0;
        }

        &-text {
            color: $colorPrimaryJustBlack;
            white-space: nowrap;
            flex: 0 0 auto;
            padding-right: 3px;
        }

        &-input {
            @include clearStyle;
            @include fontBodyText;
            flex: 1 1 auto;
            min-width: 0;
        }

        &-unit {
            color: $colorGreyMid;
            white-space: nowrap;
            flex: 0 0 auto;
            padding-left: 3px;
        }
    }
}
