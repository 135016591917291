.overlay-add-to-cart {
    padding: 8px;

    &-icon {
        margin-bottom: 40px;

        img {
            margin: 0 auto;
        }

        @include mobileLargeMax {
            margin-top: 24px;
        }
    }

    &-title {
        @include fontH4Headline;
        margin-bottom: 8px;
    }

    &-description {
        @include fontBodyText;
        color: $colorGrey;
    }

    &-buttons {
        margin: 30px 0 -6px;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;

        button,
        a {
            margin: 6px;
            width: auto;
        }
    }
}

@include mobileLargeMax {
    .overlay-add-to-cart {
        padding: 0;

        &-icon {
            margin-top: 24px;
        }
    }
}
