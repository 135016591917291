.profile-offer-overview-item {
    min-height: 156px;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: $colorPrimaryClearlyWhite;
    padding: 0 20px 20px;
    cursor: pointer;

    & + & {
        margin-top: 12px;
    }

    &-info {
        width: calc(33.3% - 10px);
        margin-top: 20px;

        //&:nth-child(5) {
        //    width: calc(66.6% - 5px);
        //}

        &-title {
            @include fontBodySmallText;
            color: $colorGrey;
            margin-bottom: 4px;
        }

        &-value {
            @include fontBodyText;
            color: $colorPrimaryJustBlack;
        }
    }

    &-availability {
        position: absolute;
        top: 22px;
        right: 22px;
        width: 12px;
        height: 12px;
        border-radius: 50%;

        &--accepted {
            background-color: $colorSemanticSuccessGreen;
        }

        &--open {
            background-color: $colorSemanticWarningYellow;
        }

        &--denied {
            background-color: $colorSemanticErrorRed;
        }
    }

    &-details {
        position: absolute;
        bottom: 20px;
        right: 20px;
        opacity: 0;
    }

    &--detail {
        cursor: auto;
    }

    &:hover:not(&--detail) {
        .profile-offer-overview-item-details {
            opacity: 1;
            transition: opacity 0.4s ease;
        }
    }
}

@include tabletMax {
    .profile-offer-overview-item {
        &:not(&--detail) {
            //&-details {
            //    opacity: 1;
            //}
        }
    }
}

@include mobileLargeMax {
    .profile-offer-overview-item {
        flex-direction: column;
        justify-content: initial;
        padding: 0 16px 20px;

        &-info {
            width: 100%;
        }
    }
}
