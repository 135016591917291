.profile-offer-detail-table {
    background-color: $colorPrimaryClearlyWhite;
    flex: 1 1 auto;
    margin-top: 20px;

    &-inner {
        width: 100%;
    }

    &-headline {
        @include fontBodySmallText;
        border-bottom: 1px solid $colorGreyLight;
        color: $colorGrey;
        text-align: left;

        &-title {
            padding: 40px 0 4px;
        }
    }

    th {
        @include fontBodySmallTextBold;
        padding-left: 20px;

        &:last-child {
            padding-right: 20px;
            text-align: right;
        }
    }

    table {
        width: 100%;
    }
}

@include tabletMax {
    .profile-offer-detail-table {
        width: calc(100% + 64px);
        margin-left: -32px;
        background-color: transparent;
        padding-top: 0;

        &-inner {
            padding: 0 32px;
            overflow: auto;
        }

        table {
            background-color: $colorPrimaryClearlyWhite;
            min-width: 900px;
        }
    }
}

@include mobileLargeMax {
    .profile-offer-detail-table {
        width: calc(100% + 32px);
        margin-left: -16px;

        th {
            padding-left: 16px;

            &:first-child {
                width: 204px;
            }

            &:last-child {
                padding-right: 16px;
            }
        }

        &-inner {
            padding: 0 16px;
        }
    }
}
