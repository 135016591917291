.header-title-small {
  font-size: 18px;
  line-height: 24px;
}

@include tabletSmallMax {
  .article-page {
    .header-bottom {
      flex-direction: column;
    }

    .header-favorite-button {
      width: 100%;
      margin-left: 0;
      margin-top: 10px;
    }

    .header-favorite-button-title {
      font-size: inherit;
    }
  }
}
