.shape-overview-table-content-inner {
  width: 100%;
}

.shape-overview-table table th {
  white-space: unset;
}

@include tabletSmallMax {
  .shape-overview .filter-overlay .shape-overview-filters {
    display: flex;
  }

  .shape-overview-table table {
    min-width: 500px;
  }

  .shape-overview-table-content-inner {
    overflow: auto;
  }
}
