.footer {
    background-color: $colorPrimaryJustBlack;
    color: $colorGreyBarely;

    &-max-width {
        max-width: 1440px;
        margin: 0 auto;
        display: flex;
    }

    &-information {
        width: 30%;
        padding: 100px 40px;
    }

    &-links {
        width: 70%;
        border-left: 1px solid $colorGreyDark;
    }

    &-links-content {
        display: flex;
        flex-wrap: wrap;
        padding: 100px 20px 100px 80px;
    }

    &-links-column {
        width: 33.3333%;
        padding-right: 20px;
        word-break: break-word;
    }

    &-links-column-title {
        @include fontH4Headline;
        color: $colorGrey;

        // Arrow indicating the dropdown menu on mobile devices.
        &::after {
            display: none;
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 20px;
            pointer-events: none;
            font-family: $iconFont;
            transition: transform 0.4s ease;
            content: $icon-angle-down;
        }

        &--open {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    &-links-column-content {
        margin-top: 20px;
        font-size: 0;

        a {
            display: block;
            @include fontBodyText;
        }
    }

    &-links-lower {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding: 15px 50px;
        border-top: 1px solid $colorGreyDark;
    }

    &-links-lower-items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        a {
            display: block;
            margin: 10px 30px;
            @include fontOverline;
            color: $colorGrey;
        }
    }

    &-links-lower-socials {
        display: flex;
        flex-wrap: wrap;
        padding: 0 15px;

        a {
            width: 48px;
            height: 48px;
            line-height: 48px;
            font-size: 24px;
            text-align: center;
        }
    }

    &-information-logo {
        width: 100%;
        max-width: 210px;
        margin-bottom: 40px;
    }

    &-information-text {
        color: $colorGreyMid;
        @include fontBodyText;
    }

    &-information-link {
        margin-top: 8px;
        font-weight: 700;
        color: $colorGreyMid;

        span {
            margin-right: 8px;
        }

        &:first-of-type {
            margin-top: 20px;
        }

        a {
            color: $colorPrimaryClearlyWhite;
        }
    }
}

@include tabletMax {
    .footer {
        &-information {
            padding: 100px 32px;
        }

        &-links-content {
            padding-left: 32px;
            padding-right: 12px;
        }

        &-links-lower {
            padding: 15px 2px;
        }
    }
}

@include tabletSmallMax {
    .footer {
        &-max-width {
            display: block;
        }

        &-information {
            width: 100%;
            border-bottom: 1px solid $colorGreyDark;
            padding-top: 60px;
            padding-bottom: 60px;
        }

        &-links-content {
            padding-top: 60px;
            padding-bottom: 60px;
        }

        &-links {
            width: 100%;
            border-left: none;
        }
    }
}

@include mobileLargeMin {
    .footer {
        &-links-column-content {
            display: block !important;

            a:not(:last-child) {
                margin-bottom: 12px;
            }
        }
    }
}

@include mobileLargeMax {
    .footer {
        &-information {
            padding: 60px 16px;
            border-bottom: none;
        }

        &-links-content {
            padding: 0;
            border-top: 1px solid $colorGreyDark;
        }

        &-links-column {
            width: 100%;
            padding: 0;
            border-bottom: 1px solid $colorGreyDark;
        }

        &-links-column-title {
            cursor: pointer;
            padding: 20px 56px 20px 16px;
            @include fontBodyText;
            color: $colorPrimaryClearlyWhite;

            &::after {
                display: block;
            }
        }

        &-links-column-content {
            display: none;
            margin-top: 0;
            padding: 8px 0 20px;

            a {
                padding: 12px 16px;
                display: inline-block;
                vertical-align: top;
                width: 50%;
                color: $colorGreyMid;
            }
        }

        &-links-lower {
            padding: 0;
            border-top: none;
        }

        &-links-lower-items {
            padding: 38px 0;

            a {
                margin: 0;
                padding: 16px;
                width: 50%;
            }
        }

        &-links-lower-socials {
            width: 100%;
            padding: 24px 16px;
            border-top: 1px solid $colorGreyDark;
            justify-content: center;
        }

        &-information-logo {
            max-width: 170px;
            margin-bottom: 24px;
        }
    }
}
