@mixin desktopLargeMin {
    @media screen and (min-width: $desktopLargeBreakpoint + 1px) {
        @content;
    }
}

@mixin desktopLargeMax {
    @media screen and (max-width: $desktopLargeBreakpoint) {
        @content;
    }
}

@mixin desktopMin {
    @media screen and (min-width: $desktopBreakpoint + 1px) {
        @content;
    }
}

@mixin desktopMax {
    @media screen and (max-width: $desktopBreakpoint) {
        @content;
    }
}

@mixin desktopSmallMin {
    @media screen and (min-width: $desktopSmallBreakpoint + 1px) {
        @content;
    }
}

@mixin desktopSmallMax {
    @media screen and (max-width: $desktopSmallBreakpoint) {
        @content;
    }
}

@mixin tabletMin {
    @media screen and (min-width: $tabletBreakpoint + 1px) {
        @content;
    }
}

@mixin tabletMax {
    @media screen and (max-width: $tabletBreakpoint) {
        @content;
    }
}

@mixin tabletSmallMin {
    @media screen and (min-width: $tabletSmallBreakpoint + 1px) {
        @content;
    }
}

@mixin tabletSmallMax {
    @media screen and (max-width: $tabletSmallBreakpoint) {
        @content;
    }
}

@mixin mobileLargeMin {
    @media screen and (min-width: $mobileLargeBreakpoint + 1px) {
        @content;
    }
}

@mixin mobileLargeMax {
    @media screen and (max-width: $mobileLargeBreakpoint) {
        @content;
    }
}

@mixin mobileSmallMin {
    @media screen and (min-width: $mobileSmallBreakpoint + 1px) {
        @content;
    }
}

@mixin mobileSmallMax {
    @media screen and (max-width: $mobileSmallBreakpoint) {
        @content;
    }
}

@mixin clearStyle {
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: 0;
    background: none;
    box-shadow: none;
    appearance: none;
    outline: none;
}

@mixin stretched($position: absolute) {
    position: $position;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
