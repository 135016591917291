.profile-offer-overview {
    padding: 20px 40px 80px;
    background-color: $colorGreyBarely;
    display: flex;
    flex-direction: row;

    &-content {
        width: 100%;
    }
}

@include tabletMax {
    .profile-offer-overview {
        padding: 12px 32px 60px;
        flex-direction: column;
    }
}

@include mobileLargeMax {
    .profile-offer-overview {
        padding: 16px 16px 60px;
    }
}
