.profile-offer-detail-table-item {
    position: relative;
    border-bottom: 1px solid $colorGreyLight;

    > td {
        padding: 12px 0 12px 20px;
        vertical-align: middle;

        &:last-child {
            padding-right: 20px;
            text-align: right;
        }
    }

    &-column {
        color: $colorGreyDark;

        &-product {
            @include fontBodyBold;
            margin-bottom: 4px;
        }

        &-legend {
            @include fontBodySmallText;
            color: $colorGrey;
        }

        &-price {
            white-space: nowrap;
            color: $colorPrimaryJustBlack;
        }

        &-quantity,
        &-price {
            margin-bottom: 2px;
        }

        &-in-stock {
            position: relative;
            padding-left: 22px;

            // Display status circle.
            &::after {
                content: '';
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                width: 12px;
                height: 12px;
                border-radius: 50%;
            }

            &--yes {
                &::after {
                    background-color: $colorSemanticSuccessGreen;
                }
            }

            &--no {
                &::after {
                    background-color: $colorSemanticErrorRed;
                }
            }
        }
    }
}

@include mobileLargeMax {
    .profile-offer-detail-table-item {
        > td {
            padding: 12px 0 12px 16px;

            &:last-child {
                padding-right: 16px;
            }
        }
    }
}
