@import "icon_variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hfgtrew');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?hfgtrew#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?hfgtrew') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?hfgtrew') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?hfgtrew##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home-icon-1 {
  &:before {
    content: $icon-home-icon-1;
  }
}
.icon-zoom-out {
  &:before {
    content: $icon-zoom-out;
  }
}
.icon-zoom-in {
  &:before {
    content: $icon-zoom-in;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-thumbs-up {
  &:before {
    content: $icon-thumbs-up;
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube;
  }
}
.icon-add-comment {
  &:before {
    content: $icon-add-comment;
  }
}
.icon-angle-down {
  &:before {
    content: $icon-angle-down;
  }
}
.icon-angle-left {
  &:before {
    content: $icon-angle-left;
  }
}
.icon-angle-right {
  &:before {
    content: $icon-angle-right;
  }
}
.icon-angle-up {
  &:before {
    content: $icon-angle-up;
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down;
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left;
  }
}
.icon-arrow-right-up {
  &:before {
    content: $icon-arrow-right-up;
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right;
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up;
  }
}
.icon-auftragspositionen {
  &:before {
    content: $icon-auftragspositionen;
  }
}
.icon-auslieferung {
  &:before {
    content: $icon-auslieferung;
  }
}
.icon-blech {
  &:before {
    content: $icon-blech;
    color: #babdc2;
  }
}
.icon-book-open {
  &:before {
    content: $icon-book-open;
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar;
  }
}
.icon-carcoclix {
  &:before {
    content: $icon-carcoclix;
  }
}
.icon-certificate {
  &:before {
    content: $icon-certificate;
  }
}
.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
.icon-check-rounded {
  &:before {
    content: $icon-check-rounded;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-checkbox-alt {
  &:before {
    content: $icon-checkbox-alt;
  }
}
.icon-checkbox {
  &:before {
    content: $icon-checkbox;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-compress {
  &:before {
    content: $icon-compress;
  }
}
.icon-download {
  &:before {
    content: $icon-download;
  }
}
.icon-e-procurement {
  &:before {
    content: $icon-e-procurement;
  }
}
.icon-ecommerce-dollar {
  &:before {
    content: $icon-ecommerce-dollar;
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-envelope {
  &:before {
    content: $icon-envelope;
  }
}
.icon-events {
  &:before {
    content: $icon-events;
  }
}
.icon-expand {
  &:before {
    content: $icon-expand;
  }
}
.icon-external-link {
  &:before {
    content: $icon-external-link;
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook;
  }
}
.icon-fahrtkostenverguetung {
  &:before {
    content: $icon-fahrtkostenverguetung;
  }
}
.icon-file-download {
  &:before {
    content: $icon-file-download;
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf;
  }
}
.icon-filter-v2 {
  &:before {
    content: $icon-filter-v2;
  }
}
.icon-filter {
  &:before {
    content: $icon-filter;
  }
}
.icon-flach {
  &:before {
    content: $icon-flach;
    color: #babdc2;
  }
}
.icon-fliessbohren {
  &:before {
    content: $icon-fliessbohren;
  }
}
.icon-font-size {
  &:before {
    content: $icon-font-size;
  }
}
.icon-gesundheitfoerderung {
  &:before {
    content: $icon-gesundheitfoerderung;
  }
}
.icon-gewindeschneiden {
  &:before {
    content: $icon-gewindeschneiden;
  }
}
.icon-graph {
  &:before {
    content: $icon-graph;
  }
}
.icon-gruendung {
  &:before {
    content: $icon-gruendung;
  }
}
.icon-i-form {
  &:before {
    content: $icon-i-form;
    color: #babdc2;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram;
  }
}
.icon-kantine {
  &:before {
    content: $icon-kantine;
  }
}
.icon-lagerflaeche {
  &:before {
    content: $icon-lagerflaeche;
  }
}
.icon-liefertermine {
  &:before {
    content: $icon-liefertermine;
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin;
  }
}
.icon-lkw {
  &:before {
    content: $icon-lkw;
  }
}
.icon-lock {
  &:before {
    content: $icon-lock;
  }
}
.icon-login {
  &:before {
    content: $icon-login;
  }
}
.icon-logistik {
  &:before {
    content: $icon-logistik;
  }
}
.icon-logout {
  &:before {
    content: $icon-logout;
  }
}
.icon-map-marker {
  &:before {
    content: $icon-map-marker;
  }
}
.icon-materialbeschaffung {
  &:before {
    content: $icon-materialbeschaffung;
  }
}
.icon-medal {
  &:before {
    content: $icon-medal;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-minus {
  &:before {
    content: $icon-minus;
  }
}
.icon-oberflaechenbehandlung {
  &:before {
    content: $icon-oberflaechenbehandlung;
  }
}
.icon-pen-1 {
  &:before {
    content: $icon-pen-1;
  }
}
.icon-pen {
  &:before {
    content: $icon-pen;
  }
}
.icon-person {
  &:before {
    content: $icon-person;
  }
}
.icon-play {
  &:before {
    content: $icon-play;
  }
}
.icon-plus {
  &:before {
    content: $icon-plus;
  }
}
.icon-preis-zuschlaege {
  &:before {
    content: $icon-preis-zuschlaege;
  }
}
.icon-profil-rohr {
  &:before {
    content: $icon-profil-rohr;
    color: #babdc2;
  }
}
.icon-quote {
  &:before {
    content: $icon-quote;
  }
}
.icon-rohrlasern {
  &:before {
    content: $icon-rohrlasern;
  }
}
.icon-rund-rohr {
  &:before {
    content: $icon-rund-rohr;
    color: #babdc2;
  }
}
.icon-rund {
  &:before {
    content: $icon-rund;
    color: #babdc2;
  }
}
.icon-saegen {
  &:before {
    content: $icon-saegen;
  }
}
.icon-search {
  &:before {
    content: $icon-search;
  }
}
.icon-sechskant {
  &:before {
    content: $icon-sechskant;
    color: #babdc2;
  }
}
.icon-settings-1 {
  &:before {
    content: $icon-settings-1;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-shop-cart {
  &:before {
    content: $icon-shop-cart;
  }
}
.icon-sonderform {
  &:before {
    content: $icon-sonderform;
    color: #babdc2;
  }
}
.icon-sortiment {
  &:before {
    content: $icon-sortiment;
  }
}
.icon-star-filled {
  &:before {
    content: $icon-star-filled;
  }
}
.icon-star {
  &:before {
    content: $icon-star;
  }
}
.icon-support {
  &:before {
    content: $icon-support;
  }
}
.icon-t-form {
  &:before {
    content: $icon-t-form;
    color: #babdc2;
  }
}
.icon-telephone {
  &:before {
    content: $icon-telephone;
  }
}
.icon-tourenzeiten {
  &:before {
    content: $icon-tourenzeiten;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-u-form {
  &:before {
    content: $icon-u-form;
    color: #babdc2;
  }
}
.icon-umsatz {
  &:before {
    content: $icon-umsatz;
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock;
  }
}
.icon-upload {
  &:before {
    content: $icon-upload;
  }
}
.icon-user {
  &:before {
    content: $icon-user;
  }
}
.icon-vierkant {
  &:before {
    content: $icon-vierkant;
    color: #babdc2;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.icon-weiterbildung {
  &:before {
    content: $icon-weiterbildung;
  }
}
.icon-winkel-profile {
  &:before {
    content: $icon-winkel-profile;
    color: #babdc2;
  }
}
.icon-winkel {
  &:before {
    content: $icon-winkel;
    color: #babdc2;
  }
}
.icon-world {
  &:before {
    content: $icon-world;
  }
}

