.overlay-cart-item-comment {
    &-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-title {
            @include fontH4Headline;
            color: $colorPrimaryJustBlack;
        }

        &-icon {
            color: $colorPrimaryEnergeticRed;
            margin-top: 3px;

            i {
                font-size: 24px;
            }
        }
    }

    &-text-area {
        margin-top: 28px;
    }

    &-buttons {
        margin-top: 18px;
        display: flex;
        justify-content: flex-end;

        button,
        a {
            width: 120px;

            &:last-child {
                margin-left: 12px;
            }
        }
    }
}

@include mobileSmallMax {
    .overlay-cart-item-comment {
        &-buttons {
            justify-content: center;

            button,
            a {
                width: 148px;
            }
        }
    }
}
