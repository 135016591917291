.profile-offer-detail-table-item {
    &-column {
        &-in-stock {
            &--no {
                &::after {
                    background-color: $colorSemanticErrorOrange;
                }
            }
        }
    }
}
