.profile-user-management-item {
    &-reset-password-container {
        position: absolute;
        top: 111px;
        right: 20px;
        z-index: 1;
    }
}
@include mobileLargeMax {
  .profile-user-management-item-reset-password-container {
    top: 256px;
  }
}
