.profile-cart-form {
    background-color: $colorPrimaryClearlyWhite;
    padding: 20px;

    &-title {
        @include fontH4Headline;
        color: $colorPrimaryJustBlack;
    }

    &-content {
        margin-top: 28px;

        &-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &-info {
                width: calc(33.3% - 10px);
            }
        }

        &-comment {
            margin-top: 20px;
        }

        &-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 16px;

            a {
                width: 120px;

                &:last-child {
                    margin-left: 12px;
                }
            }
        }
    }
}

@include mobileLargeMax {
    .profile-cart-form {
        padding: 16px;

        &-title {
            @include fontBodyLargeBold;
        }

        &-content {
            &-top {
                justify-content: initial;
                flex-wrap: wrap;

                &-info {
                    width: 100%;
                    margin-top: 20px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }

            &-buttons a {
                width: calc(50% - 6px);
            }
        }
    }
}
