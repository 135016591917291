.profile-offer-overview-filter {
    &-offers {
        margin-top: 12px;
    }

    &-search {
        background-color: $colorPrimaryClearlyWhite;
        padding: 20px;

        &-headline {
            @include fontH3Headline;
            color: $colorPrimaryJustBlack;
        }

        &-icon {
            position: absolute;
            top: 16px;
            right: 16px;
            font-size: 24px;
            color: $colorPrimaryEnergeticRed;
            display: none;
        }

        &--open {
            .profile-offer-overview-filter-search {
                &-icon {
                    transform: rotate(180deg);
                }

                &-content {
                    margin-top: 28px;
                }
            }
        }

        &-filters {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: space-between;

            &-option {
                width: calc(33.3% - 10px);
                margin-bottom: 20px;

                // Overwrite datepicker margin to fit into design.
                .datepicker {
                    margin-bottom: 0;
                }
            }
        }

        &-bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &-legend {
                display: flex;

                &-state {
                    display: flex;
                    position: relative;
                    align-items: center;
                    @include fontBodyText;
                    color: $colorGreyDark;

                    &-icon {
                        content: '';
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin-right: 8px;

                        &--accepted {
                            background-color: $colorSemanticSuccessGreen;
                        }

                        &--open {
                            background-color: $colorSemanticWarningYellow;
                            margin-left: 18px;
                        }

                        &--denied {
                            background-color: $colorSemanticErrorRed;
                            margin-left: 18px;
                        }
                    }
                }
            }

            &-button {
                width: 140px;
            }
        }
    }
}

@include tabletSmallMax {
    .profile-offer-overview-filter {
        &-search {
            &-filters-option {
                width: calc(50% - 10px);
            }
        }
    }
}

@include mobileLargeMax {
    .profile-offer-overview-filter {
        &-search {
            padding: 16px;

            &-headline {
                @include fontH4Headline;
            }

            &-icon {
                display: block;
            }

            &-content {
                display: none;
            }

            &--open {
                .profile-offer-overview-filter-search-content {
                    display: block;
                }
            }

            &-filters {
                flex-direction: column;

                &-option {
                    width: 100%;
                }
            }

            &-bottom {
                flex-direction: column;

                &-legend {
                    width: 100%;
                    justify-content: space-between;
                    margin-bottom: 28px;

                    &-state-icon--open,
                    &-state-icon--denied {
                        margin-left: 0;
                    }
                }

                &-button {
                    width: 100%;
                }
            }
        }
    }
}

@include mobileSmallMax {
    .profile-offer-overview-filter-search-bottom {
        align-items: initial;

        &-legend {
            justify-content: flex-start;
            flex-wrap: wrap;

            &-state {
                width: 50%;

                &:nth-child(2) {
                    width: calc(50% - 28px);
                    margin-left: 28px;
                }

                &:last-child {
                    margin-top: 12px;
                }
            }
        }
    }
}
