.profile-cart-form {
  &-content {
    &-buttons {
      app-button {
        width: 120px;
        display: block;

        a:last-child {
          width: 100%;
          margin: 0;
        }

        &:last-child {
          margin-left: 12px;
        }
      }
    }
  }
}

@include mobileLargeMax {
  .profile-cart-form {
    &-buttons app-button {
      width: calc(50% - 6px);
    }
  }
}
