.cart {
    background-color: $colorPrimaryClearlyWhite;

    &-headlines {
        display: flex;
        border-bottom: 1px solid $colorGreyLight;
        padding: 8px 20px 4px;
    }

    &-headline {
        @include fontBodySmallTextBold;
        color: $colorGrey;
        padding-right: 20px;

        &-product {
            // Width of element in design plus padding, divided by container width in design.
            width: calc(100% * ((324 + 20) / 1320));
            // Width of image, divided by container width in design.
            margin-left: calc(100% * (210 / 1320));
        }

        &-amount {
            // Width of element in design plus padding, divided by container width in design.
            width: calc(100% * ((140 + 20) / 1320));
        }

        &-weight {
            // Width of element in design plus padding, divided by container width in design.
            width: calc(100% * ((128 + 20) / 1320));
        }

        &-stock {
            // Width of element in design plus padding, divided by container width in design.
            width: calc(100% * ((124 + 20) / 1320));
        }

        &-price {
            // Width of element in design plus padding, divided by container width in design.
            width: calc(100% * ((100 + 20) / 1320));
            text-align: right;
        }

        &-summary {
            // Width of element in design plus padding, divided by container width in design.
            width: calc(100% * ((110 + 20) / 1320));
            text-align: right;
        }
    }

    &-summary {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 60px 20px 20px;
        justify-content: space-between;
    }

    &-summary-comment {
        padding-left: 16%;
        width: calc(50% + 140px); // 140px is width of amount counter.
        padding-right: 20px;
    }

    &-summary-content {
        text-align: right;
        margin-right: -40px; // Fix for weird design...
    }

    &-summary-price {
        margin-bottom: 40px;
    }

    &-summary-price-title {
        color: $colorGreyDark;
        @include fontH4Headline;
    }

    &-summary-price-extra {
        @include fontBodySmallText;
        color: $colorGrey;
        margin-top: 5px;
    }

    &-summary-button {
        display: inline-block;
        width: auto;
        min-width: 190px;
    }
}

@include desktopSmallMax {
    .cart {
        &-headlines {
            display: none;
        }

        &-summary {
            padding: 20px;
        }

        &-summary-comment {
            padding: 0;
            width: 100%;

            .text-area textarea {
                min-height: 96px;
            }
        }

        &-summary-content {
            width: 100%;
            text-align: right;
            margin-right: 0;
            margin-top: 20px;
        }
    }
}

@include mobileLargeMax {
    .cart {
        &-summary {
            padding: 20px 16px 16px;
        }

        &-summary-button {
            width: 100%;
        }
    }
}
