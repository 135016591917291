.navigation-cart,
.navigation-favorites {
    color: $colorPrimaryEnergeticRed;
    @include fontBodyText;
    transition: color 0.4s ease;

    &:hover {
        color: $colorPrimaryHighlightRed;
    }

    i {
        display: inline-block;
        vertical-align: middle;
        font-size: 24px;
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }

    &-counter {
        top: 10px;
        right: 10px;
        position: absolute;
        border-radius: 50%;
        height: 20px;
        width: 20px;
        background-color: $colorPrimaryJustBlack;
        color: $colorPrimaryClearlyWhite;
        font-size: 13px;
        line-height: 22px;
        font-weight: 700;
        text-align: center;
    }
}

@include tabletMax {
    .navigation-cart,
    .navigation-favorites {
        display: none;
    }
}
