.address-selection {
    background-color: $colorPrimaryClearlyWhite;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-title {
        @include fontH3Headline;
        margin-bottom: 28px;
    }

    &-shipping-selection {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        &-select {
            max-width: 325px;
        }
    }

    &-items {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        max-width: 1440px;

        &.address-selection-items--mobile {
            display: none;
        }
    }

    &-button-container {
        margin-top: 20px;
    }
}

@include tabletMax {
    .address-selection-items {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        > * {
            width: calc(50% - 10px);
        }
    }
}

@include mobileLargeMax {
    .address-selection {
        padding: 16px;
    }

    .address-selection-title {
        @include fontH4Headline;
    }

    .checkbox-list-title {
        @include fontBodyLargeBold;
        color: $colorPrimaryJustBlack;
    }

    .address-selection-item--hide-on-mobile {
        display: none;
    }

    .address-selection-items {
        flex-direction: column;
        align-items: stretch;

        > * {
            width: auto;
        }
    }

    .address-selection-items.address-selection-items--mobile {
        margin-top: 20px;
        display: flex;
    }

    .address-selection-button-container {
        margin-top: 16px;
        margin-bottom: 8px;
    }
}
