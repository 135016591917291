.profile-navigation {
    max-width: 325px;
    width: 100%;
    margin-right: 20px;

    &-inner {
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: auto;
    }

    &-logout {
        margin-top: 1px;
        padding: 20px;
        background-color: $colorPrimaryClearlyWhite;
        color: $colorGreyDark;

        i {
            font-size: 24px;
            margin-top: -3px;
        }

        span {
            @include fontBodyLargeText;
        }
    }
}

@include tabletMax {
    .profile-navigation {
        max-width: unset;
        width: calc(100% + 64px);
        background-color: unset;
        margin-left: -32px;
        margin-right: 0;
        margin-bottom: 20px;

        &-inner {
            flex-direction: row;
            overflow: scroll;
        }

        &-logout {
            border-bottom: 0.5px solid $colorGreyMid;
            padding: 20px 32px 16px;
            background-color: $colorGreyBarely;
            color: $colorGrey;
            white-space: nowrap;

            span {
                @include fontBodyLargeBold;
            }
        }
    }
}

@include mobileLargeMax {
    .profile-navigation {
        width: calc(100% + 32px);
        margin-left: -16px;
    }
}
