.filter-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorPrimaryClearlyWhite;
    flex-direction: column;
    overflow: hidden;
    z-index: $zOverlay;

    &-header {
        width: 100%;
        padding: 16px;
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $colorGreyLight;
    }

    &-header-text {
        @include fontH3Headline;
    }

    &-body {
        flex: 1 1 auto;
        padding: 16px 16px 0;
        width: 100%;
        overflow: auto;
    }

    &-footer {
        padding: 16px;
        flex: 0 0 auto;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        & > .button-primary,
        & > .button-secondary {
            width: calc(50% - 5px);
            flex: 0 0 auto;
        }
    }
}

@include tabletSmallMax {
    .filter-overlay--open {
        display: flex;
    }
}
