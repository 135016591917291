.skeleton-row {
  border-top: 1px solid $colorGreyLight;

  &.skeleton-header-row {
    border-top: none;
  }

  &:not(.product-skeleton) .skeleton-item:last-child {
    padding-right: 85px;
  }
}

.skeleton-item {
  padding: 13px 20px;

  @include desktopSmallMax {
    width: 100%;
  }

  &.circle {
    padding: 13px 15px;
    width: 40px;

    @include desktopSmallMax {
      display: none;
    }
  }

  &.skeleton-header {
    height: 69px;
    vertical-align: bottom;
  }
}

.skeleton-spare-item {
  @include desktopSmallMax {
    display: none;
  }
}
