.datepicker {
    margin-bottom: 18px;

    &-label {
        @include fontBodySmallText;
        color: $colorGreyDark;
        margin-bottom: 4px;
    }

    i {
        color: $colorPrimaryEnergeticRed;
        display: block;
    }
}
