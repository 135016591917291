.order-overview-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    border-bottom: 1px solid $colorGreyLight;

    &-content {
        display: flex;
        flex: 1 1 auto;
        width: 100%;
        align-items: center;
    }

    &-additions {
        display: flex;
        flex-direction: column;
        align-items: stretch;

        > * {
            margin-top: 12px;
        }

        > *:first-child {
            margin-top: 20px;
        }
    }

    &-text {
        padding-right: 20px;
        width: 36%;

        .order-overview-item-availability-icon {
            display: none;
        }
    }

    &-text-title {
        color: $colorGreyDark;
        @include fontBodyBold;
    }

    &-text-description {
        @include fontBodySmallText;
        color: $colorGrey;
        margin-top: 4px;
    }

    &-text-comment,
    &-text-comment-mobile {
        @include clearStyle;
        margin-top: 16px;
        text-align: left;
        @include fontBodySmallText;
        color: $colorGrey;
    }

    &-text-comment-mobile {
        display: none;
    }

    &-amount,
    &-weight {
        padding-right: 20px;
        width: 12%;
    }

    &-amount {
        width: 15%;
    }

    &-availability {
        padding-right: 20px;
        padding-left: 20px;
        width: 15%;

        &-icon {
            position: absolute;
            top: 5px;
            left: 0;
            content: '';
            width: 12px;
            height: 12px;
            border-radius: 50%;

            &--available {
                background: $colorSemanticSuccessGreen;
            }

            &--unavailable {
                background: $colorPrimaryEnergeticRed;
            }
        }
    }

    &-price {
        padding-right: 15px;
        width: 12%;
    }

    &-price-extra,
    &-amount-extra {
        @include fontBodySmallText;
        color: $colorGrey;
        margin-top: 4px;
    }

    &-sum {
        padding-right: 0;
        width: 10%;
        text-align: right;
    }
}

@include tabletMax {
    .order-overview-item {
        &-content {
            flex-wrap: wrap;
        }

        &-additions {
            > *:first-child {
                margin-top: 24px;
            }
        }

        &-image {
            display: none;
        }

        &-text {
            width: 100%;
            padding-right: 30%;
            margin-bottom: 4px;

            .order-overview-item--transport & {
                width: 70%;
                margin-bottom: 0;
            }

            .order-overview-item-availability-icon {
                display: inline-block;
            }
        }

        &-text-title {
            display: inline;
            vertical-align: middle;
        }

        &-text-description {
            display: none;

            .order-overview-item--transport & {
                display: block;
            }
        }

        &-text-comment {
            display: none;
        }

        &-text-comment-mobile {
            margin-top: 16px;
            display: block;
        }

        &-price,
        &-weight,
        &-availability {
            display: none;
        }

        &-sum {
            @include fontBodyBold;
            color: $colorGreyDark;
            width: 50%;
            text-align: right;
            padding-right: 0;
            margin-top: -30px;

            .order-overview-item--transport & {
                width: 30%;
                margin-top: initial;
            }
        }

        &-amount {
            width: 50%;
            padding-right: 0;
            color: $colorGrey;

            .order-overview-item--transport & {
                display: none;
            }
        }

        &-amount-extra {
            display: none;
        }
    }
}

@include mobileLargeMax {
    .order-overview-item {
        padding: 20px 16px;
    }
}
