.overlay {
  display: flex;
}

.overlay-content .mat-dialog-container {
  padding: 0;
  box-shadow: none;
  background: none;
  color: inherit;
  border-radius: 0;
}

.cdk-global-overlay-wrapper {
  z-index: 3000;
}
