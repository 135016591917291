.navigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $colorPrimaryClearlyWhite;
    z-index: $zNavigation;

    // Border on the bottom.
    &::after {
        position: absolute;
        left: 0;
        bottom: 0;
        pointer-events: none;
        content: '';
        background-color: $colorGreyLight;
        height: 1px;
        width: 100%;
    }

    // Spacing to push down content under navigation.
    &-spacer {
        height: 164px;
        flex: 0 0 auto;
    }

    &-toggler {
        @include clearStyle;
        display: none;
        position: absolute;
        right: 30px;
        top: 10px;
        height: 44px;
        width: 44px;
        padding: 10px;
        z-index: 1;

        div {
            height: 2px;
            background-color: $colorPrimaryEnergeticRed;
            width: 100%;
            transition: opacity 0.4s ease, transform 0.4s ease;

            &:not(:last-child) {
                margin-bottom: 6px;
            }

            .navigation--open & {
                opacity: 0;

                &:first-child {
                    transform: translateY(8px) rotate(45deg);
                    opacity: 1;
                }

                &:last-child {
                    transform: translateY(-8px) rotate(-45deg);
                    opacity: 1;
                }
            }
        }
    }

    &-top {
        padding: 24px 40px 24px 290px; // 250px + 40px Spacing for logo.
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &-logo {
        width: 100%;
        max-width: 210px;
        position: absolute;
        left: 40px;
        top: 24px;
        z-index: 1;
    }

    &-search {
        max-width: 420px;
        min-height: 48px;
        width: 100%;
    }

    &-language-chooser {
        margin-left: 20px;
        min-height: 48px;

        select {
            width: 92px;
        }
    }

    &-inner {
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &-items {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include tabletMax {
            width: 100%;
        }
    }

    &-item {
        position: relative;
        cursor: pointer;
        @include fontBodyLargeText;

        &--menu {
            // Arrow indicating a menu.
            &::after {
                display: block;
                position: absolute;
                top: 22px;
                right: 15px;
                color: $colorPrimaryEnergeticRed;
                content: $icon-angle-down;
                font-family: $iconFont;
                font-size: 24px;
                pointer-events: none;
                transition: transform 0.4s ease;
            }
        }
    }

    &-item-title {
        display: block;
        position: relative;
        text-align: center;
        transition: color 0.4s ease;
        padding: 22px 20px;
        min-width: 52px;

        .navigation-item--active &,
        .navigation-item:hover & {
            color: $colorPrimaryEnergeticRed;
        }
    }

    &-item-menu {
        position: absolute;
        padding: 20px;
        cursor: auto;
        top: 100%;
        left: -20px;
        background-color: $colorPrimaryClearlyWhite;
        transition: opacity 0.4s ease, visibility 0.4s ease, transform 0.6s ease;
        box-shadow: 0 2px 16px rgba($colorPrimaryBlack, 0.16);
        display: flex;
    }

    &-item-menu-back {
        color: $colorPrimaryEnergeticRed;
        display: none;
        padding: 20px 32px;
        width: 100%;
        text-align: left;
        height: 64px;

        i {
            display: inline-block;
            vertical-align: middle;
            font-size: 24px;
            margin-right: 5px;
        }

        span {
            display: inline-block;
            vertical-align: middle;

            @include fontBodyLargeText;
        }

        &--line {
            border-bottom: 1px solid $colorGreyLight;
        }
    }

    &-item-menu-column {
        padding: 10px;
    }

    &-item-menu-column + &-item-menu-column--without-title {
        margin-top: 26px;
    }

    &-item-menu-link {
        position: relative;
        display: flex;
        width: 220px;
        padding: 10px 40px 10px 10px;
        @include fontBodyLargeText;
        transition: color 0.4s ease, background-color 0.4s ease;

        i {
            color: $colorGreyMid;
            font-size: 24px;
            margin-right: 5px;
            transition: color 0.4s ease;
        }

        // Arrow visible on desktop hover.
        &::after {
            content: $icon-arrow-right;
            font-family: $iconFont;
            color: $colorPrimaryEnergeticRed;
            font-size: 24px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            pointer-events: none;
            transition: opacity 0.4s ease;
        }

        &--active {
            color: $colorPrimaryEnergeticRed;
        }
    }

    &-item-menu-content {
        display: grid;
        grid-auto-flow: column;
        grid-template-rows: repeat(5, auto);
        column-gap: 20px;
        margin-top: 0;
    }

    &-buttons {
        display: flex;
        align-items: center;
    }

    &-button {
        position: relative;
        padding: 20px;
        line-height: 24px;

        &:not(:first-child) {
            // Grey stroke between buttons.
            &::before {
                height: 32px;
                width: 1px;
                content: '';
                pointer-events: none;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                background-color: $colorGreyLight;
            }
        }
    }

    &-logout {
        display: none;
    }
}

@include tabletMin {
    .navigation {
        &-content {
            display: block !important; // Overwrite JS.
        }

        &-item {
            // Rectangle on the bottom.
            &::before {
                display: block;
                position: absolute;
                bottom: 1px;
                height: 4px;
                width: 52px;
                background-color: $colorPrimaryEnergeticRed;
                content: '';
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.4s ease;
            }

            &--active {
                &::before {
                    opacity: 1;
                }
            }

            &:hover {
                &::after {
                    transform: rotate(180deg);
                }
            }
        }

        &-item-title {
            .navigation-item:first-child & {
                // Grey stroke for the first (shop) item.
                &::after {
                    height: 32px;
                    width: 1px;
                    content: '';
                    pointer-events: none;
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    background-color: $colorGreyLight;
                }
            }

            .navigation-item--menu & {
                padding-right: 45px;
            }
        }

        &-item-menu {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }

        &-item-menu-title {
            @include fontOverline;
            font-weight: 700;
            color: $colorGrey;
            margin-bottom: 10px;
            padding: 0 10px;
        }

        &-item-menu-link {
            &:hover {
                background-color: $colorGreyBarely;
                color: $colorPrimaryEnergeticRed;

                &::after {
                    opacity: 1;
                }

                i {
                    color: $colorPrimaryEnergeticRed;
                }
            }
        }

        .navigation-item--menu.navigation-item--active {
            &::before {
                left: calc(50% - 12px);
            }
        }
    }
}

@include tabletMax {
    .navigation {
        height: 64px;

        &-spacer {
            height: 64px;
        }

        &-content {
            @include stretched(fixed);
            padding: 64px 0;
            display: none;
            background-color: $colorPrimaryClearlyWhite;
        }

        &-toggler {
            display: block;
            right: 22px;
        }

        &-top {
            padding: 24px 32px;
        }

        &-logo {
            top: 16px;
            left: 32px;
            max-width: 150px;
        }

        &-search {
            max-width: none;
        }

        &-search-input {
            input {
                padding-right: 16px;
                padding-left: 48px;
            }

            i {
                color: $colorGrey;
            }

            button {
                right: auto;
                left: 0;
            }
        }

        &-inner {
            padding-left: 32px;
            padding-right: 0;
            overflow: auto;
            height: calc(100% - 96px); // 96px is height of search and language bar.
            display: block;
        }

        &-item {
            width: 100%;
            border-bottom: 1px solid $colorGreyLight;

            &::after {
                transform: rotate(-90deg);
                right: 25px;
            }
        }

        &-item-title {
            min-width: 0;
            padding: 20px 60px 19px 0;
            text-align: left;
        }

        &-item-menu {
            display: block;
            position: fixed;
            bottom: 0;
            left: 0;
            top: auto;
            width: 100%;
            overflow: auto;
            height: calc(100vh - 160px); // 160px is height of elements above.
            background-color: $colorPrimaryClearlyWhite;
            box-shadow: none;
            transform: translateX(100%);
            z-index: 1;
            padding: 0;
            transition: transform 0.6s ease;

            .navigation-item--open & {
                transform: translateX(0);
            }
        }

        &-item-menu-back {
            display: block;
        }

        &-item-menu-column {
            padding: 0;
        }

        &-item-menu-column + &-item-menu-column--without-title {
            margin-top: 0;

            .navigation-item-menu-link:first-child {
                border-top: 1px solid $colorGreyLight;
            }
        }

        &-item-menu-title {
            @include fontH4Headline;
            padding: 17px 32px;
            text-transform: none;
            color: $colorPrimaryJustBlack;
            border-top: 1px solid $colorGreyLight;
            border-bottom: 1px solid $colorGreyLight;
            margin-bottom: 0;
        }

        &-item-menu-link {
            width: 100%;
            padding: 20px 0 19px;
            border-bottom: 1px solid $colorGreyLight;

            &:last-child {
                padding: 20px 0;
                border-bottom: none;
            }
        }

        &-item-menu-content {
            display: block;
            padding-left: 32px;
            margin-top: 0;
        }

        &-buttons {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            border-top: 1px solid $colorGreyLight;
            background-color: $colorPrimaryClearlyWhite;

            &--logged-in {
                justify-content: space-between;

                .navigation-login {
                    width: unset;

                    &::after {
                        display: none;
                    }
                }

                .navigation-logout {
                    display: block;

                    &::before {
                        display: none;
                    }
                }
            }
        }

        &-login {
            width: 100%;
            padding-right: 52px; // 32px + 20px for icon.
            padding-left: 32px;

            @include mobileLargeMax {
                padding-right: 40px;
                padding-left: 16px;
            }
        }

        &-logout {
            padding-right: 32px;
        }
    }
}

@include mobileLargeMax {
    .navigation {
        &-toggler {
            right: 6px;
        }

        &-top {
            padding: 16px;
        }

        &-logo {
            left: 16px;
        }

        &-language-chooser {
            margin-left: 16px;
        }

        &-inner {
            padding-left: 16px;
            height: calc(100% - 80px); // 80px is height of search and language bar.
        }

        &-item {
            &::after {
                right: 15px;
            }
        }

        &-item-menu {
            height: calc(100vh - 144px); // 144px is height of elements above.
        }

        &-item-menu-back {
            padding: 20px 16px;
        }

        &-item-menu-title {
            padding: 17px 16px;
        }

        &-item-menu-content {
            padding-left: 16px;
        }

        &-login {
            padding-right: 40px;
            padding-left: 16px;
        }

        &-logout {
            padding-right: 16px;
        }
    }
}
