.dimensions-filter {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-image {
        // Allow image to be a bit wider compared to other filters.
        margin: 0 -8px 12px;
        align-self: center;

        @include tabletSmallMax {
            margin: 8px 0 20px;
        }
    }

    &-text {
        @include fontBodySmallTextBold;
        color: $colorGrey;

        @include tabletSmallMax {
            @include fontBodyLargeBold;
            color: $colorPrimaryJustBlack;
        }
    }

    &-item {
        margin-top: 12px;
    }

    // Full width line under image on mobile.
    &-border {
        height: 1px;
        margin: 0 -16px 12px;
        background-color: $colorGreyLight;
        display: none;

        @include tabletSmallMax {
            display: block;
        }
    }

  .reset-filter-button {
    float: right;
    margin-top: 15px;
  }
}
