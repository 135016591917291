.header {
    background-color: $colorGreyBarely;
    padding: 20px 40px;

    &-bottom {
        display: flex;
        flex-direction: row;
        padding-top: 20px;
        align-items: center;
    }

    &-title {
        @include fontH2Headline;
        margin-right: 16px;
    }

    &-favorite {
        border-left: 1px solid $colorGreyMid;
    }

    &-filter {
        display: none;
        white-space: nowrap;
    }

    &-favorite-button {
        background-color: transparent;
        color: $colorPrimaryEnergeticRed;
        border: 0;
        padding: 0;
        margin-left: 17px;
        cursor: pointer;

        &-icon {
            font-size: 20px;
            margin-right: 7px;
            vertical-align: text-bottom;
        }

        &-title {
            font-family: $fontDefault;
            @include fontBodyText;
        }
    }
}

@include tabletMax {
    .header {
        padding: 16px 32px;
    }
}

@include tabletSmallMax {
    .header {
        &-title {
            @include fontH3Headline;
            margin-right: 20px;
        }

        &-bottom {
            align-items: flex-start;
            justify-content: space-between;
            padding-top: 12px;
        }

        &-favorite {
            border-left: 0;
        }

        &-filter {
            display: block;
        }

        &-favorite-button {
            width: 27px;
        }

        &-favorite-button-icon::before {
            font-size: 20px;
            margin-right: 0;
        }

        &-favorite-button-title {
            font-size: 0;
        }
    }
}

@include mobileLargeMax {
    .header {
        padding: 16px 20px;
    }
}
