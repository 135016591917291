$icomoon-font-family: "iconfont-ehg" !default;
$icomoon-font-path: "fonts" !default;

$icon-home-icon-1: "\e96a";
$icon-zoom-out: "\e968";
$icon-zoom-in: "\e969";
$icon-share: "\e965";
$icon-thumbs-up: "\e966";
$icon-youtube: "\e967";
$icon-add-comment: "\e900";
$icon-angle-down: "\e901";
$icon-angle-left: "\e902";
$icon-angle-right: "\e903";
$icon-angle-up: "\e904";
$icon-arrow-down: "\e905";
$icon-arrow-left: "\e906";
$icon-arrow-right-up: "\e907";
$icon-arrow-right: "\e908";
$icon-arrow-up: "\e909";
$icon-auftragspositionen: "\e90a";
$icon-auslieferung: "\e90b";
$icon-blech: "\e90c";
$icon-book-open: "\e90d";
$icon-calendar: "\e90e";
$icon-carcoclix: "\e90f";
$icon-certificate: "\e910";
$icon-chat: "\e911";
$icon-check-rounded: "\e912";
$icon-check: "\e913";
$icon-checkbox-alt: "\e914";
$icon-checkbox: "\e915";
$icon-clock: "\e916";
$icon-close: "\e917";
$icon-compress: "\e918";
$icon-download: "\e919";
$icon-e-procurement: "\e91a";
$icon-ecommerce-dollar: "\e91b";
$icon-edit: "\e91c";
$icon-envelope: "\e91d";
$icon-events: "\e91e";
$icon-expand: "\e91f";
$icon-external-link: "\e920";
$icon-facebook: "\e921";
$icon-fahrtkostenverguetung: "\e922";
$icon-file-download: "\e923";
$icon-file-pdf: "\e924";
$icon-filter-v2: "\e925";
$icon-filter: "\e926";
$icon-flach: "\e927";
$icon-fliessbohren: "\e928";
$icon-font-size: "\e929";
$icon-gesundheitfoerderung: "\e92a";
$icon-gewindeschneiden: "\e92b";
$icon-graph: "\e92c";
$icon-gruendung: "\e92d";
$icon-i-form: "\e92e";
$icon-info: "\e92f";
$icon-instagram: "\e930";
$icon-kantine: "\e931";
$icon-lagerflaeche: "\e932";
$icon-liefertermine: "\e933";
$icon-linkedin: "\e934";
$icon-lkw: "\e935";
$icon-lock: "\e936";
$icon-login: "\e937";
$icon-logistik: "\e938";
$icon-logout: "\e939";
$icon-map-marker: "\e93a";
$icon-materialbeschaffung: "\e93b";
$icon-medal: "\e93c";
$icon-menu: "\e93d";
$icon-minus: "\e93e";
$icon-oberflaechenbehandlung: "\e93f";
$icon-pen-1: "\e940";
$icon-pen: "\e941";
$icon-person: "\e942";
$icon-play: "\e943";
$icon-plus: "\e944";
$icon-preis-zuschlaege: "\e945";
$icon-profil-rohr: "\e946";
$icon-quote: "\e947";
$icon-rohrlasern: "\e948";
$icon-rund-rohr: "\e949";
$icon-rund: "\e94a";
$icon-saegen: "\e94b";
$icon-search: "\e94c";
$icon-sechskant: "\e94d";
$icon-settings-1: "\e94e";
$icon-settings: "\e94f";
$icon-shop-cart: "\e950";
$icon-sonderform: "\e951";
$icon-sortiment: "\e952";
$icon-star-filled: "\e953";
$icon-star: "\e954";
$icon-support: "\e955";
$icon-t-form: "\e956";
$icon-telephone: "\e957";
$icon-tourenzeiten: "\e958";
$icon-trash: "\e959";
$icon-u-form: "\e95a";
$icon-umsatz: "\e95b";
$icon-unlock: "\e95c";
$icon-upload: "\e95d";
$icon-user: "\e95e";
$icon-vierkant: "\e95f";
$icon-warning: "\e960";
$icon-weiterbildung: "\e961";
$icon-winkel-profile: "\e962";
$icon-winkel: "\e963";
$icon-world: "\e964";

