.button-text {
  color: $colorPrimaryEnergeticRed;
  @include fontBodyText;
  transition: color 0.4s ease;

  &:not(:disabled):hover {
    color: $colorPrimaryHighlightRed;
  }

  &:disabled {
    color: $colorGreyMid;
  }

  i {
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
    margin-right: 5px;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  &.reset-filter-button {
    font-size: 14px;
    display: flex;
    align-items: baseline;

    i {
      font-size: 21px;
      position: relative;
      top: 4px;
    }
  }
}
