.cart-c2a {
  z-index: 300;

  .navigation-cart-counter {
    top: -5px;
    right: -5px;
    position: absolute;
  }

  &.disabled {
    display: none;
  }
}

.sales-rep-c2a {
  width: 300px;
  right: unset;
  left: 16px;
}

.sales-rep-c2a-content {
  padding-top: 6px;
  border-radius: 0;
  width: 300px;
  color: $colorPrimaryClearlyWhite;
}


@include tabletMax {
  .cart-c2a.favorites-c2a {
    height: calc(100% - 116px);

    .cart-c2a-content {
      top: calc(var(--vh, 1vh) * 100 - 176px);
    }
  }
}

@include mobileLargeMax {
  .cart-c2a.favorites-c2a {
    height: calc(100% - 86px);

    .cart-c2a-content {
      top: calc(var(--vh, 1vh) * 100 - 146px);
    }
  }
}

@include mobileSmallMax {
  .sales-rep-c2a {
    width: 270px !important;
  }

  .sales-rep-c2a-content {
    width: 270px !important;
  }
}
