.order-header {
    padding: 20px 40px;
    background-color: $colorGreyBarely;

    &-top,
    &-bottom {
        display: flex;
        flex-direction: row;
    }

    &-top {
        align-items: flex-start;
        justify-content: center;
    }

    &-bottom {
        align-items: center;
    }

    &-title {
        @include fontH2Headline;
    }

    &-back-button {
        color: $colorPrimaryEnergeticRed;
        white-space: nowrap;
        @include fontBodyText;
        position: absolute;
        left: 0;

        i {
            margin-right: 5px;
            font-size: 20px;
            vertical-align: text-bottom;

            &::before {
                width: 16px;
                height: 12px;
            }
        }
    }
}

@include tabletMax {
    .order-header {
        padding: 20px 32px;

        &-top {
            justify-content: space-between;
        }

        &-back-button {
            position: unset;
            left: unset;
        }
    }
}

@include tabletSmallMax {
    .order-header-top {
        flex-wrap: wrap;
    }

    .order-header-back-button {
        order: 1;
    }

    .order-header-bottom {
        padding-top: 12px;
    }
}

@include mobileLargeMax {
    .order-header-title {
        @include fontH3Headline;
    }

    .order-header {
        padding: 20px 16px;
    }
}
