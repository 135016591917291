.shipping-options {
    background-color: $colorPrimaryClearlyWhite;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    // Remove bottom border of last container;
    margin-bottom: -1px;

    &-title {
        @include fontH3Headline;
        padding: 20px 20px 0;
    }

    &-container {
        padding: 0 20px 20px;
        border-bottom: 1px solid $colorGreyLight;
    }

    &-columns {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
        margin-right: -20px;
    }

    &-column {
        flex: 1 1 auto;
        max-width: 325px;
        display: flex;
        flex-direction: column;
        margin-right: 20px;
    }

    &-headline {
        @include fontBodyBold;
        margin-top: 28px;
    }

    &-comment,
    &-label {
        margin-top: 20px;
    }
}

@include mobileLargeMax {
    .shipping-options-column {
        max-width: none;
    }

    .shipping-options-container {
        padding: 0 16px 16px;
    }

    .shipping-options-title {
        @include fontH4Headline;
        padding: 16px 16px 0;
    }
}
