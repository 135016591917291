.material {
    display: flex;
    flex-direction: row;
    align-items: stretch;

    @include tabletSmallMax {
        .material-filters {
            display: none;
        }
    }
}
