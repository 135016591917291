.shape-overview-filters {
    padding: 40px 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 250px;
    flex: 0 0 auto;
    border-right: 1px solid $colorGreyLight;

    &-filter {
        width: 100%;
        margin-top: 40px;

        &:first-child {
            margin-top: 0;
        }
    }
}

@include tabletSmallMax {
    .shape-overview-filters {
        width: 100%;
        border-right: none;
        padding: 0;
    }
}
