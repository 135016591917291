app-collapsable {
  .mat-expansion-panel-header {
    padding: 0;
  }

  .mat-expansion-panel-body {
    padding: 0;
    box-sizing: border-box;
  }
}
