.profile-factory-certificates-filter {
    background-color: $colorPrimaryClearlyWhite;
    padding: 20px;
    margin-bottom: 20px;

    &--open {
        .profile-factory-certificates-filter {
            &-icon {
                transform: rotate(180deg);
            }

            &-content {
                margin-top: 28px;
            }
        }
    }

    &-content {
        margin-top: 28px;
    }

    &-headline {
        @include fontH3Headline;
        color: $colorPrimaryJustBlack;
    }

    &-icon {
        position: absolute;
        top: 16px;
        right: 16px;
        font-size: 24px;
        color: $colorPrimaryEnergeticRed;
        display: none;
    }

    &-filters {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: calc(100% + 20px);
        margin-left: -10px;

        &-option {
            width: calc(25% - 20px);
            margin: 0 10px 20px;

            // Overwrite datepicker margin to fit into design.
            .datepicker {
                margin-bottom: 0;
            }
        }
    }

    &-bottom {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        &-button {
            width: 140px;
        }
    }
}

@include tabletSmallMax {
    .profile-factory-certificates-filter {
        &-filters-option {
            width: calc(50% - 20px);
        }
    }
}

@include mobileLargeMax {
    .profile-factory-certificates-filter {
        padding: 16px 16px 20px;

        &-headline {
            @include fontH4Headline;
        }

        &-content {
            display: none;
        }

        &--open {
            .profile-factory-certificates-filter-content {
                display: block;
            }
        }

        &-icon {
            display: block;
        }

        &-filters {
            width: 100%;
            margin-left: 0;
            flex-direction: column;

            &-option {
                width: 100%;
                margin: 0 0 20px;
            }
        }

        &-bottom {
            flex-direction: column;

            &-button {
                width: 100%;
            }
        }
    }
}

@include mobileSmallMax {
    .profile-factory-certificates-filter-bottom {
        align-items: initial;
    }
}
