.address-selection-item {
    padding: 20px;
    border: 1px solid $colorGreyLight;

    &-type {
        display: flex;
        justify-content: space-between;

        &-title {
            @include fontBodyBold;
        }

        .icon-pen {
            color: $colorPrimaryEnergeticRed;
            font-size: 20px;
        }
    }

    &-text {
        @include fontBodyText;
        color: $colorGreyDark;
        margin-top: 8px;
    }
}

@include mobileLargeMax {
    .address-selection-item {
        padding: 16px;
    }
}
