.shape-overview {
    display: flex;
    align-items: stretch;

    @include tabletSmallMax {
        .shape-overview-filters {
            display: none;
        }
    }
}
