.profile-favorite-list-detail-page .profile-offer-overview-item {
  min-height: unset;
}

.profile-favorites-page .profile-carts-item-title-container {
  margin: 0;
}

.button-icon.remove-favorite-button {
  margin-left: 20px;

  &::before {
    height: 32px;
    width: 1px;
    content: "";
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: $colorGreyLight;
  }

  i {
    margin-left: 20px;
  }
}
