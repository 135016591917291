.shape-overview-table {
    width: calc(100% - 250px); // Width of filter is 250px;
    flex: 0 0 auto;

    table {
        @include fontBodyText;
        text-align: left;
        width: 100%;
        min-width: 800px;

        tr {
            border-bottom: 1px solid $colorGreyLight;
            transition: background-color 0.3s ease;

            &:hover {
                &:not(:first-child) {
                    background-color: $colorGreyBarely;
                }
            }
        }

        th {
            vertical-align: bottom;
            white-space: nowrap;
        }

        th,
        td {
            min-height: 48px;
            padding: 12px;
            position: relative;
        }

        td {
            transition: background-color 0.3s ease;

            &:empty::before {
                color: $colorGreyMid;
                content: '—';
                font-size: 12px;
            }

            a {
                position: absolute;
                display: flex;
                align-items: center;
                @include stretched;
                padding: 12px 50px 12px 12px;
                color: $colorPrimaryEnergeticRed;
                transition: background-color 0.3s ease, color 0.3s ease;
                height: 100%;

                // Arrow icon.
                &::after {
                    display: block;
                    position: absolute;
                    top: 50%;
                    right: 12px;
                    transform: translateY(-50%);
                    font-family: $iconFont;
                    content: $icon-arrow-right;
                    color: $colorPrimaryClearlyWhite;
                    font-size: 24px;
                    pointer-events: none;
                    opacity: 0;
                    transition: opacity 0.3s ease;
                }

                &:hover {
                    background-color: $colorPrimaryEnergeticRed;
                    color: $colorPrimaryClearlyWhite;

                    &::after {
                        opacity: 1;
                    }
                }
            }

            // Hover the whole column effect.
            &::after {
                transition: background-color 0.3s ease;
                content: '';
                height: 20000px;
                left: 0;
                position: absolute;
                top: -10000px;
                width: 100%;
                z-index: -1;
            }

            &:hover {
                background-color: $colorGreyLight;

                &::after {
                    background-color: $colorGreyBarely;
                }
            }
        }
    }

    &-shadow {
        // Shadow on the right edge when display gets too small.
        &::after {
            display: none;
            position: absolute;
            right: 0;
            top: 0;
            content: '';
            height: 100%;
            width: 50px;
            pointer-events: none;
            background: linear-gradient(to right, rgba($colorPrimaryBlack, 0) 0, rgba($colorPrimaryBlack, 0.15) 100%);
        }
    }

    &-content {
        width: 100%;

        &-inner {
            overflow: auto;
        }
    }

    &-title-extra {
        display: block;
        color: $colorGrey;
    }

    &-details-hover-desktop {
        margin-left: 4px;
    }
}

@include tabletMax {
    .shape-overview-table {
        &-shadow {
            &::after {
                display: block;
            }
        }
    }
}

@include tabletSmallMax {
    .shape-overview-table {
        width: 100%;

        &-content-inner {
            overflow: scroll;
        }

        &-shadow {
            &::after {
                display: none;
            }
        }

        &-title-extra {
            display: none;
        }

        &-details-hover-desktop {
            display: none;
        }
    }
}

@include mobileLargeMax {
    .shape-overview-table {
        &-shadow {
            &::after {
                display: block;
            }
        }
    }
}
