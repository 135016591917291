.cart-c2a {
    position: absolute;
    top: 0;
    right: 40px;
    min-height: 100px;
    height: calc(100% - 40px); // 40px spacing bottom.
    width: 60px;
    pointer-events: none;
    z-index: 999;

    &-content {
        position: sticky;
        top: calc(var(--vh, 1vh) * 100 - 100px); // 100px = 60px container height + 40px spacing bottom.
        right: 0;
        display: none;
        background-color: $colorPrimaryEnergeticRed;
        border-radius: 50%;
        width: 60px;
        height: 60px;
        text-align: center;
        pointer-events: auto;

        i {
            color: $colorPrimaryClearlyWhite;
            font-size: 24px;
            line-height: 60px;
        }
    }

    &-counter {
        top: -5px;
        right: -5px;
        position: absolute;
        border-radius: 50%;
        height: 24px;
        width: 24px;
        background-color: $colorPrimaryJustBlack;
        color: $colorPrimaryClearlyWhite;
        font-size: 13px;
        line-height: 26px;
        font-weight: 700;
        text-align: center;
    }
}

@include tabletMax {
    .cart-c2a {
        &-content {
            display: block;
        }
    }
}

@include mobileLargeMax {
    .cart-c2a {
        height: calc(100% - 16px); // 16px spacing bottom.
        right: 16px;

        &-content {
            top: calc(var(--vh, 1vh) * 100 - 76px); // 76px = 60px container height + 16px spacing bottom.
            right: 16px;
            bottom: 16px;
        }
    }
}
