.fancy-input {

  .fancy-input-container-unit {
    cursor: default;
  }

  &--error .fancy-input-container {
    border-color: $colorPrimaryEnergeticRed !important;
  }

  &--disabled {
    .fancy-input-label, .fancy-input-container-unit {
      color: $colorGreyLight !important;
    }

    .fancy-input-container {
      background-color: $colorGreyBarely !important;
      color: $colorGreyLight !important;
    }
  }
}
