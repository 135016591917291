.address-edit-page {
    background-color: $colorGreyBarely;
    padding: 20px 40px 80px;
}

@include tabletMax {
    .address-edit-page {
        padding: 12px 32px 60px;
    }
}

@include mobileLargeMax {
    .address-edit-page {
        padding: 12px 16px 60px;
    }
}
