.cart-item {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    border-bottom: 1px solid $colorGreyLight;

    &-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;
    }

    &-image {
        // Width of element in design plus padding, divided by container width in design.
        width: calc(100% * ((190 + 20) / 1320));
        padding-right: 20px;

        img {
            width: 100%;
            padding: 20px;
            border: 1px solid $colorGreyLight;
            max-width: 250px;
        }
    }

    &-text {
        // Width of element in design plus padding, divided by container width in design.
        width: calc(100% * ((324 + 20) / 1320));
        padding-right: 20px;

        .cart-item-availability-icon {
            display: none;
        }
    }

    &-text-title {
        color: $colorGreyDark;
        @include fontBodyBold;
    }

    &-text-description {
        @include fontBodySmallText;
        color: $colorGrey;
        margin-top: 4px;
    }

    &-text-comment {
        @include fontBodyText;
        color: $colorGrey;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &-container {
            display: flex;
            flex-direction: row;
            max-width: 256px;
            margin-top: 16px;
        }

        &-edit {
            align-self: self-end;
            margin-left: 8px;
        }

        &-button {
            @include clearStyle;
            margin-top: 16px;
            text-align: left;
            color: $colorPrimaryEnergeticRed;
        }
    }

    &-amount {
        // Width of element in design plus padding, divided by container width in design.
        width: calc(100% * ((140 + 20) / 1320));
        padding-right: 20px;

        .amount-counter {
            max-width: 120px;
        }
    }

    &-weight {
        // Width of element in design plus padding, divided by container width in design.
        width: calc(100% * ((128 + 20) / 1320));
        padding-right: 20px;

        &-text {
            @include fontBodyText;
            color: $colorPrimaryJustBlack;
        }
    }

    &-mobile-availability {
        display: none;
    }

    &-availability {
        // Width of element in design plus padding, divided by container width in design.
        width: calc(100% * ((124 + 20) / 1320));
        padding-right: 20px;
        color: $colorGreyDark;
        // Padding to provide space for the absolutely positioned availability symbol.
        padding-left: 28px;
        position: relative;
    }

    &-availability-icon {
        position: absolute;
        top: 5px;
        left: 5px;
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;

        &--available {
            background: $colorSemanticSuccessGreen;
        }

        &--unavailable {
            background: $colorPrimaryEnergeticRed;
        }
    }

    &-price {
        // Width of element in design plus padding, divided by container width in design.
        width: calc(100% * ((100 + 20) / 1320));
        padding-right: 20px;
        text-align: right;
    }

    &-price-extra {
        @include fontBodySmallText;
        color: $colorGrey;
        margin-top: 4px;
    }

    &-sum {
        // Width of element in design plus padding, divided by container width in design.
        width: calc(100% * ((110 + 20) / 1320));
        padding-right: 20px;
        text-align: right;
    }

    &-icon-container {
        // Width of element in design plus padding, divided by container width in design.
        width: calc(100% * ((64 + 0) / 1320));
        height: 1px;
        position: relative;
    }

    &-delete {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        width: 60px;
        height: 60px;
        text-align: center;
    }

    &-additions {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        // Left spacing of element, divided by container width in design.
        margin-left: calc(100% * (210 / 1320));

        > * {
            margin-top: 12px;
        }

        > *:first-child {
            margin-top: 0;
        }
    }
}

@include desktopSmallMax {
    .cart-item {
        &-image {
            display: none;
        }

        &-text {
            width: 100%;
            padding-right: 40px;
            margin-bottom: 20px;

            .cart-item--transport & {
                width: 70%;
                margin-bottom: 0;
                padding-right: 0;
            }

            .cart-item-availability-icon {
                display: inline-block;
            }
        }

        &-text-title {
            display: inline;
            vertical-align: middle;
        }

        &-text-description {
            display: none;

            .cart-item--transport & {
                display: block;
            }
        }

        &-text-comment {
            &-container {
                margin-top: 8px;
            }

            &-button {
                margin-top: 8px;
                display: block;
            }
        }

        &-weight {
            display: none;
        }

        &-price {
            display: none;
        }

        &-sum {
            @include fontBodyBold;
            color: $colorGreyDark;
            width: 50%;
            text-align: right;
            padding-right: 0;
            margin-top: -20px;

            .cart-item--transport & {
                width: 30%;
                margin-top: initial;
            }
        }

        &-delete {
            transform: none;
            top: 0;
            height: auto;
            width: auto;
        }

        &-amount {
            width: 50%;
            padding-right: 0;

            .cart-item--transport & {
                display: none;
            }
        }

        &-mobile-availability {
            display: inline-block;
            position: absolute;
            margin-left: 8px;

            .profile-offer-detail-table-item-column-in-stock {
                font-size: 0;
            }
        }

        &-availability {
            display: none;
        }

        &-availability-icon {
            position: relative;
            top: auto;
            left: auto;
            margin-left: 5px;
            vertical-align: middle;
        }

        &-icon-container {
            width: 0;
            height: 0;
            position: static;
        }

        &-additions {
            margin-left: 0;

            > *:first-child {
                margin-top: 24px;
            }
        }
    }
}

@include mobileLargeMax {
    .cart-item {
        padding: 20px 16px;

        &-text-comment-container {
            max-width: 207px;
        }
    }
}
