.select-box {
    &-label {
        @include fontBodySmallText;
        color: $colorGreyDark;
        margin-bottom: 4px;
    }

    &-select {
        position: relative;
        display: block;

        select {
            cursor: pointer;
            color: $colorPrimaryJustBlack;
            width: 100%;
            height: 48px;
            font-family: $fontDefault;
            @include clearStyle;
            @include fontBodyText;
            padding: 12px 48px 12px 16px;
            border: 1px solid $colorGreyLight;
            -webkit-appearance: none;
        }

        &::after {
            position: absolute;
            right: 0;
            top: 0;
            width: 48px;
            height: 48px;
            text-align: center;
            font-family: $iconFont;
            content: $icon-angle-down;
            color: $colorPrimaryEnergeticRed;
            font-size: 24px;
            line-height: 48px;
            pointer-events: none;
        }

        &--error {
            border: 1px solid $colorPrimaryEnergeticRed;
        }
    }

    &-error {
        &-message {
            display: flex;
            flex-direction: row;
            color: $colorPrimaryEnergeticRed;
            margin-top: 6px;

            span {
                @include fontCaptionRegular;
                margin: 4px 0 0 4px;
            }
        }

        &-icon {
            i {
                font-size: 20px;
            }

            &:hover {
                .select-box-error-tooltip {
                    visibility: visible;
                    opacity: 1;
                    display: block;
                }
            }
        }

        &-tooltip {
            visibility: hidden;
            display: block;
            font-family: 'Akkurat Pro', serif !important;
            font-weight: 400;
            font-size: 14px !important;
            line-height: 20px !important;
            width: 300px;
            background-color: $colorPrimaryClearlyWhite;
            color: $colorPrimaryJustBlack;
            padding: 20px;
            position: absolute;
            z-index: 1;
            bottom: 150%;
            left: -70%;
            opacity: 0;
            transition: opacity 0.3s;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);

            a {
                color: $colorPrimaryEnergeticRed;
                text-decoration: underline $colorPrimaryEnergeticRed;
            }

            &::after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                top: 100%;
                left: 3%;
                box-sizing: border-box;
                border: 8px solid $colorPrimaryJustBlack;
                border-color: transparent transparent $colorPrimaryClearlyWhite $colorPrimaryClearlyWhite;
                transform-origin: 0 0;
                transform: rotate(-45deg);
                box-shadow: -3px 3px 3px 0 rgba(0, 0, 0, 0.16);
            }
        }
    }
}
