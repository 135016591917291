.profile-navigation-item {
    padding: 12px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    background-color: $colorPrimaryClearlyWhite;

    &:hover {
        .profile-navigation-item-title {
            color: $colorPrimaryEnergeticRed;
            transition: color 0.5s ease;
        }
    }

    &-active-indicator {
        width: 4px;
        height: 24px;
        background-color: $colorPrimaryEnergeticRed;
        border-radius: 0 4px 4px 0;
        position: absolute;
        left: 0;
        top: 12px;
    }

    &-title {
        @include fontBodyLargeText;
        color: $colorGreyDark;

        &--active {
            color: $colorPrimaryEnergeticRed;
        }
    }
}

@include tabletMax {
    .profile-navigation-item {
        border-bottom: 0.5px solid $colorGreyMid;
        padding: 20px 32px 16px;
        background-color: $colorGreyBarely;

        &-active-indicator {
            width: 100%;
            height: 3px;
            top: auto;
            bottom: -1px;
        }

        &-title {
            @include fontBodyLargeBold;
            color: $colorGrey;
            white-space: nowrap;

            &--active {
                color: $colorPrimaryEnergeticRed;
            }
        }
    }
}

@include mobileLargeMax {
    .profile-navigation-item {
        padding: 20px 16px 16px;
    }
}
