.configuration-step-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 28px;

    &-number {
        height: 32px;
        width: 32px;
        border: 1px solid $colorPrimaryEnergeticRed;
        border-radius: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 12px;

        span {
            @include fontBodyBold;
            color: $colorPrimaryEnergeticRed;
        }
    }

    &-title {
        @include fontBodyLargeBold;
        color: $colorGreyDark;
    }
}
