.shipping-selection {
    background-color: $colorPrimaryClearlyWhite;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-title {
        @include fontH3Headline;
        margin-bottom: 8px;
    }

    &-items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-right: -20px;

        & > * {
            max-width: 325px;
            flex: 1 1 auto;
            margin-right: 20px;
            margin-top: 20px;
        }
    }
}

@include mobileLargeMax {
    .shipping-selection {
        padding: 16px;

        &-title {
            @include fontH4Headline;
            margin-bottom: 12px;
        }

        &-items {
            margin-right: -16px;

            & > * {
                max-width: none;
                margin-right: 16px;
                margin-top: 16px;
            }
        }
    }
}
