.material-list {
    &-header {
        border-bottom: 1px solid $colorGreyLight;

        th {
            background-color: $colorPrimaryClearlyWhite;
            padding: 24px 0 12px;
            position: sticky;
            top: 163px;
            z-index: 101;
        }
    }

    &-header-title {
        @include fontH3Headline;
        color: $colorPrimaryJustBlack;
        padding-left: 20px;
        text-align: start;
    }

    &-header-shapes-icon {
        width: 40px;
        text-align: center;

        i {
            font-size: 20px;
            color: $colorGreyMid;
        }

        @include desktopSmallMax {
            display: none;
        }
    }
}

@include tabletMax {
    .material-list {
        &-header {
            th {
                top: 63px;
            }
        }
    }
}
