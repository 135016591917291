/* =================================================
        Base definitions.
================================================= */

html {
    box-sizing: border-box;
    font-family: $fontDefault;
    color: $colorPrimaryJustBlack;
    height: 100%;
}

body {
    display: flex; // For sticky footer.
    flex-direction: column;
    height: 100%;
    @include fontBodyText;
}

.no-overflow {
    overflow: hidden;
}

.content {
    flex: 1; // For sticky footer.
}

* {
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: inherit;
}

p {
    margin: 0;
}

img,
picture {
    display: block;
    max-width: 100%;
}

a {
    text-decoration: none;
    color: inherit;
}

div,
main,
header,
footer,
section,
article {
    position: relative;
    display: block;
}

button {
    cursor: pointer;
    font-family: $fontDefault;
    @include clearStyle;
}

// Radio styling
.radio {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 24px;

    &:not(:last-child) {
        margin-bottom: 16px;
    }
}

.radio-text {
    @include fontBodyText;
    color: $colorGreyDark;
    margin-top: 2px;
    cursor: pointer;
}

input[type='radio'] {
    display: flex;
    justify-content: center;
    align-items: center;
    appearance: none;
    background-color: $colorPrimaryClearlyWhite;
    margin: 0;
    font: inherit;
    color: $colorPrimaryEnergeticRed;
    width: 20px;
    height: 20px;
    border: 1px solid $colorPrimaryEnergeticRed;
    border-radius: 50%;
    margin-right: 8px;
    cursor: pointer;

    // Reset default safari styling.
    -moz-appearance: none;
    -webkit-appearance: none;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
}

input[type='radio']::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 10px 10px $colorPrimaryEnergeticRed;
}

input[type='radio']:checked::before {
    transform: scale(1);
}
