.profile-user-management {
    width: 100%;
    position: relative;

    &-add-user {
        display: block;
        width: 100%;
        padding: 24px 20px;
        background-color: $colorPrimaryClearlyWhite;
        margin-bottom: 12px;
    }

    &-description {
        width: 100%;
        background-color: $colorPrimaryClearlyWhite;
        padding: 20px;
        position: relative;
        margin-bottom: 20px;

        &-title {
            @include fontH3Headline;
            color: $colorPrimaryJustBlack;
            margin-bottom: 8px;
        }

        &-text {
            @include fontBodyText;
            max-width: 765px;
            color: $colorGrey;
        }
    }
}

@include mobileLargeMax {
    .profile-user-management {
        &-add-user {
            padding: 20px 16px;
            margin-bottom: 20px;
        }

        &-description {
            padding: 16px;
        }
    }
}
