.profile-user-contact {
    width: 100%;
    background-color: $colorPrimaryClearlyWhite;
    padding: 20px 20px 24px;
    position: relative;
    margin-bottom: 20px;

    &-title {
        @include fontH3Headline;
        color: $colorPrimaryJustBlack;
        margin-bottom: 28px;
    }

    &-salutation {
        margin-bottom: 20px;
    }

    &-content {
        display: flex;

        &-left,
        &-right {
            max-width: 305px;
            width: 100%;
        }

        &-left {
            margin-right: 20px;
        }
    }
}

@include mobileLargeMax {
    .profile-user-contact {
        &-title {
            @include fontH4Headline;
        }

        &-content {
            flex-direction: column;

            &-left {
                margin-right: 0;
            }

            &-right {
                margin-top: 20px;
            }
        }
    }
}
