.material-list-item {
    position: relative;
    border-bottom: 1px solid $colorGreyLight;

    > td {
        padding: 12px 0;
        vertical-align: middle;
    }

    &-active {
        display: none;
        position: absolute;
        // -1px to also cover the border.
        top: -1px;
        bottom: -1px;
        left: 0;
        width: 4px;
        background-color: $colorPrimaryEnergeticRed;
        z-index: 100;
    }

    &-center {
        display: flex;
        align-items: center;
    }

    &-title {
        &-text {
            @include fontBodyText;
            color: $colorGreyDark;
        }

        &-arrow {
            font-size: 20px;
            color: $colorPrimaryEnergeticRed;
            margin-bottom: 2px;
            margin-right: 14px;
            margin-left: 20px;
            transition: transform 0.4s ease;
        }
    }

    &-number {
        @include fontBodyText;
        color: $colorGreyDark;

        @include desktopSmallMax {
            padding-right: 16px !important;
        }
    }

    &-shapes-icon {
        width: 40px;
        text-align: center;
        font-size: 20px;
        margin-top: 2px;

        .icon-check {
            color: $colorPrimaryEnergeticRed;
        }

        .icon-minus {
            color: $colorGreyMid;
        }

        @include desktopSmallMax {
            display: none;
        }
    }

    &-subs {
        display: none;
    }

    &--open {
        .material-list-item-title-text,
        .material-list-item-number {
            color: $colorPrimaryEnergeticRed;
        }

        .material-list-item-title-arrow {
            transform: translateY(2px) rotate(90deg);
        }

        .material-list-item-active {
            display: block;
        }
    }

    &:hover {
        background-color: $colorGreyBarely;
        transition: background-color 0.5s ease;
        cursor: pointer;

        .material-list-item-title-text,
        .material-list-item-number {
            color: $colorPrimaryEnergeticRed;
            transition: color 0.5s ease;
        }
    }
}
