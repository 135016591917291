.select-box {
    &-select {
      select {
        &.disabled {
          background-color: $colorGreyBarely;
          color: $colorGrey;
          pointer-events: none;
        }
      }
    }
}
