.order-overview-page {
    padding: 20px 40px 80px;
    background-color: $colorGreyBarely;

    &-content {
        display: flex;
        align-items: flex-start;

        .shipping-selection {
            margin-top: 20px;
        }

        .order-summary {
            flex: 0 0 auto;
            margin-left: 20px;
        }

        .address-selection {
            margin-top: 20px;
        }
    }

    &-inner {
        flex: 1 1 auto;
    }
}

@include desktopSmallMax {
    .order-overview-page {
        padding: 20px 32px 60px;

        &-content {
            flex-wrap: wrap;
        }

        &-inner {
            width: 100%;
            flex: 0 0 auto;
        }

        .order-summary {
            margin-left: 0;
            margin-top: 20px;
            width: 100%;
        }
    }
}

@include mobileLargeMax {
    .order-overview-page {
        padding: 16px 16px 60px;
    }
}
