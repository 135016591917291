.amount-counter {
    display: block;

    &-label {
        @include fontBodySmallText;
        color: $colorGreyDark;
        margin-bottom: 4px;
    }

    &-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: 48px;
        border: 1px solid $colorGreyLight;
        padding: 12px;

        &-button {
            width: 24px;
            height: 24px;

            i {
                font-size: 24px;
                color: $colorPrimaryEnergeticRed;
            }
        }

        &-button:disabled {
            i {
                color: $colorGreyMid;
            }
        }

        &-number {
            @include fontBodyText;
        }
    }
}
