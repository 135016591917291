.checkbox.configuration-checkbox input[type=checkbox] {
  display: inline-block;
  position: absolute;
  opacity: 0;
  pointer-events: none;

  &:disabled + .checkbox-title {
    cursor: default;
    color: $colorGreyMid;
    &::after {
      background-color: $colorGreyMid;
      border-color: $colorGreyMid;
    }
  }

  // set native browser-style for focused checkboxes
  &:focus ~ .checkbox-title::after {
    outline: Highlight solid 2px;
  }

  + .checkbox-title {
    font-weight: unset;
  }
}

@media (-webkit-min-device-pixel-ratio:0) {
  .checkbox.configuration-checkbox input[type=checkbox]:focus ~ .checkbox-title::after {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}
