.profile-user-user {
    width: 100%;
    background-color: $colorPrimaryClearlyWhite;
    padding: 20px 20px 24px;
    position: relative;
    margin-bottom: 20px;

    &-title {
        @include fontH3Headline;
        color: $colorPrimaryJustBlack;
        margin-bottom: 28px;
    }

    &-edit {
        position: absolute;
        top: 20px;
        right: 20px;
        display: flex;
        flex-direction: row;
        color: $colorPrimaryEnergeticRed;

        &-text {
            @include fontBodyText;
        }

        &-icon {
            font-size: 24px;
            margin-left: 4px;
        }
    }

    &-content {
        display: flex;

        &-left,
        &-right {
            max-width: 305px;
            width: 100%;
        }

        &-left {
            margin-right: 20px;
        }

        &-edit {
            margin-top: 20px;

            &:first-child {
                margin-top: 0;
            }

            &-label {
                @include fontBodySmallText;
                color: $colorGrey;
                margin-bottom: 8px;
            }

            &-options-label {
                color: $colorGrey;
            }
        }

        &-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 20px;

            button,
            a {
                width: 140px;

                &:last-child {
                    margin-left: 12px;
                }
            }
        }
    }
}

@include mobileLargeMax {
    .profile-user-user {
        &-title {
            @include fontH4Headline;
        }

        &-edit-text {
            display: none;
        }

        &-content {
            flex-direction: column;

            &-left,
            &-right {
                max-width: unset;
            }

            &-left {
                margin-right: 0;
            }

            &-right {
                margin-top: 20px;
            }

            &-buttons {
                justify-content: initial;

                button,
                a {
                    width: 100%;
                }
            }
        }
    }
}
