.profile-offer-overview-filter {

  &-search {

    &-bottom {

      &-legend {

        &-state {
          cursor: default;
        }
      }
    }
  }
}

