.cart-item-addition {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-block: 12px;
    border: 1px solid $colorGreyLight;

    &-text {
        // Width of element in design plus padding, divided by container width in design.
        width: calc(100% * ((756 + 40) / 1110));
        padding-right: 20px;
        padding-left: 20px;
    }

    &-text-title {
        color: $colorGreyDark;
        @include fontBodyBold;
    }

    &-text-description {
        @include fontBodySmallText;
        color: $colorGrey;
        margin-top: 4px;
    }

    &-addition-amount {
        display: none;
    }

    &-price {
        // Width of element in design plus padding, divided by container width in design.
        width: calc(100% * ((100 + 20) / 1110));
        padding-right: 20px;
        text-align: right;
    }

    &-price-extra {
        @include fontBodySmallText;
        color: $colorGrey;
        margin-top: 4px;
    }

    &-sum {
        // Width of element in design plus padding, divided by container width in design.
        width: calc(100% * ((110 + 20) / 1110));
        padding-right: 20px;
        text-align: right;
    }

    &-icon-spacer {
        // Spacer width corresponding with icon spacing of cart-item.
        width: calc(100% * (64 / 1110));
        height: 1px;
    }
}

@include desktopSmallMax {
    .cart-item-addition {
        padding: 20px;

        &-text {
            width: 100%;
            padding-right: 40px;
            margin-bottom: 20px;
            padding-left: 0;

            .cart-item--addition & {
                width: 70%;
                margin-bottom: 0;
                padding-right: 0;
            }
        }

        &-text-title {
            display: inline;
            vertical-align: middle;
        }

        &-text-description {
            .cart-item--addition & {
                display: block;
            }
        }

        &-price {
            display: none;
        }

        &-sum {
            @include fontBodyBold;
            color: $colorGreyDark;
            width: 50%;
            text-align: right;
            padding-right: 0;

            .cart-item--transport & {
                width: 30%;
                margin-top: initial;
            }

            .cart-item--addition & {
                width: 50%;
                margin-top: 12px;
            }
        }

        &-amount {
            width: 50%;
            padding-right: 0;

            .cart-item--transport &,
            .cart-item--addition & {
                display: none;
            }
        }

        &-addition-amount {
            display: block;
            width: 50%;
            padding-right: 0;
            @include fontBodySmallText;
            color: $colorGrey;
        }

        &-icon-spacer {
            display: none;
        }
    }
}
