.item-image-selection {
    width: calc(50% - 10px);
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    @include tabletSmallMax {
        width: 100%;
    }

    &-container {
        width: 100%;
        border: 1px solid $colorGreyLight;
        padding: 1px;
        transition: border 0.4s ease;

        &:not(.item-image-selection-container--selected):hover {
            border: 1px solid $colorPrimaryEnergeticRed;
        }

        &--selected {
            border: 2px solid $colorPrimaryEnergeticRed;
            padding: 0;
        }

        &-image {
            height: 92px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            box-shadow: 0 1px 0 0 $colorGreyLight;
        }

        &-title {
            @include fontBodySmallText;
            color: $colorGreyDark;
            min-height: 36px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 7px;
            padding-right: 20px;
        }
    }

    &-degree {
        display: none;

        &--selected {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-top: 16px;
        }

        &-select {
            width: calc(50% - 8px);
        }
    }
}
