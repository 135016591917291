.text-input {
    &-container {
        border: 1px solid $colorGreyLight;
    }

    &-label {
        @include fontBodySmallText;
        color: $colorGreyDark;
        margin-bottom: 4px;
    }

    input {
        @include clearStyle;
        height: 46px;
        width: 100%;
        padding: 12px 16px;
        font-family: $fontDefault;
        color: $colorPrimaryJustBlack;

        &::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder,
        &::-webkit-input-placeholder,
        &::-moz-placeholder {
            font-family: $fontDefault;
            color: $colorGrey;
            @include fontBodyText;
        }

      &.disabled {
        background-color: $colorGreyBarely;
        color: $colorGrey;
        pointer-events: none;
      }
    }

    button {
        @include clearStyle;
        display: none;
        top: 0;
        right: 0;
        position: absolute;
        color: $colorPrimaryJustBlack;
        height: 48px;
        width: 48px;
        text-align: center;
        font-size: 24px;
    }

    &--error .text-input-container {
        border-color: $colorPrimaryEnergeticRed;
    }

    &-error-text {
        @include fontCaptionRegular;
        padding-top: 4px;
        color: $colorPrimaryEnergeticRed;
    }

    &--button {
        input {
            width: calc(100% - 48px);
            padding-right: 0;
        }

        button {
            display: block;
        }
    }
}
