.table-scroll-container {
  position: sticky;
  top: 50%;
  right: 0;
  z-index: 1000;
  text-align: right;
}

.shape-overview-table table th.js-sticky-td,
.shape-overview-table table td.js-sticky-td {
  background-color: $colorPrimaryClearlyWhite;
  z-index: 100;
  position: relative;
  left: 0 !important;
}

.shape-overview-table table th.js-sticky-td.highlighted,
.shape-overview-table table td.js-sticky-td.highlighted,
.shape-overview-table table tbody tr:hover:first-child,
tr:hover td.js-sticky-td,
.highlighted {
  background-color: $colorGreyBarely;
}

.shape-overview-table table td.highlighted:hover {
  background-color: $colorGreyLight;
}

.shape-overview-table table tr:hover,
tbody .shape-overview-table table tr:hover {
  background-color: unset;
}

.shape-overview-table table td::after {
  display: none;
}

.shape-overview-table table th {
  transition: background-color 0.3s ease;
}

.scroll-button {
  font-size: 26px;
  background-color: $colorPrimaryEnergeticRed;
  color: $colorPrimaryClearlyWhite;
  width: 40px;
  height: 80px;
  font-weight: 600;
  position: absolute;
  display: inline-block;

  &.js-table-scroll--right {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    right: 0;

    i {
      left: 4px;
    }
  }

  &.js-table-scroll--left {
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    left: 0;

    i {
      right: 4px;
    }
  }

  i {
    pointer-events: none;
    position: relative;
  }
}

.sticky-scroll-shadow {
  right: -50px;
  background: linear-gradient(to right, rgba(46, 51, 61, 0.096) 0%, rgba(28, 29, 32, 0.096) 0.01%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease;

  &.sticky-scroll-shadow--show {
    opacity: 1;

    @include mobileLargeMax {
      opacity: 0;
    }
  }
}
