.order-overview-item-addition {
    display: flex;
    align-items: center;
    padding: 12px 20px;
    border: 1px solid $colorGreyLight;

    &-text {
        padding-right: 20px;
        width: calc(78% + 12px);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 4px;
    }

    &-text-title {
        color: $colorGreyDark;
        @include fontBodyBold;
    }

    &-text-description {
        @include fontBodySmallText;
        color: $colorGrey;
    }

    &-price {
        padding-right: 15px;
        width: calc(12% - 12px);
    }

    &-price-extra {
        @include fontBodySmallText;
        color: $colorGrey;
        margin-top: 4px;
    }

    &-sum {
        padding-right: 0;
        width: 10%;
        text-align: right;
    }
}

@include tabletMax {
    .order-overview-item-addition {
        padding: 16px;

        &-text {
            width: 100%;
            flex: 1 1 auto;
            padding-right: 8px;
        }

        &-price {
            display: none;
        }

        &-sum {
            @include fontBodyBold;
            color: $colorGreyDark;
            padding-right: 0;
            flex: 0 0 auto;
            white-space: nowrap;
            width: auto;
        }
    }
}
