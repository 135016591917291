.profile-info-text {
    display: flex;
    flex-direction: column;

    &:not(:first-child) {
        margin-top: 20px;
    }

    &-headline {
        @include fontBodySmallText;
        color: $colorGrey;
    }

    &-password {
        @include fontBodyLargeBold;
        color: $colorPrimaryJustBlack;
    }

    &-value {
        @include fontBodyText;
        color: $colorPrimaryJustBlack;
        margin-top: 4px;
    }
}
