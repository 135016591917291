.overlay {
    @include stretched(fixed);
    background: rgba($colorPrimaryBlack, 0.4);
    z-index: $zOverlay;
    display: none;
    justify-content: center;
    padding: 40px;

    &--open {
        display: flex;
    }

    &-inner {
        overflow: auto;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-content {
        background-color: $colorPrimaryClearlyWhite;
        padding: 20px;
        width: 100%;
        max-width: 550px;
        text-align: center;
    }

    &-cart {
        max-width: 670px;
    }
}

@include mobileLargeMax {
    .overlay {
        padding: 16px;

        &-content {
            padding: 16px;
        }
    }
}
