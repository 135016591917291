.profile-factory-certificates-list {
    background-color: $colorPrimaryClearlyWhite;
    flex: 1 1 auto;

    &-table {
        width: 100%;
    }

    &-table-headline {
        @include fontBodySmallText;
        border-bottom: 1px solid $colorGreyLight;
        color: $colorGrey;
        text-align: left;

        &-title {
            padding: 40px 0 4px;
        }
    }

    th {
        white-space: nowrap;
        padding-right: 12px;

        &:first-child {
            padding-left: 54px;
        }
    }

    table {
        width: 100%;
    }
}

@include tabletMax {
    .profile-factory-certificates-list {
        width: calc(100% + 64px);
        margin-left: -32px;
        background-color: transparent;
        padding-top: 0;

        &-table {
            padding: 0 32px;
            overflow: auto;
        }

        table {
            background-color: $colorPrimaryClearlyWhite;
            min-width: 900px;
        }
    }
}

@include mobileLargeMax {
    .profile-factory-certificates-list {
        width: calc(100% + 32px);
        margin-left: -16px;

        &-table {
            padding: 0 16px;
        }
    }
}
