.registration-form {
    width: 100%;
    background-color: $colorPrimaryClearlyWhite;
    padding: 20px;
    position: relative;
    margin-bottom: 20px;

    &-title {
        @include fontH4Headline;
        color: $colorPrimaryJustBlack;
        margin-bottom: 28px;
    }

    &-content {
        display: flex;
        flex-direction: column;

        &-row {
            display: flex;
            margin-top: 20px;
        }

        &-field {
            max-width: 305px;
            width: 100%;

            &-label {
                @include fontBodySmallText;
                color: $colorGrey;
                margin-bottom: 8px;
            }

            & + & {
                margin-left: 20px;
            }
        }

        &-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 20px;

            button,
            a {
                width: 120px;

                &:last-child {
                    margin-left: 12px;
                }
            }
        }
    }
  &-checkbox {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;

    span.checkbox-error {
        color: $colorSemanticErrorRed;
    }

    a {
      text-decoration: underline;
    }

    .checkbox {
      margin-top: -12px;
    }
  }

  &-error {
    color: $colorSemanticErrorRed;
  }
}

@include mobileLargeMax {
    .registration-form {
        padding: 16px 16px 20px;

        &-title {
            @include fontH4Headline;
        }

        &-content-row {
            flex-direction: column;
            margin-top: 0;
        }

        &-content-field {
            max-width: none;
            margin-top: 20px;

            & + & {
                margin-left: 0;
            }

            &--empty {
                margin-top: 0;
            }
        }

        &-content-buttons {
            justify-content: initial;

            button,
            a {
                width: 100%;
            }
        }
    }
}
