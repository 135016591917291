.shop-detail-informations {
    display: block;
    width: 25%;
    padding: 10px;

    &-title {
        @include fontH4Headline;
        color: $colorPrimaryJustBlack;

        &::before {
            display: none;
            position: absolute;
            top: 18px;
            left: 12px;
            color: $colorPrimaryEnergeticRed;
            content: $icon-angle-right;
            font-family: $iconFont;
            font-size: 24px;
            pointer-events: none;
            transition: transform 0.4s ease;
        }

        &--open {
            &::before {
                transform: rotate(90deg);
            }
        }
    }

    &-container {
        margin-top: 20px;
        background-color: $colorPrimaryClearlyWhite;
        padding: 20px;
        display: block;
    }

    &-container-image {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 166px;
        border: 1px solid $colorGreyLight;
    }

    &-container-information {
        margin-top: 20px;

        &-pair {
            display: flex;
            flex-direction: row;
            @include fontBodyText;
            margin-bottom: 12px;

            &-title {
                color: $colorGrey;
            }

            &-value {
                margin-left: 5px;
            }
        }

        :last-child {
            margin-bottom: 0;
        }
    }
}

@include tabletMax {
    .shop-detail-informations {
        background-color: $colorPrimaryClearlyWhite;
        width: 100%;
        padding: 0;
        margin-bottom: 2px;

        &-title {
            background-color: $colorPrimaryClearlyWhite;
            padding: 18px 40px 20px;
            cursor: pointer;

            &::before {
                display: block;
                top: 16px;
            }
        }

        &-container {
            margin-top: 0;
            padding: 0 16px 12px;
            display: none;

            &--open {
                display: block;
            }
        }
    }
}

@include mobileLargeMax {
    .shop-detail-informations-title {
        padding: 16px 16px 16px 40px;
    }
}

@include tabletSmallMax {
    .shop-detail-informations {
        width: 100%;
    }
}
