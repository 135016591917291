.material-list-item-sub {
    position: relative;
    border-bottom: 1px solid $colorGreyLight;
    cursor: pointer;

    &:not(.material-list-item-sub--open) {
        display: none;
    }

    > td {
        padding: 12px 0;
        vertical-align: middle;
    }

    > td:last-child {
        padding-right: 0;
    }

    &-active {
        display: none;
        position: absolute;
        // -1px to also cover the border.
        top: -1px;
        bottom: -1px;
        left: 0;
        width: 4px;
        background-color: $colorPrimaryEnergeticRed;
        z-index: 100;
    }

    &-center {
        display: flex;
        align-items: center;
    }

    &-title {
        &-text {
            @include fontBodyText;
            color: $colorGreyDark;
            margin-right: 12px;
        }

        &-icon {
            font-size: 20px;
            color: $colorGreyMid;
            margin-bottom: 1px;
            margin-right: 14px;
            margin-left: 54px;
        }
    }

    &-number {
        @include fontBodyText;
        color: $colorGreyDark;

        @include desktopSmallMax {
            padding-right: 16px !important;
        }
    }

    &-show-details-content {
        display: none;
        align-items: center;
        justify-content: flex-end;
        color: $colorPrimaryEnergeticRed;

        &-text {
            @include fontBodyText;
        }

        &-icon {
            font-size: 20px;
            margin-bottom: 1px;
            margin-right: 40px;
            margin-left: 4px;
        }
    }

    &--open {
        .material-list-item-sub-active {
            display: block;
        }
    }

    &:hover {
        background-color: $colorGreyBarely;
        transition: background-color 0.5s ease;
        cursor: pointer;

        .material-list-item-sub-title-icon,
        .material-list-item-sub-title-text,
        .material-list-item-sub-number {
            color: $colorPrimaryEnergeticRed;
            transition: color 0.5s ease;
        }

        .material-list-item-sub-show-details-content {
            display: flex;
        }
    }

    @include desktopSmallMax {
        &-show-details {
            display: none;
        }
    }
}
