.shop-detail-informations-container-information-pair {
  flex-wrap: wrap;
  gap: 5px;
}

.shop-detail-informations-container-information-pair-value {
  margin-left: 0;
  word-break: break-word;
}

.shop-detail-informations {
  width: 100%;
  padding: 0;

  &-container {
    margin-top: 0;
  }

  &-container-image {
    height: auto;

    img {
      height: 166px;
    }
  }
}

app-article-detail-information {
  width: 25%;
  padding: 10px;
}

@include tabletMax {
  app-article-detail-information {
    width: 100%;
    padding: 0;
  }

  .shop-detail-informations {
    &-container {
      display: block;
    }
  }
}

@include tabletSmallMax {
  app-article-detail-information {
    width: 100%;
  }
}
