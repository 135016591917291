.profile-login-page {
  padding: 20px 40px 80px;
  background-color: $colorGreyBarely;
  display: flex;
  flex-direction: row;

  &-content {
    width: 100%;
  }
}

.registration-card-container {
  margin-top: 1.5rem;
  background-color: $colorGreyBarely;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 50%;

  a {
    color: $colorPrimaryEnergeticRed;
  }

  a:hover {
    cursor: pointer;
  }
}

@include tabletMax {
  .profile-login-page {
    padding: 12px 32px 60px;
    flex-direction: column;
  }

  .registration-card-container {
    max-width: 100%;
  }
}

@include mobileLargeMax {
  .profile-login-page {
    padding: 16px 16px 60px;
  }
}
