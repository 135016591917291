.address-form {
    background-color: $colorPrimaryClearlyWhite;
    padding: 20px;

    &-title {
        @include fontH3Headline;
        color: $colorPrimaryJustBlack;
    }

    &-input {
        max-width: 305px;
        width: 100%;
        margin-top: 20px;

        &:first-child {
            margin-top: 28px;
        }

        &-small {
            max-width: 190px;
        }

        &-big {
            max-width: 420px;
        }
    }

    &-group {
        display: flex;
        flex-direction: row;

        .address-form-input {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
                margin-top: 20px;
            }
        }
    }

    &-content {
        &-buttons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 20px;

            a {
                width: 140px;

                &:last-child {
                    margin-left: 12px;
                }
            }
        }
    }
}

@include mobileLargeMax {
    .address-form {
        padding: 16px;

        &-title {
            @include fontH4Headline;
        }

        &-input {
            max-width: 100%;

            &-small,
            &-big {
                max-width: 100%;
            }
        }

        &-group {
            flex-direction: column;

            .address-form-input {
                margin-left: 0;
            }
        }

        &-content {
            &-buttons a {
                width: calc(50% - 6px);
            }
        }
    }
}
