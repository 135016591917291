.button-icon {

  &:not(:disabled):hover {

    .button-icon-tooltip-wide {
      width: 300px;
      left: -77px;
    }
  }
}
