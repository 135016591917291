.button-icon {
    color: $colorPrimaryEnergeticRed;
    @include fontBodyText;
    transition: color 0.4s ease;
    position: relative;

    &:disabled {
        color: $colorGreyMid;
        cursor: unset;
    }

    &:not(:disabled):hover {
        color: $colorPrimaryHighlightRed;

        .button-icon-tooltip {
            visibility: visible;
            opacity: 1;
        }
    }

    i {
        display: inline-block;
        vertical-align: middle;
        font-size: 24px;
    }

    &-tooltip {
        visibility: hidden;
        display: block;
        font-family: 'Akkurat Pro', serif !important;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        width: 120px;
        background-color: $colorPrimaryJustBlack;
        color: $colorPrimaryClearlyWhite;
        text-align: center;
        border-radius: 6px;
        padding: 5px;
        position: absolute;
        z-index: 1;
        bottom: 125%;
        left: 50%;
        margin-left: -60px;
        opacity: 0;
        transition: opacity 0.3s;

        &::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: $colorPrimaryJustBlack transparent transparent transparent;
        }
    }
}

.loading-spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: unset;
  pointer-events: none;

  &:after {
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    margin: 0 auto;
    border-radius: 50%;
    border-width: 2px;
    border-style: solid;
    border-color: $colorGreyMid $colorGreyMid $colorGreyMid transparent;
    animation: loading-animation 1.5s linear infinite;
    cursor: unset;
    pointer-events: none;
  }
}

@keyframes loading-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@include tabletMax {
    .button-icon-tooltip {
        display: none;
    }
}
