// Variables to define font name and font location on server for use in our own css.
// When changing icons, put the new icons into /prototype/fonts/iconfont.
// Then open the /prototype/fonts/iconfont/variables.scss. Check if the
// Value for $icomoon-font-family differs from this $iconFont. If yes,
// overwrite the $iconFont value with the value found in $icomoon-font-family.
$iconFont: 'iconfont-ehg';
$iconFontPath: '/assets/fonts/iconfont/fonts';

// Variables to make icomoon work.
$icomoon-font-family: #{$iconFont};
$icomoon-font-path: #{$iconFontPath};

// Imports the iconfont (and with it the icon content variables).
@import 'icon_style';
