.cart-page {
    padding: 20px 40px 80px;
    background-color: $colorGreyBarely;
}

@include tabletMax {
    .cart-page {
        padding: 12px 32px 60px;
    }
}

@include mobileLargeMax {
    .cart-page {
        padding: 16px 16px 60px;
    }
}
