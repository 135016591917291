.button-primary {
    position: relative;
    width: 100%;
    display: block;
    color: $colorPrimaryClearlyWhite;
    background-color: $colorPrimaryEnergeticRed;
    @include fontBodyBold;
    transition: background-color 0.4s ease;
    padding: 12px 16px;
    text-align: center;

    &:not(:disabled):hover {
        background-color: $colorPrimaryHighlightRed;
    }

    &:disabled {
        color: $colorGreyMid;
        background-color: $colorGreyBarely;
    }

    i {
        display: block;
        position: absolute;
        top: 12px;
        font-size: 24px;
    }

    span {
        display: inline-block;
        vertical-align: middle;
    }

    &--icon:not(.button-primary--icon-right) {
        text-align: right;
        padding-left: 50px;

        i {
            left: 16px;
        }
    }

    &--icon.button-primary--icon-right {
        text-align: left;
        padding-right: 50px;

        i {
            right: 16px;
        }
    }
}
