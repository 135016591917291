.cart-selection {
    @include clearStyle;
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &-title {
        @include fontH3Headline;
        text-align: left;
    }

    &-select {
        @include fontBodyText;
        text-align: left;

        select {
            @include clearStyle;
            @include fontBodyText;
            font-family: $fontDefault;
            padding-right: 30px;
            cursor: pointer;
        }

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 24px;
            content: $icon-angle-down;
            font-family: $iconFont;
            color: $colorPrimaryJustBlack;
        }
    }
}

@include desktopSmallMax {
    .cart-selection {
        border-bottom: 1px solid $colorGreyLight;
    }
}

@include mobileLargeMax {
    .cart-selection {
        padding: 16px 16px 20px;

        &-title {
            @include fontH4Headline;
            width: 100%;
        }

        &-select {
            margin-top: 12px;
        }
    }
}
