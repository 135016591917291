.checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
    min-height: 24px;
    margin-left: 24px;
    margin-bottom: 12px;

    &-container {
        position: relative;
    }

    /* stylelint-disable selector-max-specificity */
    input[type='checkbox'] {
        display: none;

        & + .checkbox-title {
            $marginLeft: 12px;
            display: flex;
            align-items: center;
            cursor: pointer;
            position: relative;
            font-weight: 300;
            font-size: 16px;
            line-height: 24px;
            color: $colorGreyDark;
            margin-left: $marginLeft;
            transition: color 0.4s ease;

            &:hover {
                color: $colorPrimaryEnergeticRed;
            }

            // Checkbox.
            &::after {
                content: '';
                width: 20px;
                height: 20px;
                background-color: $colorPrimaryClearlyWhite;
                border: 1px solid $colorGrey;
                position: absolute;
                top: 0;
                left: calc(#{$marginLeft + 24px} * (-1));
                // Needed for ie11.
                display: block;
            }

            // Checkmark.
            & .checkbox-checkmark {
                opacity: 0;
                position: absolute;
                font-size: 24px;
                color: $colorPrimaryClearlyWhite;
                z-index: 1;
                // Needed for ie11.
                display: block;

                &.icon-blech {
                    top: -2px;
                    font-size: 28px;
                    left: calc(#{$marginLeft + 24px} * (-1) - 3px);
                }

                &.icon-check {
                    top: -1px;
                    font-size: 24px;
                    left: calc(#{$marginLeft + 24px} * (-1) - 1px);
                }
            }
        }

        &:checked {
            ~ .checkbox-arrow {
                left: 6px;
                transform: rotate(90deg);
            }

            + .checkbox-title {
                // Checkbox.
                &::after {
                    border-color: $colorPrimaryEnergeticRed;
                    background-color: $colorPrimaryEnergeticRed;
                    // Needed for ie11.
                    display: block;
                }

                // Checkmark.
                & .checkbox-checkmark {
                    opacity: 1;
                    // Needed for ie11.
                    display: block;
                }
            }
        }
    }

    &-children {
        .checkbox-arrow {
            display: block;
        }

        input[type='checkbox'] + .checkbox-title {
            $marginLeft: 32px;
            margin-left: $marginLeft;

            // Checkbox.
            &::after {
                left: calc(#{$marginLeft + 24px} * (-1));
            }

            // Checkmark.
            & .checkbox-checkmark {
                &.icon-blech {
                    left: calc(#{$marginLeft + 24px} * (-1) - 3px);
                }

                &.icon-check {
                    left: calc(#{$marginLeft + 24px} * (-1) - 1px);
                }
            }
        }
    }
    /* stylelint-enable selector-max-specificity */

    &-arrow {
        display: none;
        position: absolute;
        left: 8px;
        top: 0;
        line-height: 19px;
        font-size: 22px;
        transform-origin: center;
        color: $colorPrimaryEnergeticRed;
    }

    &-form-icon {
        font-size: 24px;
        line-height: 1;
        margin-left: -4px;
    }
}
